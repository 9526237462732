import styled from "styled-components";

import { BarLoader, BeatLoader } from "react-spinners";

export const Container = styled.div`
  display: flex;
`;
export const Content = styled.div`
  width: calc(100% - 305px);
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
  }
  h2 {
    display: flex;
    font-size: 16px;
    align-items: center;
    img {
      margin-right: 20px;
    }
  }
`;

export const FilterConfig = styled.form`
  max-width: 330px;
  width: 100%;
  img {
    width: 12px;
    position: absolute;
  }
  button {
    position: absolute;
    background: none;

    img {
      width: 12px;
    }
  }
  input {
    width: 100%;
    background: transparent;
    border: 0;
    border-bottom: solid 1px #292d41;
    font-size: 14px;
    font-weight: 300;
    padding: 0 0 5px 20px;
    &::placeholder {
      color: #8b8d97;
    }
  }
`;
export const ProductContainer = styled.div``;
export const HeaderList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.2fr 1fr 1fr 0.1fr;
  margin-bottom: 20px;
  padding: 0 20px;
  p {
    font-size: 14px;
    color: #4c5062;
    font-weight: 300;
  }
`;
export const ItemList = styled.div`
  width: 100%;
  padding: 20px;
  padding-right: 10px;
  border-radius: 6px;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1.2fr 1fr 1fr 0.1fr;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  cursor: pointer;
  transition: all ease 0.1s;
  &:hover {
    transform: scale(1.01);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 0.8fr 1.5fr 0.8fr;
  }
  p {
    font-size: 11px;
    color: #8b8d97;
  }
`;

export const Loading = styled(BeatLoader)``;
export const LoadingBtn = styled(BarLoader)``;

export const Err = styled.div`
  font-size: 10px;
  color: red;
  margin: 5px 0;
`;
