import React,{useState, useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import api from '../../../services/api';
import { useParams} from "react-router";

import iconmarker from "../../../assets/icons/marker.png";

export default function Map(props) {
  const { data, setNewKm } = props;

  const [markerlat, setMarkerlat] = useState('')
  const [markerlng, setMarkerlng] = useState('')
  const [statusnf, setStatusnf] = useState([])

  let {idnf} = useParams()

  //status da nota
  async function statusNota() {
    const res = await api.get(`/status?invoice_id=${idnf}`)
    const invoices = res.data.data;
    setStatusnf(invoices)
  }

  // Mapa
  const [viewport] = useState({
      center: {
        lat: -22.5362048,
        lng: -44.1711174
      },
      zoom: 11,
      
      
  });


  const Marker = () => <img src={iconmarker} alt="" /> ;

    
  async function handleApiLoaded(map, maps) {
    const directionsService = new maps.DirectionsService()
    const directionsDisplay = new maps.DirectionsRenderer({suppressMarkers: true})

    let origin = data.shipper.address.address + ',' + data.shipper.address.city;

    statusnf.map((invoice, index) => {
      if(invoice.status_lat && invoice.status_log) {
        origin = new maps.LatLng(invoice.status_lat, invoice.status_log);
      }
    })

    let destination = data.recipient.address.address + ',' + data.recipient.address.city;
    
    let lat = ''
    let lng = ''
    // eslint-disable-next-line array-callback-return
    statusnf.map(item => {

      if(item.status_lat && item.status_log) {
        lat = item.status_lat.toString().replace(",", ".")
        lng = item.status_log.toString().replace(",", ".")
      }
      setMarkerlat(lat)
      setMarkerlng(lng)
    }
    )

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === maps.DirectionsStatus.OK) {
          directionsDisplay.setMap(map)
          directionsDisplay.setDirections(result)
          setNewKm(result.routes[0].legs[0].distance.text)
        }
      }
      
    );
    

    function routatual()  {
      const directionActual = new maps.DirectionsRenderer({
        polylineOptions: { strokeColor: '#5cb85c' }
      })
      directionsService.route(
        {
          origin: origin,
          destination: new maps.LatLng(lat, lng),
          travelMode: maps.TravelMode.DRIVING
          
        },
        (result, status) => {
          if (status === maps.DirectionsStatus.OK) {
            directionActual.setMap(map)
            directionActual.setDirections(result)
          }
        }
      );
    }
    routatual()
  }

  // Fim Mapa
    
  useEffect(() => {
    statusNota() 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyClrVQ7HHBRkVM86vFK_T3if58a8RzSr6E" }}
      defaultCenter={viewport.center}
      defaultZoom={viewport.zoom}
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
        {markerlat === '' && markerlng === '' ? <></> : <Marker lat={markerlat} lng={markerlng}/>}               
    </GoogleMapReact>
  );
}
  