import * as Yup from "yup";

export const useValidation = () => {
  return {
    handleFormErrors: (errors, ref) => {
      if (!ref.current) return null;
      const validationErrors = {};
      if (errors instanceof Yup.ValidationError) {
        errors.inner.forEach((er) => {
          validationErrors[er.path] = er.message;
        });
        ref.current.setErrors(validationErrors);
      }
    },
    handleApiErrors: (errors, ref) => {
      if (!ref.current) return null;
      const validationErrors = {};
      Object.keys(errors).forEach((field) => {
        validationErrors[field] = errors[field];
      });
      ref.current.setErrors(validationErrors);
    },
  };
};
