import React, { useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { logout } from "../../services/auth";
import Drawer from "@material-ui/core/Drawer";

import api from "../../services/api";

// Links
import { linksMenuRight } from "../../content";
import Text from "../Text";

// Images
import imgUser from "../../assets/user-header.png";
import imgera from "../../assets/logo-gera.png";
import iconLogout from "../../assets/icons/icon-logout.png";

// Css
import * as S from "./styles";

export default function Menu(props) {
  const [userinfo, setUser] = useState("");
  const [loading] = useState(false);

  useEffect(() => {
    async function feachReference() {
      await api
        .post(`/me`)
        .then((result) => {
          setUser(result.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    feachReference();
  }, []);

  return (
    <S.Container>
      <Drawer
        anchor="right"
        open={props.menu.right}
        onClose={props.onToggleDrawer("right", false)}
      >
        <S.ContentMenu>
          <div className="user">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <S.UserImage src={imgUser} alt="" />
                <p>{userinfo.name}</p>
                <p>{userinfo.email}</p>
              </>
            )}
          </div>
          <nav>
            <Route>
              {linksMenuRight.map((link, i) => (
                <Link to={link.url} key={i}>
                  <Text id={link.txt} dm={link.label} />
                </Link>
              ))}
            </Route>
          </nav>
          <S.BoxLast>
            <S.Logout onClick={logout}>
              <p>Logout</p>
              <img src={iconLogout} alt="" />
            </S.Logout>
            <S.Gerasinergia>
              <p>Desenvolvido por: </p>
              <img src={imgera} alt="" />
            </S.Gerasinergia>
          </S.BoxLast>
        </S.ContentMenu>
      </Drawer>
    </S.Container>
  );
}
