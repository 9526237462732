import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import Select from "react-select";

// Components
import Header from "../../../../components/Header";
import AsideMenu from "../../../../components/AsideMenu";
import LoadPage from "../../../../components/LoadPage";
import BtnBack from "../../../../components/BtnBack";
import Text from "../../../../components/Text";

//Images
import iconTit from "../../../../assets/icons/icon-campaing.svg";
import iReference from "../../../../assets/icons/icon-produdctReference.svg";

//CSS
import {
  Center,
  TopHeader,
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  Button,
  Btns,
} from "../../../../styles/style";

import { Container, Content, Err, LoadingBtn } from "../styles";

export default function New({ history }) {
  const { register, handleSubmit, errors, control } = useForm();

  const [products, setProducts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const msgsuccess = (text) => toast.success(text);

  useEffect(() => {
    async function load(url, set, load) {
      await api
        .get(url)
        .then((result) => {
          set(result.data.data);
          setLoading(load);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    load(`/product?perPage=10000`, setProducts);
    load(`/branch-n?perPage=10000`, setCompanies, false);
  }, []);

  async function onSubmit(data, e) {
    setLoadingBtn(true);
    setTxtBtn(false);
    await api
      .post(`product-branch`, {
        branch_id: data.company.id,
        product_id: data.code_corteva.id,
        code: data.code_toller,
      })
      .then((result) => {
        e.target.reset();
        msgsuccess("Referencia registrada!");
        setLoadingBtn(false);
        setTxtBtn(true);
        history.push(`/configuracoes/referenciaprodutos`);
      });
  }

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>

        <Container>
          <AsideMenu />

          <Content>
            <header className="content">
              <h2>
                <img src={iReference} alt="" />
                <Text id="referenciaprodutos" dm="Referencia de Produtos" />
              </h2>
            </header>

            {loading ? (
              <LoadPage />
            ) : (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <DescriptionTitle>
                  <Text id="info.ref" dm="Informações da referencia" />
                </DescriptionTitle>
                <BoxForm>
                  <Row className="r2">
                    <InputBox>
                      <label htmlFor="">
                        <Text id="empresa" dm="Empresa" />
                      </label>
                      <Controller
                        as={
                          <Select
                            className="basic-single"
                            placeholder="Selecione o toller"
                            options={companies}
                            getOptionLabel={({ company_name }) => company_name}
                            getOptionValue={({ id }) => id}
                          />
                        }
                        control={control}
                        name="company"
                        rules={{ required: true }}
                      />
                      {errors.company && <Err>Selecione a empresa</Err>}
                    </InputBox>
                  </Row>
                  <Row className="r2">
                    <InputBox>
                      <label htmlFor="">
                        <Text id="codigo" dm="Código" />{" "}
                        <Text id="produto" dm="Produto" /> Corteva
                      </label>
                      <Controller
                        as={
                          <Select
                            className="basic-single"
                            placeholder="Selecione o produto"
                            options={products}
                            getOptionLabel={({ code, name }) =>
                              `${code} - ${name}`
                            }
                            getOptionValue={({ id }) => id}
                          />
                        }
                        control={control}
                        name="code_corteva"
                        rules={{ required: true }}
                      />
                      {errors.code_corteva && <Err>Selecione a empresa</Err>}
                    </InputBox>
                    <InputBox>
                      <label htmlFor="">
                        <Text id="codigo" dm="Código" />{" "}
                        <Text id="produto" dm="Produto" /> Toller
                      </label>
                      <input
                        name="code_toller"
                        ref={register({ required: true })}
                      />
                      {errors.code_toller && <Err>Digite o código</Err>}
                    </InputBox>
                  </Row>
                </BoxForm>

                <Btns>
                  <Button
                    type="reset"
                    className="cancel"
                    onClick={history.goBack}
                  >
                    <Text id="cancelar" dm="Cancelar" />
                  </Button>
                  <Button>
                    {txtBtn && <Text id="salvar" dm="Salvar" />}
                    <LoadingBtn
                      sizeUnit={"px"}
                      size={10}
                      height={2}
                      color={"#fff"}
                      loading={loadingBtn}
                    />
                  </Button>
                </Btns>
              </Form>
            )}
          </Content>
        </Container>
      </Center>
    </>
  );
}
