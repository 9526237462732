import React, { useCallback } from "react";

import * as S from "./styles";

const ItemBomJustified = ({ data, onDelete }) => {
  const filterJustification = useCallback(
    (data) => {
      const bomJustification = data.justification.filter(
        (justification) => justification.type === "BOM"
      );
      if (bomJustification.length) {
        const ids = data.justification_ids
          ? data.justification_ids
          : [bomJustification[0].id];
        return (
          <S.Justificantion>
            <S.JustificantionText>
              {bomJustification[0].justification}
            </S.JustificantionText>
            <S.TrashButton onClick={() => onDelete(ids)}>
              <S.IconTrash />
            </S.TrashButton>
          </S.Justificantion>
        );
      }
    },
    [onDelete]
  );
  return (
    <S.Container>
      <S.Item>
        <S.Value>{data?.bom_name || data?.invoice_name}</S.Value>
        <S.Value>{data?.group && <S.IconGroup />}</S.Value>
        <S.Value>UN: {data?.unit}</S.Value>
        <S.Value>NF: {data?.invoice_quantity}</S.Value>
        <S.Value>BOM: {data?.bom_quantity || "---"}</S.Value>
        <S.Value className={`${data?.diff !== "0.000" && "diff"}`}>
          DIF: {data?.diff !== null ? data?.diff : "---"}
        </S.Value>
      </S.Item>
      {data?.justification && filterJustification(data)}
    </S.Container>
  );
};

export default ItemBomJustified;
