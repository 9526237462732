import React, { useCallback } from "react";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import Modal from "../../../components/Modal";
import ReactTooltip from "react-tooltip";

// Css
import * as S from "./styles";
import api from "../../../services/api";
import { toast } from "react-toastify";

registerLocale("pt-BR", pt);

export default function ModalDetails({
  id,
  data,
  open,
  handleClose,
  loadCampaings,
}) {
  const close = () => {
    handleClose();
  };

  const handleState = (data) => {
    let state = "SEM ESTADO";

    switch (data) {
      case "00":
        state = "EM TRANSITO";
        break;
      case "01":
        state = "NOTA ENTREGUE";
        break;
      case "101":
        state = "AGUARDANDO CARREGAMENTO";
        break;
      default:
        state = "SEM ESTADO";
        break;
    }

    return state;
  };

  const handleAnchorWithToken = useCallback(async (nfNumber) => {
    try {
      const response = await api.post('/token', { nfNumber })
      if(response.data) {
        const newUrl = `${process.env.REACT_APP_INVOICE}/${nfNumber}?token=${response.data.token}`;
        window.open(newUrl, '_blank')
      }
    } catch (err) {
      toast.error('Erro ao gerar link da NF')
    }
  }, [])

  return (
    <div>
      <Modal open={open} close={close}>
        <S.ContainerModal>
          <S.BtnFechar onClick={close} />
          <S.TitleModal>Lista de Itens: {data[1]}</S.TitleModal>
          <S.QuantityModal>
            <h2>{data.bomProduct}</h2>
            <span>
              <p>
                <strong>Quantidade Total:</strong> 
                {data[0].reduce((a, b) => a + (b.item_nota_qtde || 0), 0)
                  .toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }
              </p>
            </span>
          </S.QuantityModal>
          <S.ContentListInvoiceModal>
            <header>
              <p>NF</p>
              <p>Produto</p>
              <p>Status</p>
              <p>Prazo</p>
              <p>Previsão</p>
              <p>Quantidade</p>
              <p></p>
            </header>
            <S.ListModal>
              {data[0].map((item) => (
                <S.ItemListInvoiceModal key={item.id}>
                  <p>{item.notafull_nf_num}</p>
                  <p>{item.item_nota_descr.length > 90 ? 
                      item.item_nota_descr.substring(0,100)+"..." :
                      item.item_nota_descr
                  }</p>
                  <p>{handleState(item.state)}</p>
                  <p>
                    {item.prazo ? moment(item.prazo).format("DD/MM/YYYY") : "-"}
                  </p>
                  <p>
                    {item.previsao
                      ? moment(item.previsao).format("DD/MM/YYYY")
                      : "-"}
                  </p>
                  <p>
                    {item.item_nota_qtde.toLocaleString("pt-BR", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </p>

                  <button
                    onClick={() => handleAnchorWithToken(item.notafull_nf_num)}
                  >
                    <S.IconTracking data-tip="Visualizar NF" />
                  </button>

                  <ReactTooltip effect="solid" />
                </S.ItemListInvoiceModal>
              ))}
            </S.ListModal>
          </S.ContentListInvoiceModal>
        </S.ContainerModal>
      </Modal>
    </div>
  );
}
