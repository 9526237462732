import React, { useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useValidation } from '../../../hooks';
import ActionsSapCreate from '../../../store/ducks/sap/create';
import ActionsSapDelete from '../../../store/ducks/sap/delete';

import * as S from './styles';
import { InputFile } from '../../shared';

const UploadXLS = ({ onUploaded, branchInvoiceId, bomSap }) => {
  const [open, setOpen] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { handleFormErrors } = useValidation();
  const { loading } = useSelector((state) => state.sapCreate);
  const { loading: sapDeleteLoading } = useSelector((state) => state.sapDelete);

  const onSuccess = useCallback(({ reset }, refresh) => {
    reset();
    refresh();
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          xls: Yup.string().required('Selecione um arquivo XLS'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = new FormData();
        formData.append('xls', data.xls);
        formData.append('branch_invoice_id', branchInvoiceId);

        dispatch(
          ActionsSapCreate.request(formData, () =>
            onSuccess({ reset }, onUploaded)
          )
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [branchInvoiceId, dispatch, handleFormErrors, onSuccess, onUploaded]
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(ActionsSapDelete.request(id, onUploaded));
    },
    [dispatch, onUploaded]
  );

  return (
    <>
      {bomSap ? (
        <S.ButtonMini onClick={() => handleDelete(bomSap?.id)}>
          Arquivo SAP - {format(new Date(bomSap?.created_at), 'dd/MM/yyyy')}{' '}
          {sapDeleteLoading ? <S.OnLoading /> : <S.IconClose />}
        </S.ButtonMini>
      ) : (
        <S.ButtonMini
          btStyle="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          Importar planilha SAP
        </S.ButtonMini>
      )}

      <S.Container open={open}>
        <S.Box>
          <S.Head>
            <S.Title>
              <S.IconFile />
              Importar planilha SAP
            </S.Title>
          </S.Head>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.WrapInputs>
              <InputFile name="xls" label="XLS SAP" />
            </S.WrapInputs>
            <S.WrapButtons>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => setOpen(false)}
              >
                Voltar
              </S.Button>
              <S.Button btStyle="primary" type="submit">
                {loading ? <S.OnLoading /> : 'Upload'}
              </S.Button>
            </S.WrapButtons>
          </Form>
        </S.Box>
      </S.Container>
    </>
  );
};

export default UploadXLS;
