import React from "react";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import Modal from "../../../components/Modal";

// Css
import * as S from "./styles";

registerLocale("pt-BR", pt);

export default function ModalComex({
  id,
  products,
  open,
  handleClose,
  loadCampaings,
}) {
  const close = () => {
    handleClose();
  };

  const plantDestiny = (plant_destiny) => {
    switch (plant_destiny) {
      case "47.180.625/0021-90":
        return "DOW - FR. ROCHA";
      case "47.180.625/0019-75":
        return "DOW - MOGI MIRIM";
      case "47.180.625/0020-09":
        return "DOW - JACAREI";
      case "47.180.625/0022-70":
        return "DOW - IBIPORA";
      case "61.064.929/0001-79":
        return "DUPONT - BARUERI";
      case "61.064.929/0072-62":
        return "DUPONT - IBIPORA";
      case "61.064.929/0076-96":
        return "DUPONT - PAULINIA";
      default:
        return "-";
    }
  };

  const addQtyTotal = (products) => {
    let qtyTotal = 0;

    products.map((product) => {
      product.pos.map((po) => {
        po.po_items.map((po_item) => {
          qtyTotal += po_item.qty;
        });
      });
    });

    return qtyTotal;
  };

  return (
    <div>
      <Modal open={open} close={close}>
        <S.ContainerModal>
          <S.BtnFechar onClick={close} />
          <S.TitleModal>ITO-COMEX</S.TitleModal>
          <S.QuantityModal>
            <h2>{`PRODUTO: ${products[0].product_description}`}</h2>
            <span>
              <p>
                <strong>Quantidade Total:</strong> {addQtyTotal(products)}
              </p>
            </span>
          </S.QuantityModal>
          <S.ContentListComexModal>
            <header>
              <p>PO</p>
              <p>Quantidade</p>
              <p>Destino</p>
              <p>GR Atual</p>
              <p>Responsavel</p>
            </header>
            <S.ListModal>
              {products.map((product) =>
                product.pos.map((po) =>
                  po.po_items.map((po_item, index) => (
                    <S.ItemListComexModal key={po_item.uuid}>
                      <p>{`${po.order_reference}-${po_item.item}`}</p>
                      <p>{po_item.qty}</p>
                      <p>{plantDestiny(po_item.plant_destiny)}</p>
                      <p>{moment(po_item.gr_actual).format("DD/MM/YYYY")}</p>
                      <p>{po.responsible_po}</p>
                    </S.ItemListComexModal>
                  ))
                )
              )}
            </S.ListModal>
          </S.ContentListComexModal>
        </S.ContainerModal>
      </Modal>
    </div>
  );
}
