import React, { useCallback, useState } from "react";
import * as S from "./styles";
import ActionsBranchRawInvoiceDelete from "../../../../store/ducks/branch-raw-invoices/delete";
import { useDispatch } from "react-redux";
import { Modal } from "../../../../components/shared";

export const ModalDelete = ({ isOpen, onClose, rawId, onSuccess }) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);

  const deleteRawInvoice = useCallback(() => {
    setDeleting(true);
    dispatch(
      ActionsBranchRawInvoiceDelete.request(rawId, () => {
        setDeleting(false);
        onSuccess();
      })
    );
  }, [dispatch, onSuccess, rawId]);

  return (
    <Modal isOpen={isOpen}>
      <S.ModalBox>
        <S.ModalHead>
          <S.WrapperHeadModal>
            <S.DeleteInvoiceIcon />
            <S.ModalHeadTitle>
              Excluir Nota Fiscal de matéria prima
            </S.ModalHeadTitle>
          </S.WrapperHeadModal>
        </S.ModalHead>
        <S.ModalTitle>
          Deseja realmente excluir a Nota Fiscal selecionada? Essa ação irá
          excluir todos os items relacionados a Nota e não poderá ser desfeita.
        </S.ModalTitle>
        <S.WrapModalButtons>
          <S.Button btStyle="cancel" type="button" onClick={onClose}>
            Cancelar
          </S.Button>
          <S.Button btStyle="danger" onClick={deleteRawInvoice}>
            {deleting ? <S.Loading /> : "Excluir"}
          </S.Button>
        </S.WrapModalButtons>
      </S.ModalBox>
    </Modal>
  );
};
