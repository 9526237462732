import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as S from "./styles";
import ActionsBranchInvoiceList from "../../store/ducks/branch-invoices/list";
import Header from "../../components/Header";
import { Search, Modal } from "../../components/shared";
import {
  ListBranchInvoices,
  UploadMultiplesSAP,
  UploadXML,
  Filter,
  DownloadXLS,
  MassJustification,
} from "../../components/Consume";

const initalFilter = {
  status: null,
  startDate: null,
  endDate: null,
  filter_campaign: null,
  filter_nf: null,
  filter_product: null,
  filter_toller: null,
};

const initalSearch = null;

const Consume = () => {
  let localFilters = JSON.parse(localStorage.getItem("filter"));
  let localSearch = JSON.parse(localStorage.getItem("search"));
  const dispatch = useDispatch();
  const [search, setSearch] = useState(
    localSearch ? localSearch : initalSearch
  );
  const [filter, setFilter] = useState(
    localFilters ? localFilters : initalFilter
  );
  const [reload, setReload] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const {
    data: branchInvoiceData,
    loading,
    pagination,
  } = useSelector((state) => state.branchInvoices);

  const [currentPage, setCurrentPage] = useState(pagination?.page || 1);

  const onSearch = useCallback((query) => {
    setSearch(query);
    setCurrentPage(1);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = (data) => {
    setFilter({ ...data });

    setCurrentPage(1);
  };

  const resetFilter = () => {
    setFilter(initalFilter);
  };
  const handleModal = (value) => {
    setModalOpen(value);
  };

  const handleJustification = useCallback(
    (invoiceSelected) => {
      setModalOpen(true);
      setModalContent(
        <>
          <MassJustification
            invoice={invoiceSelected}
            reload={reloadData}
            isOpen={true}
            handleModal={handleModal}
          />
        </>
      );
    },
    [modalOpen]
  );

  const renderCleanFilterButton = useCallback(() => {
    const hasFilter = Object.values(filter).some((value) => value !== null);
    if (!hasFilter) return <></>;

    return <S.ButtonMini onClick={resetFilter}>Limpar filtro</S.ButtonMini>;
  }, [filter]);

  const fecthData = useCallback(() => {
    dispatch(
      ActionsBranchInvoiceList.request({ page: currentPage, search, ...filter })
    );
  }, [dispatch, currentPage, search, filter]);

  const reloadData = () => {
    setReload(!reload);
  };

  const storageIds = useCallback(() => {
    let idList = [];
    if (branchInvoiceData) {
      branchInvoiceData.forEach((item, index) => {
        let { id, branch_id, product_id, finished_at } = item;
        idList.push({
          id: id,
          branch_id: branch_id,
          product_id: product_id,
          finished_at: finished_at,
        });
      });
    }
    localStorage.setItem("idList", JSON.stringify(idList));
  }, [branchInvoiceData]);

  useEffect(() => {
    storageIds();
  }, [branchInvoiceData]);

  useEffect(() => {
    fecthData();
  }, [fecthData, filter, reload]);

  useEffect(() => {
    localStorage.setItem("filter", JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    if (localFilters) {
      setFilter(localFilters);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("search", JSON.stringify(search));
  }, [search]);

  useEffect(() => {
    if (localSearch) {
      setSearch(localSearch);
    }
  }, []);

  return (
    <S.Container>
      <Header noMargin />
      <Modal isOpen={modalOpen}>{modalContent}</Modal>
      <S.InnerHeader>
        <S.Wrapper>
          <S.Title>
            <S.IconConsume />
            Consumo {loading && <S.Loading />}
          </S.Title>
          {/* <Search defaultValue={localSearch} onSearch={onSearch} /> */}
          <div className="buttonsWrapper">
            <DownloadXLS />
            <UploadXML onUploaded={fecthData} />
            <UploadMultiplesSAP />
            {renderCleanFilterButton()}
            <Filter handleFilter={handleFilter} filter={filter} />
          </div>
        </S.Wrapper>
      </S.InnerHeader>
      <S.Content>
        <ListBranchInvoices
          invoices={branchInvoiceData}
          pagination={pagination}
          onPageChange={handlePageChange}
          justification={handleJustification}
        />
      </S.Content>
    </S.Container>
  );
};

export default Consume;