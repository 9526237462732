import styled from "styled-components";
import { TopHeader } from "../../styles/style";

export const Container = styled.div`
  iframe {
    border: 0;
    border-radius: 9px;
  }
`;

export const HeaderPanel = styled(TopHeader)`
  margin-bottom: 15px;
`;
