import { call, put, select } from "redux-saga/effects";
import { notify } from "../../../services/notification";
import api, { makeOptions } from "../../../services/api";
import ActionsCreateJustification from "../../ducks/compare/create-justification";

const getAuthData = (state) => state.auth.data;

export function* create(action) {
  try {
    const { data: postData, onSuccess } = action;

    const url = `branch-invoice-justification`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.post, url, postData, makeOptions(authData));

    yield put(ActionsCreateJustification.success(data.data));
    notify("success", "Justificativa cadastrada com sucesso!");
    onSuccess();
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ActionsCreateJustification.failure(err.response.data.error.message)
    );
  }
}
