import styled from 'styled-components';
import { GitCompare } from '@styled-icons/boxicons-regular';
import { DocumentRemove } from '@styled-icons/heroicons-outline';

import { Center } from '../../../styles/components';

export {
  InnerHeader,
  Loading,
  Center,
  Button,
  ButtonMini,
  ModalBox,
  ModalHead,
  ModalHeadTitle,
  ModalTitle,
  WrapModalButtons,
} from '../../../styles/components';

export const Container = styled.main`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.dark};
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

export const IconConsume = styled(GitCompare).attrs({
  size: 24,
})``;

export const Content = styled.section`
  width: 100%;
  padding: 40px 32px;
`;

export const Message = styled.h5`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blueDark};
  padding-left: 16px;
`;

export const Wrapper = styled(Center)`
  display: flex;
  /* max-width: 1130px; */
  align-items: center;
  justify-content: space-between;
`;

export const NameProduct = styled.h3`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  color: ${({ theme }) => theme.colors.blueDark};

  span {
    font-family: ${({ theme }) => theme.fonts.titleBold};
    margin-right: 8px;
  }
`;

export const QtyProduct = styled.h3`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const WrapperPending = styled.div`
  margin: 24px auto;
  padding: 32px;
  width: 100%;
  /* max-width: 1130px; */

  &.bg {
    background-color: ${({ theme }) => theme.colors.darkRgba8};
  }
`;

export const Pending = styled.section`
  width: 100%;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CompareTitle = styled.h2`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.blueDark};
  margin-bottom: 32px;
`;

export const NoRecords = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.darkRgba8};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  color: ${({ theme }) => theme.colors.darkRgba50};
`;

export const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
  /* max-width: 1130px; */
  border-top: 2px ${({ theme }) => theme.colors.line} dotted;
  border-bottom: 2px ${({ theme }) => theme.colors.line} dotted;
  padding: 24px 0;
  margin: 0 auto;

  button {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const WrapperHeadModal = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteInvoiceIcon = styled(DocumentRemove).attrs({ size: 20 })`
  margin-right: 8px;
`;

export const DivContainerCompare = styled.div``;

export const Box = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  padding: 24px 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px ${({ theme }) => theme.colors.darkRgba8} solid;
  padding: 0 32px;

  &:last-child {
    border-right: none;
  }
`;

export const Label = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleBold};
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkRgba50};
`;

export const Value = styled.h5`
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 8px;
  text-align: center;
  color: ${({ color, theme }) => (color ? color : theme.colors.primary)};
`;

export const DivGhost = styled.div`
  height: 100%;
  width: 8%;
`;
