import { call, put, select } from "redux-saga/effects";
import { notify } from "../../../services/notification";
import api, { makeOptions } from "../../../services/api";
import ActionsBranchRawInvoiceDelete from "../../ducks/branch-raw-invoices/delete";

const getAuthData = (state) => state.auth.data;

export function* destroy(action) {
  try {
    const { id, onSuccess } = action;

    const url = `branch-raw-invoice/${id}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.delete, url, makeOptions(authData));

    yield put(ActionsBranchRawInvoiceDelete.success(data.data));
    notify("success", "Arquivo deletado com sucesso!");
    if (onSuccess) onSuccess();
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ActionsBranchRawInvoiceDelete.failure(err.response.data.error.message)
    );
  }
}
