import React, { useState, useCallback, useEffect } from "react";
import { Form } from "@unform/web";
import api from '../../../services/api';
import { format } from "date-fns";
import { toast } from 'react-toastify';
import { Err } from "../../../styles/style";
import * as S from "./styles";
import { Textarea } from "../../shared";
import { InputBoxJustification, InputReadOnly } from "../../../styles/style";

const MassJustification = ({handleModal, isOpen, invoice, reload}) => {
  const [justificationId, setJustificationId] = useState(0);
  const [isExist, setIsExist] = useState(false);
  const [loading,setLoading] = useState(false);
  const [justifications, setJustifications] = useState([]);
  const [error, setError] = useState(null);
  
  async function loadJustifications() {
    try {
      setJustifications(invoice.justification.justifications);
    } catch (err) {
      console.log(err);
    }
  }

  async function loadJustificationInvoice(){
    if(invoice.justification){
      setIsExist(true);
    }
    else{
      setIsExist(false)
    }
  }

  const onSubmit = async (data) => {
    if (justificationId) {
      setLoading(true)
      const body = {
        branch_invoice_id: invoice?.id,
        description: data.description,
        justification_id: justificationId,
      }
      try {
        await api
          .post(`branch-invoice-justification-sap`, body)
          .then((response) => {
            toast.success("Justificativa criada com sucesso!")
            setLoading(false);
            reload();
            setError(null);
            onClose();
          })
      }
      catch (error) {
        setLoading(false);
        onClose()
        toast.error('Erro ao criar justificativa!')
        toast.error(error.message)
      }
    }else{
      setError(<Err>Selecione a justificativa</Err>)
    }
  }

  async function loadJustifications() {
    try {
      const response = await api.get("justification-n?perPage=false");
      setJustifications(response.data.data);
    } catch (err) {
      console.log(err);
    }
  }

    const onClose = useCallback(()=> {
      handleModal(false);
      setJustificationId(0);
    },[handleModal])

  useEffect(()=>{
    loadJustifications();
  },[])

  useEffect(()=>{
    loadJustificationInvoice();
  },[invoice])

  return (
    <S.Container open={isOpen}>
      <S.Box>
        <S.Head>
          <S.Title>
            <S.IconFile />
            Justificativa
          </S.Title>
        </S.Head>
        <S.ItemInfo>
          <S.GroupValues>
            <S.Value>NF: {invoice?.number ? invoice?.number : "---"}</S.Value>
            <S.Value>ID: {invoice?.id ? invoice?.id : "---"}</S.Value>
          </S.GroupValues>
        </S.ItemInfo>
        {!isExist && 
          <Form onSubmit={onSubmit}>
            <InputBoxJustification
              name='formtest'
              onChange={(e)=>{setJustificationId(e.target.value)}}
            >
              <option value="" disabled selected>Selecione</option>
              {justifications.map((justification) => (
                <option
                  selected={
                    justification.id === invoice.justification?.id
                  }
                  value={justification.id}
                >
                  {justification.name}
                </option>
              ))}
            </InputBoxJustification>
            {error}
              <S.Subtitle>Comentário:</S.Subtitle>
            <S.WrapInputs>
              <Textarea
                name="description"
              />
            </S.WrapInputs>
            <S.WrapButtons>
              <S.Button btStyle="cancel" type="button" onClick={onClose}>
                Voltar
              </S.Button>
              <S.Button btStyle="primary" type="submit">
                { loading ? <S.OnLoading /> : "Salvar" }
              </S.Button>
            </S.WrapButtons>
          </Form>
        }
        {isExist &&
          <Form>
            <InputReadOnly>
              Justificativa: <label>{invoice.justification?.justifications[0]?.name}</label>
            </InputReadOnly>
            <InputReadOnly>
              Descrição: <label>{invoice.justification?.description}</label>
            </InputReadOnly>
            <InputReadOnly>
              Data de Inclusão: 
              <label>
                {invoice.justification ? format(new Date(invoice.justification.created_at), "dd/MM/yyyy"): ''}
              </label>
            </InputReadOnly>
              <S.WrapButtons>
                <S.Button btStyle="cancel" type="button" onClick={onClose}>
                  Voltar
                </S.Button>
              </S.WrapButtons>
          </Form>
        }
      </S.Box>
    </S.Container>
  );
};

export default MassJustification;