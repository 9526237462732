import React from "react";

import Text from "../Text";

import { Container } from "./styles";
import back from "../../assets/icons/icon-back.svg";

export default function BtnBack({ onClick }) {
  return (
    <Container onClick={onClick}>
      <img src={back} />
      <Text id="voltar" dm="Voltar" />
    </Container>
  );
}
