import styled from "styled-components";

import { ButtonMini } from "../../../styles/components";
import { Loading } from "../../../styles/components";

export const ButtonDownload = styled(ButtonMini)`
    flex-direction: column;
`;

export const OnLoading = styled(Loading)`
    color: #fff;
`