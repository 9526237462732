import React, { useEffect, useRef, useCallback } from "react";

import * as S from "./styles";

export const ModalCloseButton = ({ onClick }) => {
  return (
    <S.CloseButton onClick={onClick}>
      <S.CloseIcon />
    </S.CloseButton>
  );
};

export const Modal = ({
  children,
  onClickOutside,
  isOpen = false,
  ...rest
}) => {
  const containerRef = useRef();

  const body = [...document.getElementsByTagName("body")][0];

  const handleClickOutside = useCallback(
    (e) => {
      if (
        isOpen &&
        onClickOutside &&
        containerRef.current &&
        !containerRef.current.contains(e.target)
      ) {
        onClickOutside();
      }
    },
    [containerRef, onClickOutside, isOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      body.classList.remove("modal-open");
    };
  }, [body, handleClickOutside]);

  useEffect(() => {
    if (isOpen) {
      body.classList.add("modal-open");
    } else {
      body.classList.remove("modal-open");
    }
  }, [body, isOpen]);

  return (
    <S.ModalOverlay ref={containerRef} isOpen={isOpen} {...rest}>
      <S.ContentWrapper>{children}</S.ContentWrapper>
    </S.ModalOverlay>
  );
};
