import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  height: 70px;
  background: #292d41;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '40px')};

  .center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  .icon-menu {
  }
`;

export const UserHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    margin-right: 10px;
  }
`;

export const Username = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleBold};
  font-size: 14px;
  color: #fff;
`;

export const IconMenu = styled.div`
  cursor: pointer;
  margin-left: 16px;
  span {
    width: 16px;
    height: 2px;
    background: #fff;
    display: block;
    border-radius: 4px;
    margin-bottom: 4px;
    transition: all ease 0.3s;

    &:nth-child(2n) {
      width: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  &:hover {
    span {
      background: #8b8d97;
    }
  }
`;

export const Navigation = styled.nav`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 520px) {
    display: none;
  }
  a {
    font-family: ${({ theme }) => theme.fonts.titleBold};
    font-size: 14px;
    color: #8b8d97;
    transition: all ease 0.3s;
    margin: 0 20px;
    text-decoration: none;
    &:hover,
    &.active {
      color: #fff;
    }
  }
`;

export const margin = {
  not: {
    margin: 0,
  },
};
