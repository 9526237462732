import React, { useCallback } from "react";

import * as S from "./styles";

const ItemProductCampaign = ({ data }) => {
  const calcPercent = useCallback((bom, sap) => {
    const diff = bom - sap;

    if (Number(diff.toFixed(1))) {
      const percent = (diff * 100) / bom;
      return `${percent.toFixed(1)}%`;
    }
    return "---";
  }, []);
  return (
    <>
      <S.ContainerItem>
        <S.Item>
          <S.Value>{data?.product_name}</S.Value>
          <S.Value>QTD: {data.quantity}</S.Value>
          <S.Value>Produzido: {data.consumedProduct}</S.Value>
        </S.Item>
        <S.PercentBox product>{data?.percent}%</S.PercentBox>
      </S.ContainerItem>
      {data?.rawProducts?.length &&
        data.rawProducts.map((raw) => (
          <S.ContainerRawItem>
            <S.RawItem key={raw.product_id}>
              <S.Value>{raw.product_description}</S.Value>
              <S.Value>NF: {raw.invoice_quantity}</S.Value>
              <S.Value>BOM: {raw.bom_quantity}</S.Value>
              <S.Value>SAP: {raw.sap_quantity}</S.Value>
              <S.Value>
                DIF. BOM x SAP:{" "}
                {(raw.bom_quantity - raw.sap_quantity).toFixed(3)}
              </S.Value>
            </S.RawItem>
            <S.PercentBox>
              {calcPercent(raw.bom_quantity, raw.sap_quantity)}
            </S.PercentBox>
          </S.ContainerRawItem>
        ))}
    </>
  );
};

export default ItemProductCampaign;
