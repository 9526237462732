import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from 'moment';
import api from "../../../services/api";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import { toast } from "react-toastify";
import Select from "react-select";

// Components
import Header from "../../../components/Header";

//Images
import iconTit from "../../../assets/icons/icon-campaing.svg";

//CSS
import "react-datepicker/dist/react-datepicker.css";
import {
  Center,
  TopHeader,
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  Button,
  Btns,
} from "../../../styles/style";

import { Err, LoadingBtn } from "../styles";

registerLocale("pt-BR", pt);
export default function New({ history }) {
  const { register, handleSubmit, errors, control } = useForm();
  const [companies, setCompanies] = useState([]);
  const [datasolicitacao, setDatasolicitacao] = useState();
  const [dataentrega, setDataentrega] = useState();
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const msgsuccess = (text) => toast.success(text);

  useEffect(() => {
    async function load(url, set) {
      await api
        .get(url)
        .then((result) => {
          set(result.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    load(`/branch-n?perPage=false`, setCompanies);
  }, []);

  async function onSubmit(data, e) {
    setLoadingBtn(true);
    setTxtBtn(false);
    await api
      .post(`/order`, {
        branch_id: data.toller.id,
        description: data.descricao,
        request_date: datasolicitacao,
        deadline_date: dataentrega,
      })
      .then((result) => {
        setLoadingBtn(false);
        setTxtBtn(true);
        msgsuccess("Campanha cadastrada!");
        e.target.reset();
        history.push(`/campanha/edit/${result.data.data.id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>Lançamento da Campanha</h1>
          </span>
        </TopHeader>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <DescriptionTitle>Informações</DescriptionTitle>

          <BoxForm>
            <Row>
              <InputBox>
                <label htmlFor="">Descrição</label>
                <input
                  name="descricao"
                  ref={register()}
                  placeholder="Digite aqui a descrição da campanha"
                />
                {errors.descricao && <Err>Digite a descrição da campanha</Err>}
              </InputBox>
            </Row>
            <Row className="r3">
              <InputBox>
                <label htmlFor="">Toller</label>
                <Controller
                  as={
                    <Select
                      className="basic-single"
                      placeholder="Selecione o toller"
                      options={companies}
                      getOptionLabel={({ company_name }) => company_name}
                      getOptionValue={({ id }) => id}
                    />
                  }
                  control={control}
                  name="toller"
                  rules={{ required: true }}
                />
                {errors.toller && <Err>Selecione o toller</Err>}
              </InputBox>
              <InputBox>
                <label htmlFor="">Data Solicitação</label>
                <DatePicker
                  selected={datasolicitacao}
                  onChange={(date) => setDatasolicitacao(date)}
                  locale="pt-BR"
                  dateFormat="P"
                  name="solicitacao"
                  maxDate={new Date()}
                  autoComplete="off"
                />
                <input
                  type="hidden"
                  autoComplete="off"
                  name="solicitacao"
                  value={datasolicitacao}
                  ref={register({ required: true })}
                />
                {errors.solicitacao && <Err>Selecione a data</Err>}
              </InputBox>
              <InputBox>
                <label htmlFor="">Prazo Entrega</label>
                <DatePicker
                  selected={dataentrega}
                  onChange={(date) => setDataentrega(date)}
                  locale="pt-BR"
                  dateFormat="P"
                  name="prazoent"
                  minDate={new Date()}
                  maxDate={
                    new Date(
                      moment.utc(datasolicitacao)
                      .add(60, 'days')
                    )
                  }
                  ref={register({ required: true })}
                  autoComplete="off"
                  disabled={!datasolicitacao}
                />
                <input
                  type="hidden"
                  autoComplete="off"
                  name="prazoent"
                  value={dataentrega}
                  ref={register({ required: true })}
                />
                {errors.prazoent && <Err>Selecione a data</Err>}
              </InputBox>
            </Row>
          </BoxForm>

          <Btns>
            <Button type="reset" className="cancel" onClick={history.goBack}>
              Cancelar
            </Button>
            <Button>
              {txtBtn && "Salvar"}
              <LoadingBtn
                marginTop={"10px"}
                sizeUnit={"px"}
                size={10}
                height={2}
                color={"#fff"}
                loading={loadingBtn}
              />
            </Button>
          </Btns>
        </Form>
      </Center>
    </>
  );
}
