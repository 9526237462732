import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";

import ActionsInvoiceCompare from "../../../store/ducks/compare/invoice-compare";
import ActionsCompareApprove from "../../../store/ducks/compare/compare-approve";
import ActionsCompareDeleteJustification from "../../../store/ducks/compare/delete-justification";
import ActionsBranchRawInvoices from "../../../store/ducks/branch-raw-invoices/list";
import ActionsCompareCreateJustification from "../../../store/ducks/compare/create-justification";
import ActionsBranchInvoiceUpdateOrder from "../../../store/ducks/branch-invoices/update-order";
import OrdersComboboxActions from "../../../store/ducks/orders/orders-combobox";
import ActionsCompareCampaign from "../../../store/ducks/compare/compare-campaign";

import * as S from "./styles";

import Header from "../../../components/Header";
import NextButton from "../../../components/NextButton";
import api from "../../../services/api";
import { Select } from "../../../components/shared";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useValidation } from "../../../hooks";
import Text from "../../../components/Text";

import {
  ItemBomPending,
  ItemBomJustified,
  ItemSapPending,
  ItemSapJustified,
  ItemRawInvoice,
  CreateJustification,
  UploadXLS,
  UploadRawMaterial,
} from "../../../components/Consume";
import { ModalReprove } from "./ModalReprove";
import { ModalDelete } from "./ModalDelete";

const Compare = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();

  const [selectedItem, setSelectedItem] = useState(null);
  const [contentCSV, setContentCSV] = useState(false);
  const [rawId, setRawId] = useState(null);
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [modalReprove, setModalReprove] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);  
  
  const { invoice, branch, product } = useParams();
  const { handleFormErrors } = useValidation();

  const { data: dataCompare, loading: compareLoading } = useSelector(
    (state) => state.compareInvoice
  );
  const { loading: loadingApprove } = useSelector(
    (state) => state.compareApprove
  );
  const { loading: loadingRawInvoices, data: RawInvoicesData } = useSelector(
    (state) => state.branchRawInvoices
  );
  const { loading } = useSelector((state) => state.branchInvoiceUpdateOrder);

  const { loading: comboboxLoading, data: comboboxData } = useSelector(
    (state) => state.ordersCombobox
  );

  const { data: compareData } = useSelector(
    (state) => state.compareCampaign
  );

  const getComboboxData = useCallback(() => {
    dispatch(OrdersComboboxActions.request());
  }, [dispatch]);

  const getDataCompare = useCallback(
    (data) => {
      if (data?.branchInvoice[0]?.order_id) {
        dispatch(
          ActionsCompareCampaign.request(data?.branchInvoice[0]?.order_id)
        );
      }
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    dispatch(
      ActionsInvoiceCompare.request(
        {
          branch_invoice_id: invoice,
          branch_id: branch,
          product_id: product,
        },
        getDataCompare
      )
    );
  }, [branch, dispatch, invoice, product, getDataCompare]);

  const fetchBranchRawInvoicesData = useCallback(() => {
    dispatch(
      ActionsBranchRawInvoices.request({
        branch_invoice_id: invoice,
      })
    );
  }, [dispatch, invoice]);

  const onRawDelete = useCallback((id)=>{
    setModalDelete(true)
    setRawId(id)
  },[])

  const onUpdateOrderBranchInvoice = useCallback(() => {
    window.location.reload(false);
  }, []);

  const onDeleteRawInvoice = useCallback(() => {
    setModalDelete(false);
    fetchBranchRawInvoicesData();
    fetchData();
  }, [fetchBranchRawInvoicesData, fetchData]);

  useEffect(() => {
    getComboboxData();
  }, [getComboboxData]);

  useEffect(() => {
    async function feachProduct() {
      await api
        .get(`/product?perPage=10000`)
        .then((result) => {
          setProducts(result.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    feachProduct();
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          alfa_id: Yup.string().required("Selecione uma Campanha"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(
          ActionsBranchInvoiceUpdateOrder.request(
            invoice,
            data,
            onUpdateOrderBranchInvoice
          )
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, invoice, onUpdateOrderBranchInvoice, handleFormErrors]
  );

  const exportConsume = useCallback(() => {
    const csvHeader = [
      "Numero da NF",
      "Codigo Produto",
      "Codigo Toller",
      "Nome NF",
      "Descricao do Produto",
      "Quantidade BOM",
      "Quantidade NF",
      "UN",
      "Diferenca",
      "ICMS",
      "IPI",
      "Justificativa",
    ];

    if (dataCompare) {
      if (products[0]) {
        const productsConsume = RawInvoicesData[0];
        const csvBody = [csvHeader];

        dataCompare.invoiceVsBom.forEach((line, index) => {
          const productCode = products.filter(
            (product) => product.id === line.product_id
          );
          const tollerCode = productsConsume?.invoiceItems[index]?.product_code;

          const justification = line?.justification ?? "Sem justificativa";

          const rowBody = [
            productsConsume?.number,
            productCode[0]?.code,
            tollerCode,
            line?.invoice_name,
            productCode?.description,
            line?.bom_quantity,
            line?.invoice_quantity,
            line?.unit,
            line?.diff,
            line?.icms,
            line?.ipi,
            justification,
          ];

          csvBody.push(rowBody);
        });
        setContentCSV(csvBody);
      }
    }
  }, [RawInvoicesData, dataCompare, products]);

  const onDeleteJustification = useCallback(
    (ids) => {
      dispatch(
        ActionsCompareDeleteJustification.request({ ids: ids }, fetchData)
      );
    },
    [dispatch, fetchData]
  );

  const groupSameProduct = useCallback((items) => {
    const productIds = items
      .map((item) => item.product_id)
      .filter((item) => item !== null);
    const uniqueProductIds = [...new Set(productIds)];
    const uniqueItems = uniqueProductIds.map((productId) => {
      const itemsWithThisId = items.filter(
        (item) => item.product_id === productId
      );

      if (itemsWithThisId.length === 1) {
        return itemsWithThisId[0];
      }

      const sumItems = itemsWithThisId.reduce(
        (curr, initial) => ({
          ...initial,
          invoice_quantity: curr.invoice_quantity + initial.invoice_quantity,
          diff: Number(
            Number(curr.invoice_quantity + initial.invoice_quantity) -
              Number(curr.bom_quantity)
          ).toFixed(3),
        }),
        {
          ...itemsWithThisId[0],
          invoice_quantity: 0,
        }
      );
      sumItems.group = itemsWithThisId;
      sumItems.invoice_item_ids = itemsWithThisId.map(
        (item) => item.invoice_item_id
      );
      sumItems.justification_ids = itemsWithThisId
        .filter((item) => item.justification)
        .map((item) => item.justification[0].id);
      return sumItems;
    });
    return uniqueItems;
  }, []);

  const checkBomJustification = (justification) => {
    if (!justification) return false;
    const filter = justification.filter(
      (justification) => justification.type === "BOM"
    );

    if (filter.length) {
      return true;
    }
    return false;
  };

  const PendingInvoiceBom = useCallback(() => {
    const automaticApprove = (item) => {
      let percentDiff = ((item.diff * 100) / item.bom_quantity).toFixed(1);
      if (percentDiff < 0) {
        percentDiff = percentDiff * -1;
      }

      const boolIsApproved =
        item.invoice_item_id && percentDiff < 5 ? true : false;

      if (boolIsApproved) {
        const post = {
          branch_invoice_item_ids: item.invoice_item_ids
            ? item.invoice_item_ids
            : [item.invoice_item_id],
          justification: "Dentro da margem autorizada",
          type: "BOM",
        };
        dispatch(ActionsCompareCreateJustification.request(post, fetchData));
      }
      return boolIsApproved;
    };
    if (dataCompare.invoiceVsBom) {
      const items = dataCompare.invoiceVsBom;
      const pending = items.filter(
        (item) => !checkBomJustification(item.justification) && item.diff !== 0
      );
      if (pending.length === 0)
        return (
          <S.NoRecords>Não existem itens pendentes de comparação.</S.NoRecords>
        );

      const groupProducts = groupSameProduct(pending);

      const arrayItems = groupProducts.map((item, index) => {
        if (!automaticApprove(item))
          return (
            <ItemBomPending
              key={index}
              data={item}
              setSelectedItem={setSelectedItem}
            />
          );
      });
      return <>{arrayItems}</>;
    }
  }, [dataCompare, dispatch, fetchData, groupSameProduct]);

  const InvoiceBomJustified = useCallback(() => {
    if (dataCompare.invoiceVsBom) {
      const items = dataCompare.invoiceVsBom;
      const justified = items.filter(
        (item) => checkBomJustification(item.justification) || item.diff === 0
      );

      if (justified.length === 0)
        return <S.NoRecords>Não existem itens justificados</S.NoRecords>;

      const groupProducts = groupSameProduct(justified);

      const arrayItems = groupProducts.map((item) => (
        <ItemBomJustified
          key={item.invoice_item_id}
          data={item}
          onDelete={onDeleteJustification}
        />
      ));
      return <>{arrayItems}</>;
    }
  }, [dataCompare, groupSameProduct, onDeleteJustification]);

  const checkSapJustification = (justification) => {
    if (!justification) return false;
    const filter = justification.filter(
      (justification) => justification.type === "SAP"
    );

    if (filter.length) {
      return true;
    }
    return false;
  };

  const PendingInvoiceSap = useCallback(() => {
    if (dataCompare.invoiceVsSap) {
      const items = dataCompare.invoiceVsSap;
      const pending = items.filter(
        (item) => !checkSapJustification(item.justification) && item.diff !== 0
      );

      if (pending.length === 0)
        return (
          <S.NoRecords>Não existem itens pendentes de comparação.</S.NoRecords>
        );
      const arrayItems = pending.map((item) => (
        <ItemSapPending
          key={item.invoice_item_id}
          data={item}
          setSelectedItem={setSelectedItem}
        />
      ));

      return <>{arrayItems}</>;
    }
  }, [dataCompare]);

  const InvoiceSapJustified = useCallback(() => {
    if (dataCompare.invoiceVsSap) {
      const items = dataCompare.invoiceVsSap;

      const justified = items.filter(
        (item) => checkSapJustification(item.justification) || item.diff === 0
      );

      if (justified.length === 0)
        return <S.NoRecords>Não existem itens justificados</S.NoRecords>;

      return justified.map((item) => (
        <ItemSapJustified
          key={item.invoice_item_id}
          data={item}
          onDelete={onDeleteJustification}
        />
      ));
    }
  }, [dataCompare, onDeleteJustification]);

  const onCompareApprove = useCallback(() => {
    history.push(`/consumo-aprovado/${invoice}/${branch}/${product}`);
  }, [branch, history, invoice, product]);

  const handleCompareApprove = useCallback(() => {
    dispatch(
      ActionsCompareApprove.request(
        {
          branch_id: branch,
          product_id: product,
          branch_invoice_id: invoice,
        },
        onCompareApprove
      )
    );
  }, [branch, dispatch, invoice, onCompareApprove, product]);

  const onCreateRawInvoice = useCallback(() => {
    fetchData();
    fetchBranchRawInvoicesData();
  }, [fetchBranchRawInvoicesData, fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    exportConsume();
  }, [dataCompare, exportConsume]);

  useEffect(() => {
    fetchBranchRawInvoicesData();
  }, [fetchBranchRawInvoicesData]);

  return (
    <S.Container>
      <ModalReprove
        isOpen={modalReprove}
        onClose={() => setModalReprove(false)}
      />
      <ModalDelete
        isOpen={modalDelete}
        onClose={() => setModalDelete(false)}
        onSuccess={onDeleteRawInvoice}
        rawId={rawId}
      />
      <CreateJustification
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onCreated={fetchData}
      />
      <Header noMargin />
      <S.InnerHeader>
        <S.Wrapper>
          <S.Title>
            <S.IconConsume />
            Consumo {compareLoading && <S.Loading />}
          </S.Title>
          <S.DivContainerCompare>
            <S.BoxDashBoard>
              <S.Item>
                <S.Value>{compareData?.totalQuantityCampaign}</S.Value>
                <S.Label>
                  <Text id="totalAProduzir" dm="Total a Produzir" />
                </S.Label>
              </S.Item>
              <S.Item>
                <S.Value>{compareData?.totalQuantityConcluido}</S.Value>
                <S.Label>
                  <Text id="produzido" dm="Produzido" />
                </S.Label>
              </S.Item>
              <S.Item>
                <S.Value color="#FF006C">
                  {compareData?.percentFinalProduction > 0
                    ? compareData?.percentFinalProduction
                    : "0"}
                  %
                </S.Value>
                <S.Label>
                  <Text id="pendenteDeExpedicao" dm="Pendente de Expedição" />
                </S.Label>
              </S.Item>
              <S.Item>
                <S.Value color="#FF006C">
                  {compareData?.percentFinalPendenteConsumo > 0
                    ? compareData?.percentFinalPendenteConsumo
                    : "0"}
                  %
                </S.Value>
                <S.Label>
                  <Text id="pendentedeConsumo" dm="Pendente de Consumo" />
                </S.Label>
              </S.Item>
            </S.BoxDashBoard>
          </S.DivContainerCompare>
          <UploadXLS
            onUploaded={fetchData}
            branchInvoiceId={invoice}
            bomSap={dataCompare?.bomSap}
          />
        </S.Wrapper>
      </S.InnerHeader>
      <S.Content>
        {dataCompare && (
          <>
            <S.Wrapper>
              <S.NameProduct>
                <span>NF {dataCompare?.branchInvoice[0]?.number} - </span>
                <span>{dataCompare?.productFinished?.product_code}</span>
                {dataCompare?.productFinished?.product_description}
              </S.NameProduct>
              <S.DivButtonContainer>
                <S.ButtonMini
                  btStyle="alter"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Text id="alterarCampanha" dm="Alterar Campanha" />
                </S.ButtonMini>
                <S.QtyProduct>
                  QTD. {dataCompare?.productFinished?.quantity}
                </S.QtyProduct>
              </S.DivButtonContainer>
            </S.Wrapper>
            <S.RawMaterialWrapper>
              <S.HeaderRaw>
                <S.TitleRaw>NFs matéria prima</S.TitleRaw>
                <UploadRawMaterial
                  onUploaded={onCreateRawInvoice}
                  branchInvoiceId={invoice}
                  invoiceNumber={dataCompare?.branchInvoice[0]?.number}
                />
              </S.HeaderRaw>
              {loadingRawInvoices && <S.Loading />}
              {RawInvoicesData?.length === 0 ? (
                <S.NoRecords>
                  Nenhuma nota fiscal de matéria prima encontrada..
                </S.NoRecords>
              ) : (
                RawInvoicesData?.map((rawInvoice) => (
                  <ItemRawInvoice
                    invoice={rawInvoice}
                    key={rawInvoice.id}
                    onDelete={onRawDelete}
                  />
                ))
              )}
            </S.RawMaterialWrapper>
            <S.PendingWrapper className="bg">
              {/* <S.Pending>
                <S.TitleCompare>
                  Divergências Nota Fiscal x B.O.M
                </S.TitleCompare>
                {dataCompare?.invoiceVsBom && <PendingInvoiceBom />}
              </S.Pending> */}
              <S.Pending>
                <S.TitleCompare>Divergências Nota Fiscal x SAP</S.TitleCompare>
                {dataCompare?.invoiceVsSap && <PendingInvoiceSap />}
              </S.Pending>
            </S.PendingWrapper>
            <S.PendingWrapper>
              <S.Pending>
                {contentCSV ? (
                  <CSVLink
                    data={contentCSV}
                    separator={";"}
                    filename={`NF_x_BOM_${invoice}_${branch}_${product}.csv`}
                  >
                    <S.ButtonMini
                      style={{ float: "right", marginLeft: "7px" }}
                      btStyle="primary"
                    >
                      Exportar Consumo
                    </S.ButtonMini>
                  </CSVLink>
                ) : (
                  <S.ButtonMini
                    style={{ float: "right", marginLeft: "7px" }}
                    btStyle="disabled"
                  >
                    Exportar Consumo
                  </S.ButtonMini>
                )}
                {/* <S.TitleCompare>
                  Nota Fiscal x B.O.M{" "}
                  {loadingDeleteJustification && <S.Loading />}
                </S.TitleCompare>
                {dataCompare?.invoiceVsBom && <InvoiceBomJustified />} */}
              </S.Pending>
              <S.Pending>
                <S.TitleCompare>Nota Fiscal x SAP</S.TitleCompare>
                {dataCompare?.invoiceVsSap && <InvoiceSapJustified />}
              </S.Pending>
            </S.PendingWrapper>
            <S.Wrapper>
              <S.NameProduct>
                <span>NF {dataCompare?.branchInvoice[0]?.number} - </span>
                <span>{dataCompare?.productFinished?.product_code}</span>
                {dataCompare?.productFinished?.product_description}
              </S.NameProduct>
              <S.QtyProduct>
                QTD. {dataCompare?.productFinished?.quantity}
              </S.QtyProduct>
            </S.Wrapper>
          </>
        )}
        <S.WrapperButtons>
          <S.Button btStyle="cancel" onClick={() => history.push("/consumo")}>
            Cancelar
          </S.Button>
          <S.Button btStyle="danger" onClick={()=> setModalReprove(true)}>
            Reprovar consumo
          </S.Button>
          {dataCompare?.ableFinish && (
            <S.Button onClick={handleCompareApprove} btStyle="primary">
              {loadingApprove ? <S.Loading color="#fff" /> : "Aprovar consumo"}
            </S.Button>
          )}
          <NextButton Loading={compareLoading} />
        </S.WrapperButtons>
      </S.Content>
      <S.ContainerModal open={open}>
        <S.Box>
          <S.Head>
            <S.Title>
              <S.IconFile />
              <Text id="alterarCampanha" dm="Alterar Campanha" />
            </S.Title>
          </S.Head>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.WrapInputs>
              <Select
                name="alfa_id"
                label="Campanha"
                options={comboboxData}
                isLoading={comboboxLoading}
                isDisabled={comboboxLoading}
                placeholder="Selecione..."
              />
            </S.WrapInputs>
            <S.WrapButtons>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => setOpen(false)}
              >
                <Text id="voltar" dm="Voltar" />
              </S.Button>
              <S.Button btStyle="primary" type="submit" disabled={loading}>
                {loading ? (
                  <S.OnLoading />
                ) : (
                  <Text id="confirmar" dm="Confirmar" />
                )}
              </S.Button>
            </S.WrapButtons>
          </Form>
        </S.Box>
      </S.ContainerModal>
    </S.Container>
  );
};

export default Compare;
