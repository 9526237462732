import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  /* max-width: 1130px; */
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
`;

export const Head = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns:
    repeat(5, minmax(80px, auto))
    1fr 0.8fr 1fr 1fr 32px;
  column-gap: 16px;
  margin-bottom: 16px;
  padding: 0 16px;
  .center{
    justify-self: center;
  }
`;
export const Ghost = styled.div`
  width:40px;
  `;
export const Label = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.lightDarkBlue};
`;
