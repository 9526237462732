import { call, put, select } from "redux-saga/effects";
import { notify } from "../../../services/notification";
import api, { makeOptions } from "../../../services/api";
import ActionsBranchInvoicesUpdateOrder from "../../ducks/branch-invoices/update-order";

const getAuthData = (state) => state.auth.data;

export function* update(action) {
  try {
    const { params, data: postData, onSuccess } = action;

    const url = `branch-invoice-order/${params}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.put, url, postData, makeOptions(authData));

    yield put(ActionsBranchInvoicesUpdateOrder.success(data.data));
    notify("success", "Campanha alterada com Sucesso");
    if (onSuccess) onSuccess();
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ActionsBranchInvoicesUpdateOrder.failure(err.response.data.error.message)
    );
  }
}
