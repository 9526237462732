import React from 'react';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';

export default function Loading({ props }) {
  const contentloading = css`
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  return (
    <BeatLoader
      css={contentloading}
      sizeUnit={"px"}
      size={10}
      color={'#123abc'}
    />
  );
}