import React, { useState } from "react";
import { useForm } from "react-hook-form";

// Components
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";

// CSS
import "react-datepicker/dist/react-datepicker.css";
import * as S from "./styles";

import { themes } from "./styles";

registerLocale("pt-BR", pt);
export default function Filter({ onSubmit }) {
  let storedFilter = localStorage.getItem('statusFilter') || 'td';
  const { register, handleSubmit } = useForm();
  const [dataInico, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [filterForApi] = useState("");
  const [opsts, setOpsts] = useState(false);
  const [filStatus, setFilStatus] = useState(storedFilter);

  function geraStatus() {
    switch (filStatus) {
      case "td":
        return themes.td;
      case "atendida":
        return themes.atendida;
      case "atrasada":
        return themes.atrasada;
      case "parAtendido":
        return themes.parAtendido;
      default:
        break;
    }
  }

  function OpenStatusFilter() {
    if (opsts === false) {
      setOpsts(true);
    } else {
      setOpsts(false);
    }
  }

  function onSubmitFilter(datafilter = null) {
    if (datafilter === null) {
      return filterForApi;
    }
    let filtersForEnpoint = new URLSearchParams(datafilter).toString()

    if (dataInico !== null && dataInico !== "") {
      filtersForEnpoint += `&request_date=${
        dataInico ? new Date(dataInico).toISOString().split(".")[0] : ""
      }`;
    }
    if (dataFim !== null && dataFim !== "") {
      filtersForEnpoint += `&deadline_date=${
        dataFim ? new Date(dataFim).toISOString().split(".")[0] : ""
      }`;
    }

    const status = filStatus;
    localStorage.setItem('statusFilter', status)

    // setFilterForApi(filtersForEnpoint);
    // window.history.pushState({}, document.title, '/transferencia');

    // loadCampaings(filtersForEnpoint);
    
    onSubmit(filtersForEnpoint, status);
  }

  return (
    <S.Container>
      <S.FormFilter onSubmit={handleSubmit(onSubmitFilter)}>
        <S.ItemForm>
          <label>Código:</label>
          <input type="text" name="codigo" ref={register} />
        </S.ItemForm>
        <S.ItemForm>
          <label>Descrição:</label>
          <input type="text" name="description" ref={register} />
        </S.ItemForm>
        <S.ItemForm>
          <label>Toller:</label>
          <input type="text" name="company_name" ref={register} />
        </S.ItemForm>
        <S.ItemForm>
          <label>Data Solicitação:</label>
          <DatePicker
            selected={dataInico}
            onChange={(date) => setDataInicio(date)}
            locale="pt-BR"
            dateFormat="P"
            name="necessidade"
            autoComplete="off"
          />
        </S.ItemForm>
        <S.ItemForm>
          <label>Prazo Entrega:</label>
          <DatePicker
            selected={dataFim}
            onChange={(date) => setDataFim(date)}
            locale="pt-BR"
            dateFormat="P"
            name="necessidade"
            autoComplete="off"
          />
        </S.ItemForm>
        <S.ItemForm>
          <label>Status:</label>
          <S.BtnStatus themes={geraStatus()} onClick={OpenStatusFilter} />
          {opsts && (
            <S.StatusFilter>
              <label
                htmlFor="sts-td"
                onClick={() => {
                  setFilStatus("td");
                  OpenStatusFilter();
                }}
              >
                <input type="radio" name="status" value="td" ref={register} />
                <S.Icon themes={themes.td} />
                Todos status
              </label>
              <label
                htmlFor="sts-en"
                onClick={() => {
                  setFilStatus("atendida");
                  OpenStatusFilter();
                }}
                name="status"
                value="atendida"
                ref={register}
              >
                <input type="radio" />
                <S.Icon themes={themes.atendida} />
                Atendida
              </label>
              <label
                htmlFor="sts-ta"
                onClick={() => {
                  setFilStatus("parAtendido");
                  OpenStatusFilter();
                }}
                name="status"
                value="parAtendido"
                ref={register}
              >
                <input type="radio" name="status" />
                <S.Icon themes={themes.parAtendido} />
                Pendente
              </label>
              <label
                htmlFor="sts-ta"
                onClick={() => {
                  setFilStatus("atrasada");
                  OpenStatusFilter();
                }}
                name="status"
                value="atrasada"
                ref={register}
              >
                <input type="radio" name="status" />
                <S.Icon themes={themes.atrasada} />
                Atrasada
              </label>
            </S.StatusFilter>
          )}
        </S.ItemForm>
        <S.ItemForm>
          <label> &nbsp; </label>
          <S.Button type="submit">Filtrar</S.Button>
        </S.ItemForm>
      </S.FormFilter>
    </S.Container>
  );
}
