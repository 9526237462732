import * as S from "./styles";
import ActionsDeleteInvoice from "../../../../store/ducks/branch-invoices/delete-invoice";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useState, useCallback } from "react";
import { Modal } from "../../../../components/shared";

export const ModalReprove = ({ isOpen, onClose }) => {
  const [reproving, setReproving] = useState(false);
  const { invoice, branch, product } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const onSuccess = () => {
    setReproving(false);
    onClose();
    history.push("/consumo");
  };

  const deleteInvoice = useCallback(() => {
    setReproving(true);
    dispatch(ActionsDeleteInvoice.request(invoice, onSuccess));
  }, [dispatch, invoice]);

  return (
    <Modal isOpen={isOpen}>
      <S.ModalBox>
        <S.ModalHead>
          <S.WrapperHeadModal>
            <S.DeleteInvoiceIcon />
            <S.ModalHeadTitle>Reprovar consumo</S.ModalHeadTitle>
          </S.WrapperHeadModal>
        </S.ModalHead>
        <S.ModalTitle>
          Deseja realmente reprovar o consumo selecionado? Essa operação não
          poderá ser desfeita.
        </S.ModalTitle>
        <S.WrapModalButtons>
          <S.Button btStyle="cancel" type="button" onClick={onClose}>
            Cancelar
          </S.Button>
          <S.Button btStyle="danger" onClick={deleteInvoice}>
            {reproving ? <S.Loading /> : "Reprovar"}
          </S.Button>
        </S.WrapModalButtons>
      </S.ModalBox>
    </Modal>
  );
};
