import styled from "styled-components";
import { Close } from "@styled-icons/ionicons-sharp/Close";

export const ModalOverlay = styled.div.attrs({
  className: "modal-overlay",
})`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};

  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  padding: ${({ padding }) => {
    if (padding) return padding;
    return "2rem";
  }};
  pointer-events: none;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
`;

export const ContentWrapper = styled.div.attrs({
  className: "content-wrapper",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  div:first-child {
    pointer-events: all;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  padding: 4px 0;
  margin-left: auto;
  transition: transform 300ms linear;
  &:hover {
    transform: scale(0.9);
  }
  &:active {
    transform: scale(0.8);
  }
`;

export const CloseIcon = styled(Close).attrs({
  size: 32,
})`
  color: #000000;
`;
