import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

//CSS
import {
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  ButtonDelete,
  Button,
  LoadingBtn,
  Err,
  ReceptorList,
  ReceptorItem,
} from "../../../styles/style";

import { Container } from "../styles";

export default function AddReceptor({ id, onSubmit, receptors, onDelete }) {
  const { register, handleSubmit, errors, control } = useForm();
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  async function addRec(data, e) {
    setLoadingBtn(true);
    setTxtBtn(false);
    console.log(data);
    await onSubmit({
      name: data.name,
      email: data.email,
      telephone: data.phone,
      alert_id: id,
    }).then((result) => {
      e.target.reset();
      setLoadingBtn(false);
      setTxtBtn(true);
    });
  }

  async function deleteRec(data) {
    await onDelete({
      id: data,
    });
  }

  return (
    <Container>
      <Form className="mt" onSubmit={handleSubmit(addRec)}>
        <DescriptionTitle>Cadastro de receptor</DescriptionTitle>

        <BoxForm>
          <Row className="r211">
            <InputBox>
              <label htmlFor="">Nome</label>
              <input
                name="name"
                autoComplete="off"
                required
                ref={register({ required: true })}
              />
              {errors.nome && <Err>Digite o nome</Err>}
            </InputBox>
            <InputBox>
              <label htmlFor="">E-mail</label>
              <input
                name="email"
                autoComplete="off"
                required
                type="email"
                ref={register({ required: true })}
              />
              {errors.email && <Err>Digite o email</Err>}
            </InputBox>
            <InputBox>
              <label htmlFor="">Telefone</label>
              <Controller
                as={
                  <NumberFormat
                    name="phone"
                    format="(##) #####-####"
                    mask="_"
                  />
                }
                control={control}
                name="phone"
              />
              {/* <input name="phone" autoComplete="off" ref={register()} /> */}
            </InputBox>
            <InputBox>
              <Button>
                {txtBtn && "Adicionar"}
                <LoadingBtn
                  marginTop={"10px"}
                  sizeUnit={"px"}
                  size={10}
                  height={2}
                  color={"#fff"}
                  loading={loadingBtn}
                />
              </Button>
            </InputBox>
          </Row>
        </BoxForm>
      </Form>
      <ReceptorList>
        <header>
          <p>Nome</p>
          <p>E-mail</p>
          <p>Telefone</p>
          <p></p>
        </header>
        {receptors.length === 0 ? (
          <p>
            <small>Nenhum registro encontrado...</small>
          </p>
        ) : (
          <>
            {receptors.map((item) => (
              <ReceptorItem key={item.id}>
                <p>{item.receiver.name}</p>
                <p>{item.receiver.email}</p>
                <p>{item.receiver.telephone ? item.receiver.telephone : "-"}</p>
                <ButtonDelete
                  type="button"
                  onClick={() => deleteRec(item.receiver_id)}
                />
              </ReceptorItem>
            ))}
          </>
        )}
      </ReceptorList>
    </Container>
  );
}
