import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import Select from "react-select";

// Helpers
import replaceComma from "../../../../helpers/replaceComma";

// Components
import Header from "../../../../components/Header";
import AsideMenu from "../../../../components/AsideMenu";
import LoadPage from "../../../../components/LoadPage";
import BtnBack from "../../../../components/BtnBack";
import Text from "../../../../components/Text";

//Images
import iconTit from "../../../../assets/icons/icon-campaing.svg";
import iBom from "../../../../assets/icons/icon-bom.svg";

//CSS
import * as Ss from "../../../../styles/style";
import * as S from "../styles";

export default function New({ history }) {
  const { register, handleSubmit, errors, control } = useForm();

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [finishedProduct, setfinishedProduct] = useState([]);

  const msgsuccess = () => toast.success("Bom cadastrado!");

  useEffect(() => {
    async function load(url, set) {
      await api
        .get(url)
        .then((result) => {
          set(result.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function loadProduct() {
      await api
        .get(`/product?perPage=10000`)
        .then((result) => {
          setfinishedProduct(
            result.data.data.filter((e) =>
              e.type && 
              e.type.name.includes("PRODUTO ACABADO")
            )
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    load(`/branch-n?perPage=false`, setCompanies);
    loadProduct();
  }, []);

  async function onSubmit(data, e) {
    const quantityFormatted = replaceComma(data.quantidade);

    await api
      .post(`/bom`, {
        branch_id: data.toller.id,
        product_id: data.produto.id,
        quantity: quantityFormatted,
      })
      .then((result) => {
        msgsuccess();
        e.target.reset();
        history.push(`/configuracoes/bom/${result.data.data.id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <Ss.Center>
        <Ss.TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </Ss.TopHeader>

        <S.Container>
          <AsideMenu />
          <S.Content>
            <header className="content">
              <h2>
                <img src={iBom} alt="" />
                <Text id="bom" dm="BOM (Bill Of Materials)" />
              </h2>
            </header>

            {loading ? (
              <LoadPage />
            ) : (
              <Ss.Form onSubmit={handleSubmit(onSubmit)}>
                <Ss.DescriptionTitle>
                  <Text id="info.bom" dm="Informações do BOM" />
                </Ss.DescriptionTitle>
                <Ss.BoxForm>
                  <Ss.Row className="r2">
                    <Ss.InputBox>
                      <label htmlFor="">Toller</label>
                      <Controller
                        as={
                          <Select
                            className="basic-single"
                            placeholder="Selecione o toller"
                            options={companies}
                            getOptionLabel={({ company_name }) => company_name}
                            getOptionValue={({ id }) => id}
                          />
                        }
                        control={control}
                        name="toller"
                        rules={{ required: true }}
                      />

                      {errors.toller && <S.Err>Selecione o toller</S.Err>}
                    </Ss.InputBox>
                  </Ss.Row>
                  <Ss.Row className="r2">
                    <Ss.InputBox>
                      <label htmlFor="">
                        <Text id="produto.acabado" dm="Produto Acabado" />
                      </label>
                      <Controller
                        as={
                          <Select
                            className="basic-single"
                            placeholder="Selecione o produto"
                            options={finishedProduct}
                            getOptionLabel={({ code, name }) =>
                              `${code} - ${name}`
                            }
                            getOptionValue={({ id }) => id}
                          />
                        }
                        control={control}
                        name="produto"
                        rules={{ required: true }}
                      />
                      {errors.produto && <S.Err>Selecione o produto</S.Err>}
                    </Ss.InputBox>
                    <Ss.InputBox>
                      <label htmlFor="">
                        <Text id="quantidade" dm="Quantidade" />
                      </label>
                      <input
                        name="quantidade"
                        ref={register({ required: true })}
                      />
                      {errors.quantidade && <S.Err>Digite a quantidade</S.Err>}
                    </Ss.InputBox>
                  </Ss.Row>
                </Ss.BoxForm>

                <Ss.Btns>
                  <Ss.Button
                    className="cancel"
                    type="reset"
                    onClick={history.goBack}
                  >
                    <Text id="cancelar" dm="Cancelar" />
                  </Ss.Button>
                  <Ss.Button type="submit">
                    <Text id="salvar" dm="Salvar" />
                  </Ss.Button>
                </Ss.Btns>
              </Ss.Form>
            )}
          </S.Content>
        </S.Container>
      </Ss.Center>
    </>
  );
}
