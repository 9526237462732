import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import Select from "react-select";

// Components
import Header from "../../../../components/Header";
import AsideMenu from "../../../../components/AsideMenu";
import LoadPage from "../../../../components/LoadPage";
import BtnBack from "../../../../components/BtnBack";
import Text from "../../../../components/Text";

//Images
import iconTit from "../../../../assets/icons/icon-campaing.svg";
import iReference from "../../../../assets/icons/icon-produdctReference.svg";

//CSS
import {
  Center,
  TopHeader,
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  Button,
  Btns,
} from "../../../../styles/style";

import { Container, Content, Err, LoadingBtn } from "../styles";

export default function Details({ history }) {
  const { register, handleSubmit, errors, control } = useForm();

  const [reference, setReference] = useState([]);
  const [products, setProducts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [txtDelete, setTxtDelete] = useState(true);
  const [loadBtnDelete, setloadBtnDelete] = useState(false);

  let { id } = useParams();
  const msgsuccess = () => toast.success("Referencia atualizada!");
  const msgdelete = () => toast.success("Referencia removida com sucesso!");

  useEffect(() => {
    async function load(url, set, load) {
      await api
        .get(url)
        .then((result) => {
          set(result.data.data);
          setLoading(load);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function loadModules() {
      await load(`/product?perPage=10000`, setProducts, true);
      await load(`/branch-n?perPage=10000`, setCompanies, true);
      await load(`/product-branch/${id}`, setReference, false);
    }
    loadModules();
  }, [id]);

  async function onSubmit(data, e) {
    setLoadingBtn(true);
    setTxtBtn(false);
    await api
      .patch(`product-branch/${id}`, {
        branch_id: data.company.id,
        product_id: data.code_corteva.id,
        code: data.code_toller,
      })
      .then((result) => {
        setLoadingBtn(false);
        setTxtBtn(true);
        e.target.reset();
        msgsuccess();
        history.push("/configuracoes/referenciaprodutos");
      });
  }

  async function removeReference() {
    setloadBtnDelete(true);
    setTxtDelete(false);
    withSwalInstance(
      swal
        .fire({
          title: "Você tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1ABC9C",
          cancelButtonColor: "#FF4E50",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim, pode deletar!",
        })
        .then(async (result) => {
          if (result.value) {
            await api
              .delete(`product-branch/${id}`)
              .then((result) => {
                setloadBtnDelete(false);
                setTxtDelete(true);
                msgdelete();
                history.push("/configuracoes/referenciaprodutos");
              })
              .catch((error) => {
                console.log(error);
              });
            swal.fire("Excluído!", "Produto foi excluído", "success");
          } else {
            setloadBtnDelete(false);
            setTxtDelete(true);
          }
        })
    );
  }

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>

        <Container>
          <AsideMenu />

          <Content>
            <header className="content">
              <h2>
                <img src={iReference} alt="" />
                <Text id="referenciaprodutos" dm="Referencia de Produtos" />
              </h2>
            </header>

            {loading ? (
              <LoadPage />
            ) : (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <DescriptionTitle>
                  <Text id="info.ref" dm="Informações da referencia" />
                </DescriptionTitle>
                <BoxForm>
                  <Row className="r2">
                    <InputBox>
                      <label htmlFor="">
                        <Text id="empresa" dm="Empresa" />
                      </label>

                      <Controller
                        as={
                          <Select
                            className="basic-single"
                            placeholder="Selecione o toller"
                            options={companies}
                            getOptionLabel={({ company_name }) => company_name}
                            getOptionValue={({ id }) => id}
                          />
                        }
                        control={control}
                        name="company"
                        rules={{ required: true }}
                        defaultValue={reference.branch}
                      />
                      {errors.company && <Err>Selecione a empresa</Err>}
                    </InputBox>
                  </Row>
                  <Row className="r2">
                    <InputBox>
                      <label htmlFor="">
                        <Text id="codigo" dm="Código" />{" "}
                        <Text id="produto" dm="Produto" /> Corteva
                      </label>

                      <Controller
                        as={
                          <Select
                            className="basic-single"
                            placeholder="Selecione o toller"
                            options={products}
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                          />
                        }
                        control={control}
                        name="code_corteva"
                        rules={{ required: true }}
                        defaultValue={reference.product}
                      />
                      {errors.code_corteva && <Err>Selecione a empresa</Err>}
                    </InputBox>
                    <InputBox>
                      <label htmlFor="">
                        <Text id="codigo" dm="Código" />{" "}
                        <Text id="produto" dm="Produto" /> Toller
                      </label>
                      <input
                        name="code_toller"
                        defaultValue={reference.code}
                        ref={register({ required: true })}
                      />
                      {errors.code_toller && <Err>Digite o código</Err>}
                    </InputBox>
                  </Row>
                </BoxForm>

                <Btns className="crem">
                  <Button
                    type="reset"
                    className="delete"
                    onClick={removeReference}
                  >
                    {txtDelete && <Text id="remover" dm="Remover" />}
                    <LoadingBtn
                      marginTop={"10px"}
                      sizeUnit={"px"}
                      size={10}
                      height={2}
                      color={"#fff"}
                      loading={loadBtnDelete}
                    />
                  </Button>
                  <span>
                    <Button
                      type="reset"
                      className="cancel"
                      onClick={history.goBack}
                    >
                      <Text id="cancelar" dm="Cancelar" />
                    </Button>
                    <Button>
                      {txtBtn && <Text id="salvar" dm="Salvar" />}
                      <LoadingBtn
                        marginTop={"10px"}
                        sizeUnit={"px"}
                        size={10}
                        height={2}
                        color={"#fff"}
                        loading={loadingBtn}
                      />
                    </Button>
                  </span>
                </Btns>
              </Form>
            )}
          </Content>
        </Container>
      </Center>
    </>
  );
}
