import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { withSwalInstance } from 'sweetalert2-react';
import swal from 'sweetalert2';
import Select from 'react-select';

import Text from '../../../../components/Text';

//CSS
import {
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  ButtonDelete,
  Button,
  MaterialList,
  MaterialItem,
} from '../../../../styles/style';
import { ErrFloat, LoadingBtn } from '../styles';

const defaultValues = {
  materia: '',
};

export default function AddProducts({
  onSubmit,
  materialProduct,
  id,
  list,
  deleteProduct,
}) {
  const [txtBtn, setTxtBtn] = useState(true);
  const [isClearable] = useState(true);
  const [selectedOption, setselectedOption] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { register, handleSubmit, errors, control, reset } = useForm({
    defaultValues,
  });

  async function addMaterial(data, e) {
    setLoadingBtn(true);
    setTxtBtn(false);
    await onSubmit({
      bom_id: id,
      product_id: data.materia.id,
      quantity: data.qtd,
    }).then((result) => {
      e.target.reset();
      setLoadingBtn(false);
      setTxtBtn(true);
      reset(defaultValues);
    });
  }

  async function deleteMaterial(data) {
    withSwalInstance(
      swal
        .fire({
          title: 'Você tem certeza?',
          text: 'Você não poderá reverter isso!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ABC9C',
          cancelButtonColor: '#FF4E50',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sim, pode deletar!',
        })
        .then(async (result) => {
          if (result.value) {
            await deleteProduct({
              id: data,
            });
          }
        })
    );
  }

  const onSelectChange = (e) => {
    setselectedOption({ selection: e.value });
  };

  return (
    <>
      <Form className="mt" onSubmit={handleSubmit(addMaterial)}>
        <DescriptionTitle>
          <Text id="produtos.materiaprima" dm="Produtos ( Matérias primas )" />
        </DescriptionTitle>

        <BoxForm>
          <Row className="r311">
            <InputBox>
              <label htmlFor="">
                <Text id="menu.produtos" dm="Produtos" />
              </label>
              <Controller
                as={
                  <Select
                    className="basic-single"
                    placeholder="Selecione o produto"
                    options={materialProduct}
                    isClearable
                    getOptionLabel={({ code, name }) => `${code} - ${name}`}
                    getOptionValue={({ id }) => id}
                  />
                }
                control={control}
                name="materia"
                rules={{ required: true }}
              />
              {errors.materia && <ErrFloat>Selecione o produto</ErrFloat>}
            </InputBox>
            <InputBox>
              <label htmlFor="">
                <Text id="quantidade" dm="Quantidade" />
              </label>
              <input
                name="qtd"
                autoComplete="off"
                ref={register({ required: true })}
              />
              {errors.qtd && <ErrFloat>Digite a quantidade</ErrFloat>}
            </InputBox>
            <InputBox>
              <Button>
                {txtBtn && 'Adicionar'}
                <LoadingBtn
                  marginTop={'10px'}
                  sizeUnit={'px'}
                  size={10}
                  height={2}
                  color={'#fff'}
                  loading={loadingBtn}
                />
              </Button>
            </InputBox>
          </Row>
        </BoxForm>

        {list.length === 0 ? (
          ''
        ) : (
          <MaterialList>
            <header>
              <p>
                <Text id="codigo" dm="Código" /> Corteva
              </p>
              <p>
                <Text id="produto" dm="Produto" />
              </p>
              <p>
                <Text id="quantidade" dm="Quantidade" />
              </p>
              <p></p>
            </header>

            {list.map((item, index) => (
              <MaterialItem key={item.id}>
                <p>{item.product?.code}</p>
                <p>{item.product?.name}</p>
                <p>{item?.quantity}</p>
                <ButtonDelete
                  type="button"
                  onClick={() => deleteMaterial(item.id)}
                />
              </MaterialItem>
            ))}
          </MaterialList>
        )}
      </Form>
    </>
  );
}
