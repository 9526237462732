import React, { useRef, useEffect, useCallback, useState } from "react";
import { useField } from "@unform/core";
import { Container, FakeInput, FakeButton } from "./styles";

export default function InputFile({ name, label, required, ...rest }) {
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { fieldName, defaultValue = "", registerField, error } = useField(name);

  const handlePreview = useCallback((e) => {
    setSelectedFile(e.target.files[0]);
  }, []);

  function handleClickImage() {
    return inputRef.current.click();
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files[0]",
      clearValue(ref) {
        ref.value = "";
      },
    });
  }, [fieldName, registerField]);
  return (
    <Container>
      {label && (
        <label htmlFor={fieldName}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <input
        type="file"
        ref={inputRef}
        onChange={handlePreview}
        defaultValue={defaultValue}
        {...rest}
      />
      <FakeInput>
        {selectedFile?.name}
        <FakeButton onClick={handleClickImage}>Selecionar</FakeButton>
      </FakeInput>
      {error && <span className="error">{error}</span>}
    </Container>
  );
}
