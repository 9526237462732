import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import api from "../../../services/api";

// Components
import Header from "../../../components/Header";
import AsideMenu from "../../../components/AsideMenu";
import BtnAdd from "../../../components/BtnAdd";

//Images
import iconTit from "../../../assets/icons/icon-campaing.svg";
import iSearch from "../../../assets/icons/icon-search.svg";
import iCompany from "../../../assets/icons/icon-company.svg";

//CSS
import { Center, TopHeader } from "../../../styles/style";
import {
  Container,
  Content,
  FilterConfig,
  ProductContainer,
  HeaderList,
  ItemList,
} from "./styles";

export default function Company() {
  const [search, setSearch] = useState("");
  const [companies, setCompanies] = useState([]);
  let history = useHistory();

  useEffect(() => {
    async function feachCompanies() {
      await api
        .get(`/companies`)
        .then((result) => {
          setCompanies(result.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    feachCompanies();
  }, []);

  const results = !search
    ? companies
    : companies.filter((e) =>
        e.fantasy_name.toLowerCase().includes(search.toLocaleLowerCase())
      );

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>Configurações</h1>
          </span>
        </TopHeader>
        <Container>
          <AsideMenu />

          <Content>
            <header>
              <h2>
                <img src={iCompany} alt="" />
                Empresa
              </h2>

              <FilterConfig>
                <img src={iSearch} alt="" />
                <input
                  placeholder="Buscar empresa"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FilterConfig>

              <Link to="empresa/novo">
                <BtnAdd />
              </Link>
            </header>

            <ProductContainer>
              <HeaderList>
                <p>Nome</p>
                <p>CPF/CNPJ</p>
                <p>Cidade</p>
              </HeaderList>

              {results.map((company) => (
                <ItemList
                  key={company.id}
                  onClick={() => {
                    history.push(`empresa/${company.id}`);
                  }}
                >
                  <p>{company.fantasy_name}</p>
                  <p>{company.cnpj}</p>
                  <p>{company.address.city}</p>
                </ItemList>
              ))}
            </ProductContainer>
          </Content>
        </Container>
      </Center>
    </>
  );
}
