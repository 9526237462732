import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { useParams } from "react-router";

// Components
import Header from "../../../../components/Header";
import AsideMenu from "../../../../components/AsideMenu";
import BtnBack from "../../../../components/BtnBack";
import Text from "../../../../components/Text";

//Images
import iconTit from "../../../../assets/icons/icon-campaing.svg";
import iBom from "../../../../assets/icons/icon-justification.svg";

//CSS
import {
  Center,
  TopHeader,
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  Button,
  Btns,
} from "../../../../styles/style";

import { Container, Content, Err } from "./styles";

export default function Details({ history }) {
  const [justify, setJustify] = useState([]);
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit, errors } = useForm();

  let { id } = useParams();

  const msgsuccess = () => toast.success("Justificativa atualizada!");
  const msgdelete = () => toast.success("Justificativa removida com sucesso!");

  async function onSubmit(data, e) {
    e.preventDefault();
    await api
      .patch(`/justification-n/${id}`, {
        name: data.name,
        description: data.description || null,
      })
      .then((result) => {
        msgsuccess();
        history.push(`/configuracoes/justificativa/`);
      })
      .catch((error) => {
        console.log(error);
        history.push(`/configuracoes/justificativa/`);
      });
  }

  async function removeJustify() {
    if (
      !(
        (justify.orders && justify.orders[0]) ||
        (justify.bom_product_order_predictions &&
          justify.bom_product_order_predictions[0])
      )
    ) {
      await api
        .delete(`/justification-n/${id}`)
        .then((result) => {
          msgdelete();
          history.push(`/configuracoes/justificativa/`);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error("Não é possível deletar justificativas em uso!");
    }
  }

  useEffect(() => {
    async function loadCompany() {
      await api
        .get(`/justification-n/${id}`)
        .then((result) => {
          setJustify(result.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    loadCompany();
  }, [id]);

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>

        <Container>
          <AsideMenu />

          <Content>
            <header className="content">
              <h2>
                <img src={iBom} alt="" />
                <Text id="justificativa" dm="Justificativa" />
              </h2>
            </header>

            {loading ? (
              ""
            ) : (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <DescriptionTitle>
                  <Text
                    id="info.justificativa"
                    dm="Informações da justificativa"
                  />
                </DescriptionTitle>
                <BoxForm>
                  <Row>
                    <InputBox>
                      <label htmlFor="">
                        <Text id="justificativa" dm="Justificativa" />
                      </label>
                      <input
                        defaultValue={justify.name}
                        name="name"
                        ref={register({ required: true })}
                      />
                      {errors.name && <Err>Digite a justificativa</Err>}
                    </InputBox>
                  </Row>
                  <Row>
                    <InputBox>
                      <label htmlFor="">
                        <Text id="descricao" dm="Descrição" />
                      </label>
                      <input
                        defaultValue={justify.description}
                        name="description"
                        ref={register()}
                      />
                      {errors.description && <Err>Digite a descrição</Err>}
                    </InputBox>
                  </Row>
                </BoxForm>

                <Btns className="crem">
                  {!(
                    (justify.orders && justify.orders[0]) ||
                    (justify.bom_product_order_predictions &&
                      justify.bom_product_order_predictions[0])
                  ) && (
                    <Button
                      type="reset"
                      className="delete"
                      onClick={removeJustify}
                    >
                      <Text id="remover" dm="Remover" />
                    </Button>
                  )}
                  <span>
                    <Button type="reset" className="cancel">
                      <Text id="cancelar" dm="Cancelar" />
                    </Button>
                    <Button>
                      <Text id="salvar" dm="Salvar" />
                    </Button>
                  </span>
                </Btns>
              </Form>
            )}
          </Content>
        </Container>
      </Center>
    </>
  );
}
