import styled from "styled-components";

//icons status
import iconAt from '../../assets/icons/status-aguardandotransito.png'
import iconQuestion from '../../assets/icons/icon-question.svg'
import transito from "../../assets/icons/icon-transito.svg";
import atraso from "../../assets/icons/icon-atraso.svg";
import cancelado from "../../assets/icons/icon-cancelado.svg";
import aguardando from "../../assets/icons/icon-carregamento.svg";
import devolucaoErro from "../../assets/icons/icon-devolucao-erro.svg";
import devolucao from "../../assets/icons/icon-devolucao.svg";
import entregue from "../../assets/icons/icon-entregue.svg";
import importado from "../../assets/icons/icon-import.svg";
import sap from "../../assets/icons/icon-sap.svg";
import venda from "../../assets/icons/icon-venda.svg";
import consolidador from "../../assets/icons/icon-consolidador.svg";
import iconPin from "../../assets/icons/icon-pin.svg";

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  ${props =>
    props.blockScroll &&
    `
    overflow: hidden;
    height: 100%;
  
  `}
  .notborerheader{
    header{
      margin-bottom: 0;
    }
  }
`;

export const ContentMap = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 600px;
  max-width: 2280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const BoxRoute = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10%;
  left: 5%;
  border-radius: 6px;
  background: #4c5062;
  z-index: 99;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.4);

  div.startend {
    display: flex;
    align-items: flex-start;
    padding: 24px;

    div.icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-right: 16px;
      height: 64px;

      i {
        color: #fff;
      }
    }
    ul {
      list-style: none;

      li {
        color: #eeeeee;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        padding-bottom: 16px;
        border-bottom: 1px #707385 solid;

        &:last-child {
          border: 0;
          padding-bottom: 0;
          padding-top: 16px;
        }
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: rgba(26, 188, 156, 1);
    border-radius: 0 0 6px 6px;
    padding: 16px 24px;
    transition: 300ms;

    &:hover {
      background: rgba(26, 188, 156, 0.7);
    }
    &:active {
      background: rgba(26, 188, 156, 0.5);
    }

    img {
      margin-left: 24px;
    }
  }
`;

export const TrackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  color: #fff;
  background: rgba(70, 80, 98, 1);
  border-radius: 24px;
  transition: 300ms;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 10%;
  right: 5%;
  z-index: 99;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.4);

  &:hover {
    background: rgba(70, 80, 98, 0.7);
  }
  &:active {
    background: rgba(70, 80, 98, 0.5);
  }
`;

export const NfHeader = styled.div`
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 32px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: -10%;
  z-index: 99;

  @media screen and (max-width: 900px) {
    bottom: -30%;
  }
  @media screen and (max-width: 375px) {
    bottom: -50%;
  }
`;

export const Status = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 16px 16px 50px;
  height: 54px;
  border-radius: 28px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  background: ${props => props.themesf.bg} no-repeat 20px center;
  background-image:  ${props => `url(${props.themesf.bgi})`};

  /* ${props =>
    props.ocorrencia === "OCLIB" ||
    props.ocorrencia === "OCAGE" ||
    props.ocorrencia === "OCALT"
      ? `background: #2EBDE8 url(${aguardando}) no-repeat 10% center`
      : props.ocorrencia === "OCFIN" || props.ocorrencia === "OCTRA"
      ? `background: #3498db url(${transito}) no-repeat 10% center`
      : props.ocorrencia === "OCTRAA"
      ? `background: #F39C12 url(${atraso}) no-repeat 10% center`
      : props.ocorrencia === "OCENT"
      ? `background: #1ABC9C url(${entregue}) no-repeat 10% center`
      : props.ocorrencia === "OCDEV"
      ? `background: #9B59B6 url(${devolucao}) no-repeat 10% center`
      : props.ocorrencia === "OCDEVE"
      ? `background: #9B59B6 url(${devolucaoErro}) no-repeat 10% center`
      : props.ocorrencia === "OCARS"
      ? `background: #D02DA4 url(${sap}) no-repeat 10% center`
      : props.ocorrencia === "OCLPV"
      ? `background: #2ECC71 url(${venda}) no-repeat 10% center`
      : props.ocorrencia === "OCCAN"
      ? `background: #FF4E50 url(${cancelado}) no-repeat 10% center`
      : props.ocorrencia === "OCCON"
      ? `background: #FF4E50 url(${consolidador}) no-repeat 10% center`
      : `background: #D9D9D9 url(${importado}) no-repeat 10% center`} */

  img {
    margin-right: 8px;
  }
  p{
    color: #fff;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

export const themesf = {
  at: {
      bgi: iconAt,
      bg: '#9B59B6'
  },
  et: {
      bgi: transito,
      bg: '#3498DB',
  },
  ta: {
      bgi: atraso,
      bg: '#F39C12',
  },
  en: {
      bgi: entregue,
      bg: '#2ECC71',
  },
  st:{
    bgi: iconQuestion,
    bg: '#8B8D97',
  },
  ca:{
    bgi: cancelado,
    bg: '#da1e20 ',
  }, 
  not: {
      bgi: '',
      bg: '#8B8D97',
  },
  
};
export const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 32px;

  @media screen and (max-width: 900px) {
    padding-left: 0;
  }
`;
export const Nf = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 90px;
  h3 {
    font-size: 14px;
    color: #8b8d97;
    margin-bottom: 12px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    color: #292d41;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background: #3498db;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    border-radius: 8px;
    padding: 8px 12px;
  }
  @media screen and (max-width: 900px) {
    margin: 0 32px 32px 0;
    &:nth-child(3n + 3) {
      margin: 0 0 32px 0;
    }
  }
  @media screen and (max-width: 375px) {
    &:nth-child(2n + 2) {
      margin: 0 0 32px 0;
    }
  }
`;

export const Emissao = styled(Nf)``;
export const Prazo = styled(Nf)``;
export const Previsao = styled(Nf)``;
export const Entrega = styled(Nf)``;
export const Cfop = styled(Nf)``;

export const Content = styled.div`
  margin: 80px auto 0 auto;
  width: 100%;
  max-width: 1680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 32px;

  @media screen and (max-width: 900px) {
    margin-top: 180px;
  }
  @media screen and (max-width: 375px) {
    margin-top: 290px;
  }

  @media only screen and (max-width: 414px) {
    padding: 24px;
  }
`;

export const ProgressGraph = styled.div`
  width: 85%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 48px;

  span.linebg {
    display: block;
    flex: 1;
    height: 2px;
    background-color: #c8cfd4;
  }
`;

export const Dots = styled.div`
  position: absolute;
  top: center;
  width: 50%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 800px) {
    width: 70%;
  }
`;

export const Dot = styled.span`
  display: flex;
  width: 20px;
  height: 20px;
  background-color: ${props => (props.full ? "#2EBDE8" : "#c8cfd4")};
  border-radius: 10px;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 3px;
  }
`;
export const Line = styled.span`
  flex: 1;
  height: 2px;
  background-color: ${props => (props.full ? "#2EBDE8" : "#c8cfd4")};
`;

export const WrapBoxes = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 48px;
`;

export const Box = styled.div`
  min-width: 320px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 32px;
  margin-right: 32px;

  @media screen and (max-width: 1270px) {
    margin-bottom: 32px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 860px) {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    min-width: auto;
  }

  &:last-child {
    margin-right: 0;
  }

  h2 {
    color: #292d41;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px #8b8d97 solid;

    img {
      margin-right: 16px;
    }
  }

  div.info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 16px;

    span {
      min-width: 100px;
      display: block;
      font-size: 12px;
      font-weight: 600;
      color: #292d41;
      margin-right: 16px;
    }

    p {
      flex: 1;
      font-size: 11px;
      font-weight: 600;
      color: #8b8d97;
    }
  }
`;

export const BoxNfItems = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 32px;
  margin-bottom: 48px;
`;

export const Totals = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px #8b8d97 solid;
`;

export const TotalItem = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    width: 50%;
    margin-bottom: 32px;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    margin-bottom: 32px;
  }

  span {
    color: #707385;
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-bottom: 16px;
  }
  big {
    color: #3498db;
    font-size: 24px;
    font-weight: 600;
    display: block;
    span{
      color: #3498db;
      font-size: 24px;
      font-weight: 600;
      display: block;
    }
  }

  &:first-child {
    big {
      color: #1abc9c;
      span{
        color: #1abc9c;
      }
    }
  }
`;

export const HeaderItems = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  justify-content: space-between;
`;

export const TitleCodProd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100px;
  margin-right: 24px;
  font-size: 12px;
  font-weight: 600;
  color: #4c5062;
`;

export const TitleDescrProd = styled(TitleCodProd)`
  flex: 1;
  @media screen and (max-width: 640px) {
    display: none;
  }
`;

export const TitleQdt = styled(TitleCodProd)`
  @media screen and (max-width: 640px) {
    display: none;
  }
`;
export const TitleGrossWeight = styled(TitleCodProd)`
  @media screen and (max-width: 1120px) {
    display: none;
  }
`;
export const TitleNetWeight = styled(TitleCodProd)`
  @media screen and (max-width: 1120px) {
    display: none;
  }
`;
export const TitleUnitValue = styled(TitleCodProd)`
  @media screen and (max-width: 1120px) {
    display: none;
  }
`;
export const TitleValue = styled(TitleCodProd)``;

export const ItemNf = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  padding-bottom: 16px;
  justify-content: space-between;
  border-bottom: 2px rgba(139, 141, 151, 0.5) dotted;
`;

export const CodProd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100px;
  margin-right: 24px;
  font-size: 12px;
  font-weight: 600;
  color: #8b8d97;
`;

export const DescrProd = styled(CodProd)`
  flex: 1;
  @media screen and (max-width: 640px) {
    display: none;
  }
`;

export const Qtd = styled(CodProd)`
  @media screen and (max-width: 640px) {
    display: none;
  }
`;
export const GrossWeight = styled(CodProd)`
  @media screen and (max-width: 1120px) {
    display: none;
  }
`;
export const NetWeight = styled(CodProd)`
  @media screen and (max-width: 1120px) {
    display: none;
  }
`;
export const UnitValue = styled(CodProd)`
  @media screen and (max-width: 1120px) {
    display: none;
  }
`;
export const Value = styled(CodProd)``;

export const Logs = styled.div`
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
`;
export const Activities = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  max-width: 430px;
  border-left: 2px #c8cfd4 solid;
  padding-left: 32px;

  @media screen and (max-width: 1075px) {
    margin-bottom: 48px;
  }

  h2 {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    color: #292d41;
    margin-bottom: 24px;

    img {
      margin-right: 16px;
    }
  }

  button {
    display: flex;
    justify-content: flex-start;
    background: transparent;
    color: #2ea1e8;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 16px;
  }
`;

export const Activity = styled.li`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 16px 32px 32px 16px;
  border-radius: 6px;
  position: relative;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 48px;

  &:after {
    content: "";
    position: absolute;
    left: -9px;
    top: 18px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
  }

  p {
    padding-left: 32px;
    font-size: 12px;
    color: #292d41;
    &.type{
      font-weight: 300;
      color: #a5a5a5
    }
  }
`;
export const Pin = styled.span`
  display: flex;
  position: absolute;
  left: -42px;
  top: 18px;
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 10px;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 3px;
  }
`;
export const User = styled.h3`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: #707385;
  margin-bottom: 12px;
  img {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 8px;
  }
`;
export const Calendar = styled.small`
  position: absolute;
  left: 0;
  bottom: -24px;
  font-size: 10px;
  font-weight: 600;
  color: #8b8d97;
  font-style: italic;
`;

export const Justification = styled(Activities)``;

export const Config = styled.div`
  display: flex;
  position: relative;
  img{
    width: 25px;
    transition: all ease 0.2s;
    cursor: pointer;
    &:hover{
      transform: rotate(45deg);
    }
  }
`
export const ConfigOptions = styled.div`
    position: absolute;
    width: 235px;
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    border-radius: 6px;
    top: 0;
    bottom: 0;
    margin: auto;
    right: calc(100% + 20px);
    background: #4C5062;
    box-shadow: 0px 3px 16px rgba(0,0,0, 0.26);
    span{
      color: #da7979;
      padding: 20px 0;
      &.ab{
        color: rgba(26,188,156,1)
      }
    }
    
    &:before{
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        right: -8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 8px;
        border-color: transparent transparent transparent #4C5062;
    }
    p{
      font-size: 14px;
      color: #fff;
      padding: 20px 0;
      border-bottom: solid 1px #707385;
      cursor: pointer;
      transition: all ease 0.2s;
      &:hover{
        color: #3598db;
      }
      &:last-child{
        border: 0;
      }
    }
`

export const ModalConfig = styled.div`
  max-width: 520px;
  width: 96%;
  padding: 60px;
  border-radius: 10px;
  background: #fff;
  p{
    &.tit{
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  label{
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 300;
    margin-top: 20px;
  }
  input, select {
    width: 100%;
    height: 45px;
    border: 0;
    font-size: 14px;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 6px;
    border: solid 1px #999;
    font-weight: 400;
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
    }
  }
  textarea{
    width: 100%;
    height: 100px;
    border: solid 1px #999;
    font-size: 14px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 6px;
    resize: none;
    outline: none;
  }
  .react-datepicker-wrapper{
    width: 100%;
  }
  .btns{
    display: flex;
    justify-content: space-between;
    button{
      width: 48%;
      margin: auto;
      height: 50px;
      background: #1ABC9C;
      color: #fff;
      border-radius: 6px;
      margin-top: 10px;
      font-size: 14px;
      &.cancel{
        background: #8B8D97;
      }
      &.remove{
        background: #FF4E50;
      }
    }
  }
`
export const Erro = styled.div`
    font-size: 12px;
    color: red;
    padding-left: 10px;
    padding-bottom: 5px;
    margin-top: 5px;
`
export const MsgOk = styled.div`
  color: #1abc9c;
    font-size: 18px;
    font-weight: 400;
    margin: 40px 0;
    text-align: center;
  .icon{
    width: 60px;
    height: 60px;
    background: url(${entregue})no-repeat center center #1abc9c;
    margin: auto;
    border-radius: 50%;
    margin-bottom: 10px;
    background-size: 80%;
    transition: all ease 0.2s;
  }
`
export const ModalLocation = styled.div`
   width:   100%;
   max-width: 920px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #4c5062;
  padding: 48px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3);
  header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .close{
    color: #fff;
    padding: 8px 12px;
    border: solid 1px #fff;
    border-radius: 6px;
    transition: all ease 0.2s;
    cursor: pointer;
    &:hover{
      color: #da1e20;
      background: #fff;
    }
  }
  .tit{
    display: flex;
    align-items: flex-start;
    padding-left: 30px;
    background: url(${iconPin}) no-repeat left center;
    font-size: 18px;
    color: #fff;
    text-align: left;
  }
  .wrap{
    width: 100%;
    height: 280px;
    overflow-y: auto;
    .item{
      display: flex;
      justify-content: space-between;
      padding: 12px 20px;
      border-bottom: solid 1px #707385;
      &:last-child{
        border: 0;
      }
      p{
        font-size: 12px;
        color: #fff;
        font-weight: 300;
      }
    }
  }
`
