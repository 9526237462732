import styled from "styled-components";
import { Warning } from "@styled-icons/entypo";
import { CommentDetail } from "@styled-icons/boxicons-regular";
import { Stack } from "@styled-icons/icomoon";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Item = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns:
    2fr 32px repeat(4, minmax(90px, auto))
    32px 32px;
  column-gap: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  height: 54px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
`;

export const Value = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.dark};

  &.diff {
    color: ${({ theme }) => theme.colors.magenta};
  }
`;

export const IconWarning = styled(Warning).attrs({ size: 20 })`
  color: ${({ theme }) => theme.statusColors.divergente};
`;

export const IconComment = styled(CommentDetail).attrs({ size: 20 })`
  color: ${({ theme }) => theme.colors.lightDarkBlue};
`;

export const CommentButton = styled.button.attrs({ type: "button" })`
  width: 20px;
  height: 20px;
  display: flex;
  transition: 300ms ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const PercentBox = styled.div`
  width: 80px;
  height: 54px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.pink};
  font-family: ${({ theme }) => theme.fonts.titleBold};
  font-size: 18px;
`;

export const IconGroup = styled(Stack).attrs({ size: 20 })`
  color: ${({ theme }) => theme.colors.lightDarkBlue};
`;
