import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import api from "../../../services/api";

// Components
import Header from "../../../components/Header";
import BtnBack from "../../../components/BtnBack";
import LoadPage from "../../../components/LoadPage";
import AddReceptor from "./addReceptor";

//Images
import iconTit from "../../../assets/icons/icon-alert.svg";

//CSS
import * as Ss from "../../../styles/style";
import * as S from "../styles";

function DetailsAlert({ history }) {
  const [alert, setAlerts] = useState();
  const [loading, setLoading] = useState(true);

  let { id } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function loadAlert() {
    setLoading(true);
    await api
      .get(`/alert-n/${id}`)
      .then((result) => {
        setAlerts(result.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  async function onSubmit(data) {
    await api
      .post(`/receiver/`, data)
      .then((result) => {
        console.log(result);
        loadAlert();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function onDelete(data) {
    await api
      .delete(`/receiver/${data.id}`)
      .then((result) => {
        console.log(result);
        loadAlert();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <Ss.Center>
        <Ss.TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>Alerta</h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </Ss.TopHeader>
        <S.Container>
          {loading ? (
            <LoadPage />
          ) : (
            <>
              <Ss.Form>
                <Ss.DescriptionTitle>Informações</Ss.DescriptionTitle>

                <Ss.BoxForm>
                  <Ss.Row className="r1-3">
                    <Ss.InputBox>
                      <label htmlFor="">Código</label>
                      <S.Description>{alert.id}</S.Description>
                    </Ss.InputBox>
                    <Ss.InputBox>
                      <label htmlFor="">Nome</label>
                      <S.Description>{alert.name}</S.Description>
                    </Ss.InputBox>
                  </Ss.Row>

                  <Ss.Row>
                    <Ss.InputBox>
                      <label htmlFor="">Descrição</label>
                      <S.Description>{alert.description}</S.Description>
                    </Ss.InputBox>
                  </Ss.Row>
                </Ss.BoxForm>
              </Ss.Form>
              <AddReceptor
                id={id}
                onSubmit={onSubmit}
                onDelete={onDelete}
                receptors={alert.receiver_alerts}
              />
            </>
          )}
        </S.Container>
      </Ss.Center>
    </>
  );
}

export default DetailsAlert;
