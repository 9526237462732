import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import api from "../../../services/api";

import * as S from "./styles";
import ActionsCompareCampaign from "../../../store/ducks/compare/compare-campaign";
import ActionsBranchInvoiceOrderNotFinished from "../../../store/ducks/branch-invoices/get-order-not-finished";

import Header from "../../../components/Header";
import { ItemProductCampaign } from "../../../components/Consume";

import moment from "moment";
import Input from "../../../components/shared/Form/Input";

import { Form } from "@unform/web";
import { useRef } from "react";
import { useValidation } from "../../../hooks";

const CompareCampaign = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useState();
  const [modalCienteOpen, setModalCienteOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleFormErrors } = useValidation();
  const { order } = useParams();

  // console.log(
  //   "actions",
  //   ActionsBranchInvoiceOrderNotFinished.request().type ===
  //     "BRANCH_INVOICE_ORDER_NOT_FINISHEDREQUEST"
  // );

  const formRef = useRef();
  const containerRef = useRef(null);


  const { data: compareData, loading } = useSelector(
    (state) => state.compareCampaign
  );

  const { data: invoiceNotFinishedData, invoiceNotFinishedLoading } =
    useSelector((state) => state.branchInvoiceOrderNotFinished);

  const getBranchInvoiceNotFinished = useCallback(() => {
    dispatch(ActionsBranchInvoiceOrderNotFinished.request(order));
  }, [dispatch, order]);

  const getData = useCallback(() => {
    dispatch(ActionsCompareCampaign.request(order, history));
  }, [dispatch, history, order]);

  useEffect(() => {
    getData();
    getBranchInvoiceNotFinished();
  }, [getData, getBranchInvoiceNotFinished]);

  const itemQuantityCount = (array, product_id) => {
    let ItemArray = array.filter((item) => {
      return item.product_id == product_id;
    });

    let totalQuantity = ItemArray.reduce(
      (total, item) => item.quantity + total,
      0
    );

    return totalQuantity;
  };

  async function userVal() {
    const res = await api.post(`/me`);
    setUser(res?.data);
  }

  useEffect(() => {
    userVal();
  }, []);

  const optionsDate =  { timeZone: 'America/Sao_Paulo' };

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      console.log("User:", user);
      data.order_id = compareData?.order?.id;
      data.user_id = user?.id;
      try {
        const retorno = await api.post(`/order-consume-review`, data);
        setModalCienteOpen(false);
        history.push("/");
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors]
  );

  const handleClickOutside = (event) => {
    const classList = event.target.classList;
    console.log(classList);
    if (
      modalCienteOpen &&
      containerRef.current &&
      event.target.classList.contains("modalCiente")
    ) {
      console.log(containerRef);
      setModalCienteOpen(false);
    }
  };

  const user_ciente = compareData?.order?.order_consume_reviews[0];

  const allow_ciente =
    user?.permission == "root" &&
    compareData?.percentFinalConcluido >= 95 &&
    compareData?.percentFinalPendenteConsumo == 0;

  return (
    <S.Container>
      <Header noMargin />
      <S.InnerHeader>
        <S.WrapperHeader>
          <S.Title>
            <S.IconConsume />
            Campanha {compareData?.order?.alfa_id} -{" "}
            {compareData?.order?.description} {loading && <S.Loading />}
          </S.Title>
          <S.ButtonMini
            btStyle="primary"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Detalhes
          </S.ButtonMini>
        </S.WrapperHeader>
      </S.InnerHeader>
      <S.Indicators>
        <S.Box>
          <S.Item>
            <S.Value color="#30AA92">
              {compareData?.percentFinalConcluido}%
            </S.Value>
            <S.Label>Concluído</S.Label>
          </S.Item>
          <S.Item>
            <S.Value>{compareData?.totalQuantityCampaign}</S.Value>
            <S.Label>Total a produzir</S.Label>
          </S.Item>
          <S.Item>
            <S.Value>{compareData?.totalQuantityConcluido}</S.Value>
            <S.Label>Produzido</S.Label>
          </S.Item>
          <S.Item>
            <S.Value>{compareData?.totalProduct}</S.Value>
            <S.Label>Expedido</S.Label>
          </S.Item>
          <S.Item>
            <S.Value>{compareData?.totalRawMaterial}</S.Value>
            <S.Label>Materia prima</S.Label>
          </S.Item>
          <S.Item>
            <S.Value color="#FF006C">
              {compareData?.percentFinalProduction > 0
                ? compareData?.percentFinalProduction
                : "0"}
              %
            </S.Value>
            <S.Label>Pendente de Expedição</S.Label>
          </S.Item>
          <S.Item>
            <S.Value color="#FF006C">
              {compareData?.percentFinalPendenteConsumo > 0
                ? compareData?.percentFinalPendenteConsumo
                : "0"}
              %
            </S.Value>
            <S.Label>Pendente de Consumo</S.Label>
          </S.Item>
        </S.Box>
      </S.Indicators>
      <S.Content>
        <S.Wrapper>
          <S.DetailsHeaderConstume>
            <S.ContentTitle>Detalhes do consumo</S.ContentTitle>
            {user_ciente
              ? <S.CienteBy btStyle="alter" >
                {`Ciente por: ${user_ciente?.user?.usuario_nome} em ${new Date(
                  user_ciente?.created_at
                ).toLocaleString('pt-BR', optionsDate)}`}
              </S.CienteBy>
              : allow_ciente && (
                  <S.Button
                    btStyle="alter"
                    onClick={() => setModalCienteOpen(true)}
                    style={{ marginLeft: "5%" }}
                  >
                    Ciente
                  </S.Button>
                )}

            <S.ContainerModalCiente
              open={modalCienteOpen}
              ref={containerRef}
              onClick={handleClickOutside}
              className="modalCiente"
            >
              <S.ContainerComentary>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <S.Title style={{ fontSize: 15, marginBottom: 20 }}>
                    Campanha {compareData?.order?.alfa_id} -{" "}
                    {compareData?.order?.description}
                  </S.Title>
                  <Input
                    label={"Digite um comentário"}
                    name={"comment"}
                    style={{ width: "100%" }}
                  />
                  <S.ButtonComentaryWrapper>
                    <S.Button btStyle="primary" type="submit">
                      Enviar
                    </S.Button>
                    <S.Button
                      btStyle="cancel"
                      type="submit"
                      onClick={() => setModalCienteOpen(false)}
                      style={{ marginLeft: "5%" }}
                    >
                      Cancelar
                    </S.Button>
                  </S.ButtonComentaryWrapper>
                </Form>
                {/* <Input label={"Digite um comentário"} required={true} /> */}
              </S.ContainerComentary>
            </S.ContainerModalCiente>
          </S.DetailsHeaderConstume>
          {compareData?.consumeByProducts?.length ? (
            compareData.consumeByProducts.map((consume) => (
              <ItemProductCampaign data={consume} key={Math.random(10)} />
            ))
          ) : (
            <S.NoRecords>
              Não foi localizado nenhum consumo aprovado.
            </S.NoRecords>
          )}
        </S.Wrapper>
      </S.Content>
      <S.ContainerModal open={modalOpen}>
        <S.ModalBoxNew>
          <S.Title>
            Campanha {compareData?.order?.alfa_id} -{" "}
            {compareData?.order?.description}
          </S.Title>
          <S.ModalContainerFlex>
            <S.ModalGrid>
              <S.ModalGridItemHeader>ID</S.ModalGridItemHeader>
              <S.ModalGridItemHeader>Toller</S.ModalGridItemHeader>
              <S.ModalGridItemHeader>Number</S.ModalGridItemHeader>
              <S.ModalGridItemHeader>Dt.Emissão</S.ModalGridItemHeader>
              <S.ModalGridItemHeader>QTD.NF</S.ModalGridItemHeader>
            </S.ModalGrid>
            {invoiceNotFinishedData &&
              invoiceNotFinishedData.map((data) => {
                return (
                  <S.ContainerLink
                    target="_blank"
                    href={`/consumo/${data.id}/${data.branch_id}/${data.product_id}`}
                  >
                    <S.ModalGridItem>{data.id}</S.ModalGridItem>
                    <S.ModalGridItem>
                      {data?.branch?.fantasy_name}
                    </S.ModalGridItem>
                    <S.ModalGridItem>{data.number}</S.ModalGridItem>
                    <S.ModalGridItem>
                      {data.emission_date
                        ? moment(data.emission_date).format("DD/MM/YYYY")
                        : "-"}
                    </S.ModalGridItem>
                    <S.ModalGridItem>
                      {data?.invoiceItems && data?.invoiceItems?.length
                        ? itemQuantityCount(data.invoiceItems, data.product_id)
                        : "-"}
                    </S.ModalGridItem>
                  </S.ContainerLink>
                );
              })}
          </S.ModalContainerFlex>
          <S.ModalFlexButton>
            <S.ButtonModalNew
              btStyle="danger"
              type="button"
              onClick={() => setModalOpen(false)}
            >
              Voltar
            </S.ButtonModalNew>
          </S.ModalFlexButton>
        </S.ModalBoxNew>
      </S.ContainerModal>
    </S.Container>
  );
};

export default CompareCampaign;
