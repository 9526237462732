import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import api from "../../../services/api";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// Components
import Header from "../../../components/Header";
import AsideMenu from "../../../components/AsideMenu";
import BtnAdd from "../../../components/BtnAdd";
import BtnExcel from "../../../components/BtnExcel";
import BtnBack from "../../../components/BtnBack";
import IconStatus from "../../../components/IconStatus";
import Modal from "../../Campaigns/Details/modalPredictions";
import Text from "../../../components/Text";

//Images
import iconTit from "../../../assets/icons/icon-campaing.svg";
import iProduct from "../../../assets/icons/icon-products.svg";
import iSearch from "../../../assets/icons/icon-search.svg";

//CSS
import { Center, TopHeader } from "../../../styles/style";
import {
  Container,
  Content,
  FilterConfig,
  ProductContainer,
  HeaderList,
  ItemList,
  Loading,
} from "./styles";

const cssloading = css`
  display: flex;
  margin: 40px auto;
  justify-content: center;
`;

export default function Products() {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [productPredictionId, setProductPredictionId] = useState();
  const [open, setOpen] = useState(false);

  const [file, setFile] = useState("");
  const [fileName, seFileName] = useState("");

  let history = useHistory();

  useEffect(() => {
    async function feachProduct() {
      await api
        .get(`/product?perPage=10000`)
        .then((result) => {
          setProducts(result.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    feachProduct();
  }, []);

  useEffect(() => {
    function generateExcelData() {
      let arrayExcel = [];

      products.forEach((product) => {
        const DESCRICAO = product.name || "-";
        const CODIGO = product.code || "-";
        const UM = (product.unit && product.unit.name) || "-";
        const ESTOQUE = product.balance || "-";
        const TIPO = (product.type && product.type.name) || "-";

        const data = {
          DESCRICAO,
          CODIGO,
          UM,
          ESTOQUE,
          TIPO,
        };

        arrayExcel.push(data);
      });

      return arrayExcel;
    }

    if (products[0]) {
      const newFile = generateExcelData();

      setFile(newFile);

      const newFileName = `PRODUTOS_${moment(new Date()).format("DD_MM_YYYY")}`;

      seFileName(newFileName);
    }
  }, [products]);

  function exportToCSV() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(file);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const handleCloseModal = () => {
    setOpen(false);
  };

  function handleOpenModal(productId) {
    setProductPredictionId(productId);
    setOpen(true);
  }

  const results = !search
    ? products
    : products.filter(
        (e) =>
          e.name.toLowerCase().includes(search.toLocaleLowerCase()) ||
          e.code.toLowerCase().includes(search.toLocaleLowerCase())
      );

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>

        {open && (
          <Modal
            id={productPredictionId}
            open={open}
            handleClose={handleCloseModal}
          />
        )}

        <Container>
          <AsideMenu />

          <Content>
            <header>
              <h2>
                <img src={iProduct} alt="" />
                <Text id="menu.produtos" dm="Produtos" />
              </h2>
              <FilterConfig>
                <img src={iSearch} alt="" />
                <input
                  placeholder="Buscar produto"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FilterConfig>

              {!loading && (
                <BtnExcel
                  type="button"
                  disabled={!products}
                  onClick={() => exportToCSV()}
                  text="Exportar Relatório"
                />
              )}

              <Link to="produtos/novo">
                <BtnAdd />
              </Link>
            </header>

            <ProductContainer>
              <HeaderList>
                <p>
                  <Text id="descricao" dm="Descrição" />
                </p>
                <p>
                  <Text id="codigo" dm="Código" />
                </p>
                <p>
                  <Text id="unmedida" dm="Unidade de Medida" />
                </p>
                <p>
                  <Text id="estoque" dm="Estoque" />{" "}
                </p>
                <p>
                  <Text id="tipo" dm="Tipo" />{" "}
                </p>
                <p>
                  <Text id="previsao" dm="Previsões" />{" "}
                </p>
              </HeaderList>

              {loading ? (
                <Loading
                  css={cssloading}
                  sizeUnit={"px"}
                  size={10}
                  height={2}
                  color={"#0459a9"}
                />
              ) : (
                <>
                  {results.map((product) => (
                    <ItemList
                      key={product.id}
                      onClick={() => {
                        history.push(`produtos/${product.id}`);
                      }}
                    >
                      <p>{product.name}</p>
                      <p>{product.code}</p>
                      <p>{(product.unit && product.unit.name) || "Não informado"}</p>
                      <p>{product.balance || "Não informado"}</p>
                      <p>{(product.type && product.type.name) || "Não informado"}</p>
                      <IconStatus
                        theme="info"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenModal(product.id);
                        }}
                      />
                    </ItemList>
                  ))}
                </>
              )}
            </ProductContainer>
          </Content>
        </Container>
      </Center>
    </>
  );
}
