import { combineReducers } from 'redux';

import { reducer as auth } from './auth';
import { reducer as language } from './language';
import { reducer as branchInvoices } from './branch-invoices/list';
import { reducer as branchInvoiceCreate } from './branch-invoices/create';
import { reducer as branchInvoiceUpdateOrder } from './branch-invoices/update-order';
import { reducer as compareInvoice } from './compare/invoice-compare';
import { reducer as compareApprove } from './compare/compare-approve';
import { reducer as compareApproved } from './compare/compare-approved';
import { reducer as compareCampaign } from './compare/compare-campaign';
import { reducer as compareCreateJustification } from './compare/create-justification';
import { reducer as compareDeleteJustification } from './compare/delete-justification';
import { reducer as deleteInvoice } from './branch-invoices/delete-invoice';
import { reducer as sapCreate } from './sap/create';
import { reducer as sapCreateMultiples } from './sap/create-multiples';
import { reducer as sapDelete } from './sap/delete';
import { reducer as ordersCombobox } from './orders/orders-combobox';
import { reducer as branchRawInvoiceCreate } from './branch-raw-invoices/create';
import { reducer as branchRawInvoiceDelete } from './branch-raw-invoices/delete';
import { reducer as branchRawInvoices } from './branch-raw-invoices/list';
import { reducer as branchInvoiceOrderNotFinished} from './branch-invoices/get-order-not-finished';
import { reducer as notaFullSearch } from './notafull/search'

const reducers = combineReducers({
  auth,
  language,
  branchInvoices,
  branchInvoiceCreate,
  branchInvoiceUpdateOrder,
  compareInvoice,
  compareApprove,
  compareApproved,
  compareCampaign,
  compareCreateJustification,
  compareDeleteJustification,
  deleteInvoice,
  sapCreate,
  sapCreateMultiples,
  sapDelete,
  ordersCombobox,
  branchRawInvoiceCreate,
  branchRawInvoiceDelete,
  branchRawInvoices,
  branchInvoiceOrderNotFinished,
  notaFullSearch
});

export default reducers;
