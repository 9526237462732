import React, {useState, useEffect} from 'react';
import { useForm } from 'react-hook-form'
import api from '../../../../services/api'
import { toast } from 'react-toastify';
import { useParams} from "react-router";

// Components
import Header from '../../../../components/Header'
import AsideMenu from '../../../../components/AsideMenu'

//Images
import iconTit from '../../../../assets/icons/icon-campaing.svg'
import iCompany from '../../../../assets/icons/icon-company.svg'


//CSS
import { Center, TopHeader, Form, DescriptionTitle, BoxForm, Row, InputBox, Button, Btns } from '../../../../styles/style'

import { Container, Content, Err} from './styles';

export default function Details({history}) {
    
    const [company, setCompany] = useState([])
    const [loading, setLoading] = useState(true)
    const { register, handleSubmit, errors } = useForm()

    let {id} = useParams()

    const msgsuccess = () => toast.success("Empresa atualizada!");
    const msgdelete = () => toast.success("Empresa removida com sucesso!");

    async function onSubmit(data, e){
        e.preventDefault()
        await api.patch(`/companies/${id}`, {
            cnpj: data.cnpj,
            type: data.type,
            company_name: data.company_name,
            fantasy_name: data.fantasy_name,
            address: {
                cep: data.cep,
                add: data.address,
                neighborhood: data.neighborhood,
                num: data.num,
                city: data.city,
                state: data.state
            }
        })
        .then(result => {
            msgsuccess()
            history.push('/configuracoes/empresa')
        })
        .catch(error => {
            console.log(error)
        })
    }

    async function removeCompany(){
        await api.delete(`/companies/${id}`)
        .then(result => {
            msgdelete()
            history.push('/configuracoes/empresa')
        })
        .catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {

        async function loadCompany(){
            
            await api.get(`/companies/${id}`)
            .then(result => {
                setCompany(result.data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
        }

        loadCompany()
    }, [id])



  return (
    <>
        <Header />
        <Center>
            <TopHeader>
                <span>
                    <img src={iconTit} className="icontit" alt=""/>
                    <h1>Configurações</h1>
                </span>
            </TopHeader>

            <Container>
                <AsideMenu />
                
                <Content>
                    <header className="content">
                        <h2>
                            <img src={iCompany} alt=""/> 
                            Empresa
                        </h2>
                    </header>
                    
                    {loading ? '' : 
                    
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <DescriptionTitle>
                                Informações da empresa
                            </DescriptionTitle>
                            <BoxForm>
                                <Row className="r2">
                                    <InputBox>
                                        <label htmlFor="">CNPJ</label>
                                        <input defaultValue={company.cnpj} name="cnpj" ref={register({ required: true })}  />
                                        {errors.cnpj && <Err>Digite o CNPJ</Err>}
                                    </InputBox>
                                    <InputBox>
                                        <label htmlFor="">Tipo</label>
                                        <select defaultValue={company.type}  name="type" id="" ref={register({ required: true })}>
                                            <option value="">Selecione</option>
                                            <option value="toller">Toller</option>
                                            <option value="teste">Teste</option>
                                        </select>
                                        {errors.type && <Err>Selecione o tipo</Err>}
                                    </InputBox>
                                    
                                </Row>
                                <Row className="r2">
                                    <InputBox>
                                        <label htmlFor="">Razão Social</label>
                                        <input name="company_name" defaultValue={company.company_name} ref={register({ required: true })} />
                                        {errors.company_name && <Err>Digite a razão social</Err>}
                                    </InputBox>
                                    <InputBox>
                                        <label htmlFor="">Nome Fantasia</label>
                                        <input defaultValue={company.fantasy_name} name="fantasy_name" ref={register({ required: true })} />
                                        {errors.fantasy_name && <Err>Digite o nome fantasia</Err>}
                                    </InputBox>
                                    
                                </Row>
                            </BoxForm>

                            <DescriptionTitle>
                                Endereço
                            </DescriptionTitle>
                            <BoxForm>
                                <Row className="r2">
                                    <InputBox>
                                        <label htmlFor="">CEP</label>
                                        <input name="cep" defaultValue={company.address.cep} ref={register({ required: true })}/>
                                        {errors.cep && <Err>Digite o cep</Err>}
                                    </InputBox>
                                    <InputBox>
                                        <label htmlFor="">Endereço</label>
                                        <input name="address" defaultValue={company.address.add} ref={register({ required: true })} />
                                        {errors.address && <Err>Digite o endereço</Err>}
                                    </InputBox> 
                                </Row>
                                <Row className="r2">
                                    <InputBox>
                                        <label htmlFor="">Bairro</label>
                                        <input name="neighborhood" defaultValue={company.address.neighborhood} ref={register({ required: true })} />
                                        {errors.neighborhood && <Err>Digite o bairro</Err>}
                                    </InputBox>
                                    <InputBox>
                                        <label htmlFor="">Número</label>
                                        <input name="num" defaultValue={company.address.num} ref={register({ required: true })} />
                                        {errors.num && <Err>Digite o número</Err>}
                                    </InputBox> 
                                </Row>
                                <Row className="r2">
                                    <InputBox>
                                        <label htmlFor="">Cidade</label>
                                        <input name="city" defaultValue={company.address.city} ref={register({ required: true })} />
                                        {errors.city && <Err>Digite a cidade</Err>}
                                    </InputBox>
                                    <InputBox>
                                        <label htmlFor="">Estado</label>
                                        <input name="state" defaultValue={company.address.state} ref={register({ required: true })} />
                                        {errors.state && <Err>Digite o estado</Err>}
                                    </InputBox> 
                                </Row>
                            </BoxForm>

                            
                            <Btns className="crem">
                                <Button type="reset" className="delete" onClick={removeCompany}>Remover</Button>
                                <span>
                                    <Button type="reset" className="cancel">Cancelar</Button>
                                    <Button>Salvar</Button>
                                </span>
                            </Btns>

                        </Form>
                    }
                </Content>
            </Container>
        </Center>
    </>
  );
}
