import React, {useState, useEffect} from 'react';
import api from '../../../services/api';
import moment from "moment";
import { useParams} from "react-router";

//Images
import iconJustification from "../../../assets/icons/icon-justification.svg";

// CSS
import { Container, Activity, Pin, User, Calendar } from './styles';

export default function Justification(props) {
    const [justification, setJustification] = useState([])
    let {idnf} = useParams()

    useEffect(() => {
        async function loadJustify() {
            const res = await api.get(`/justification?invoice_id=${idnf}&perPage=10000`)
            setJustification(res.data.data)
        }
        loadJustify()
      }, [idnf, props])
      
  return (
    <Container>
      <h2>
        <img src={iconJustification} alt="Justificativa" />
        Justificativas
      </h2>
      {justification &&
        justification.map(justify => (
          <Activity key={justify.id} >
            <Pin />
            { justify.user !== null && 
              <User>
                <img src={justify.user.avatar} alt="" />
                {justify.user.name}
              </User>
            }
            <p className="type">{justify.justification_type}</p>
            <p>{justify.justification}</p>
            <Calendar>{justify.date ?  moment(justify.date ).format('DD/MM/YYYY')  : ''} </Calendar> 
            {/* new Date(justify.date).toISOString().split('T')[0] */}
            
          </Activity>
        ))} 
    </Container>
  );
}
