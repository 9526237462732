import React from 'react';
import { useForm } from 'react-hook-form'
import api from '../../../../services/api'
import { toast } from 'react-toastify';

// Components
import Header from '../../../../components/Header'
import AsideMenu from '../../../../components/AsideMenu'

//Images
import iconTit from '../../../../assets/icons/icon-campaing.svg'
import iCompany from '../../../../assets/icons/icon-company.svg'


//CSS
import { Center, TopHeader, Form, DescriptionTitle, BoxForm, Row, InputBox, Button, Btns } from '../../../../styles/style'

import { Container, Content, Err} from './styles';

export default function New() {
    const { register, handleSubmit, errors } = useForm()

    const msgsuccess = () => toast.success("Empresa cadastrada com sucesso!");

    async function onSubmit(data, e){
        
        await api.post(`/companies`, {
            cnpj: data.cnpj,
            type: data.type,
            company_name: data.company_name,
            fantasy_name: data.fantasy_name,
            address: {
                cep: data.cep,
                add: data.address,
                neighborhood: data.neighborhood,
                num: data.num,
                city: data.city,
                state: data.state
            }
        })
        .then(result => {
            e.target.reset()
            console.log(result)
            msgsuccess()
        })
        .catch(error => {
            console.log(error)
        })
    }


  return (
    <>
        <Header />
        <Center>
            <TopHeader>
                <span>
                    <img src={iconTit} className="icontit" alt=""/>
                    <h1>Configurações</h1>
                </span>
            </TopHeader>

            <Container>
                <AsideMenu />
                
                <Content>
                    <header className="content">
                        <h2>
                            <img src={iCompany} alt=""/> 
                            Nova Empresa
                        </h2>
                    </header>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <DescriptionTitle>
                            Informações da empresa
                        </DescriptionTitle>
                        <BoxForm>
                            <Row className="r2">
                                <InputBox>
                                    <label htmlFor="">CNPJ</label>
                                    <input name="cnpj" ref={register({ required: true })} />
                                    {errors.cnpj && <Err>Digite o CNPJ</Err>}
                                </InputBox>
                                <InputBox>
                                    <label htmlFor="">Tipo</label>
                                    <select name="type" id="" ref={register({ required: true })}>
                                        <option value="">Selecione</option>
                                        <option value="toller">Toller</option>
                                        <option value="transportador">Transportador</option>
                                        <option value="embarcador">Embarcador</option>
                                        <option value="centrodedistribuicao">Centro de Distribuição</option>
                                        
                                    </select>
                                    {errors.type && <Err>Selecione o tipo</Err>}
                                </InputBox>
                                
                            </Row>
                            <Row className="r2">
                                <InputBox>
                                    <label htmlFor="">Razão Social</label>
                                    <input name="company_name" ref={register({ required: true })} />
                                    {errors.company_name && <Err>Digite a razão social</Err>}
                                </InputBox>
                                <InputBox>
                                    <label htmlFor="">Nome Fantasia</label>
                                    <input name="fantasy_name" ref={register({ required: true })} />
                                    {errors.fantasy_name && <Err>Digite o nome fantasia</Err>}
                                </InputBox>
                                
                            </Row>
                        </BoxForm>

                        <DescriptionTitle>
                            Endereço
                        </DescriptionTitle>
                        <BoxForm>
                            <Row className="r2">
                                <InputBox>
                                    <label htmlFor="">CEP</label>
                                    <input name="cep" ref={register({ required: true })}/>
                                    {errors.cep && <Err>Digite o cep</Err>}
                                </InputBox>
                                <InputBox>
                                    <label htmlFor="">Endereço</label>
                                    <input name="address" ref={register({ required: true })} />
                                    {errors.address && <Err>Digite o endereço</Err>}
                                </InputBox> 
                            </Row>
                            <Row className="r2">
                                <InputBox>
                                    <label htmlFor="">Bairro</label>
                                    <input name="neighborhood" ref={register({ required: true })} />
                                    {errors.neighborhood && <Err>Digite o bairro</Err>}
                                </InputBox>
                                <InputBox>
                                    <label htmlFor="">Número</label>
                                    <input name="num" ref={register({ required: true })} />
                                    {errors.num && <Err>Digite o número</Err>}
                                </InputBox> 
                            </Row>
                            <Row className="r2">
                                <InputBox>
                                    <label htmlFor="">Cidade</label>
                                    <input name="city" ref={register({ required: true })} />
                                    {errors.city && <Err>Digite a cidade</Err>}
                                </InputBox>
                                <InputBox>
                                    <label htmlFor="">Estado</label>
                                    <input name="state" ref={register({ required: true })} />
                                    {errors.state && <Err>Digite o estado</Err>}
                                </InputBox> 
                            </Row>
                        </BoxForm>

                        
                        <Btns>
                            <Button type="reset" className="cancel">Cancelar</Button>
                            <Button>Salvar</Button>
                        </Btns>

                    </Form>
                </Content>
            </Container>
        </Center>
    </>
  );
}
