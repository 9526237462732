import React from "react";
import { format } from "date-fns";
import * as S from "./styles";

const ItemRawInvoice = ({ invoice, onDelete }) => {
  return (
    <S.Container>
      <S.Item>
        <S.Value>NF: {invoice?.number}</S.Value>
        <S.Value>
          DT. Emissão:{" "}
          {invoice?.emission_date &&
            format(new Date(invoice.emission_date), "dd/MM/yyyy")}
        </S.Value>
        <S.Value>
          QTD.Items: {invoice?.invoiceItems && invoice?.invoiceItems.length}
        </S.Value>
        <S.Value className="last">
          Data:{" "}
          {invoice?.created_at &&
            format(new Date(invoice.created_at), "dd/MM/yyyy HH:mm")}
        </S.Value>
        <S.TrashButton onClick={() => onDelete(invoice?.id)}>
          <S.IconTrash />
        </S.TrashButton>
      </S.Item>
    </S.Container>
  );
};

export default ItemRawInvoice;
