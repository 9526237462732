import { call, put, select, all } from "redux-saga/effects";
import { notify } from "../../../services/notification";
import api, { makeOptions } from "../../../services/api";
import ActionsCompareCampaign from "../../ducks/compare/compare-campaign";

const getAuthData = (state) => state.auth.data;

export function* fetch(action) {
  try {
    const { id } = action;
    const url = `bom-compare/${id}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.get, url, makeOptions(authData));

    yield put(ActionsCompareCampaign.success(data));
  } catch (err) {
    const { history } = action;
    notify("error", err.response.data.error.message);
    if(history) {
      history.push("/");
    }
    yield all([
      put(ActionsCompareCampaign.failure(err.response.data.error.message)),
      put(ActionsCompareCampaign.success(null)),
    ]);
  }
}
