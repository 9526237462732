import styled from "styled-components";

import bgLogin from "../../assets/bglogin.jpg";
import check from "../../assets/icons/icon-check.png";
import { BarLoader } from "react-spinners";

export const LoadingBtn = styled(BarLoader)``;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${bgLogin}) no-repeat center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 640px) {
    padding: 40px 10px;
    box-sizing: border-box;
    height: auto;
  }
`;
export const ContentLogin = styled.div`
  max-width: 460px;
  width: 100%;
  text-align: center;
  img {
    margin-bottom: 40px;
  }
  .box-login {
    background: #202331;
    border-radius: 20px;
    padding: 80px 40px 20px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    @media (max-width: 640px) {
      padding: 80px 30px 20px;
    }
    p.tit {
      font-size: 30px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 5px;
    }
    p {
      font-size: 16px;
      color: #8b8d97;
    }
  }
`;
export const Form = styled.form`
  margin: 40px 0;
  input[type="email"],
  input[type="password"] {
    width: 100%;
    height: 60px;
    border: 0;
    font-size: 14px;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 0 0 6px 6px;
    font-weight: 400;
    &:nth-child(1) {
      border-radius: 6px 6px 0 0;
      border-bottom: solid 1px #ccc;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
    }
  }
  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    appearance: none;
    padding: 0;
    border: solid 2px #929292;
    border-radius: 5px;
    background-position: center;
    transition: all ease 0.3s;
    &:checked {
      background: url(${check}) no-repeat center #055aab;
      border: solid 2px #055aab;
    }
  }

  .row {
    margin: 20px 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 640px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    label {
      font-size: 14px;
      color: #8b8d97;
      display: flex;
      align-items: center;
      cursor: pointer;
      input {
        margin-right: 10px;
      }
      @media (max-width: 640px) {
        margin-bottom: 10px;
      }
    }
    .esqueciminhasenha {
      font-size: 14px;
      color: #8b8d97;
      text-decoration: underline;
      cursor: pointer;
    }
    .redText {
      color: rgba($color: #ff5757, $alpha: 1);
    }
  }
`;
export const Erro = styled.div`
  font-size: 12px;
  color: red;
  margin-top: -19px;
  padding-left: 20px;
  padding-bottom: 5px;
`;

export const ErroMsg = styled.div`
  font-size: 12px;
  color: red;
  text-align: center;
  margin-top: 10px;
`;
export const Button = styled.button`
  max-width: 220px;
  width: 100%;
  display: block;
  margin: 20px auto 0;
  background: #055aab;
  color: #fff;
  height: 50px;
  font-size: 17px;
  border-radius: 8px;
  border: solid 1px #055aab;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgba($color: #055aab, $alpha: 0.2);
  }
`;
