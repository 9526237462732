import styled from 'styled-components';

import { GitCompare } from '@styled-icons/boxicons-regular';

import { Center } from '../../styles/components';

export {
  InnerHeader,
  Loading,
  Center,
  ButtonMini,
} from '../../styles/components';

export const Container = styled.main`
  width: 100%;
  /* max-width: 1920px; */
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.dark};
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

export const IconConsume = styled(GitCompare).attrs({
  size: 24,
})``;

export const Content = styled.section`
  width: 100%;
  padding: 40px 32px;
`;

export const Message = styled.h5`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blueDark};
  padding-left: 16px;
`;

export const Wrapper = styled(Center)`
  display: flex;
  /* max-width: 1130px; */
  align-items: center;
  justify-content: space-between;

  div.buttonsWrapper {
    display: flex;
    column-gap: 16px;
  }
`;
