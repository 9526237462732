import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import api from '../../../../services/api'
import { toast } from 'react-toastify';

// Components
import Header from '../../../../components/Header'
import AsideMenu from '../../../../components/AsideMenu'
import LoadPage from "../../../../components/LoadPage";
import BtnBack from "../../../../components/BtnBack";
import Text from "../../../../components/Text";

//Images
import iconTit from '../../../../assets/icons/icon-campaing.svg'
import iProducts from "../../../../assets/icons/icon-products.svg";

//CSS
import * as Ss from "../../../../styles/style";
import * as S from "../styles";

export default function New({ history }) {
    const { register, handleSubmit, errors } = useForm()
    const [loading, setLoading] = useState(false);

    const msgsuccess = () => toast.success("Grupo similar cadastrada com sucesso!");

    async function onSubmit(data, e){
        await api.post(`/group-similar`, {
            name: data.name,
        })
        .then(result => {
            msgsuccess();
            e.target.reset();
            history.push(`/configuracoes/grupo-similar/`);
        })
        .catch(error => {
            console.log(error)
        })
    }


  return (
    <>
        <Header />
        <Ss.Center>
            <Ss.TopHeader>
                <span>
                    <img src={iconTit} className="icontit" alt="" />
                    <h1>
                    <Text id="config.title" dm="Configurações" />
                    </h1>
                </span>
                <BtnBack onClick={history.goBack} />
            </Ss.TopHeader>

            <S.Container>
                <AsideMenu />
                <S.Content>
                    <header className="content">
                        <h2>
                            <img src={iProducts} alt=""/> 
                            <Text id="grupo_similar" dm="Grupo Similar" />
                        </h2>
                    </header>

                    {loading ? (
              <LoadPage />
            ) : (
                    <Ss.Form onSubmit={handleSubmit(onSubmit)}>
                        <Ss.DescriptionTitle>
                            <Text
                                id="info.grupo_similar"
                                dm="Informações do grupo similar"
                            />
                        </Ss.DescriptionTitle>
                        <Ss.BoxForm>
                            <Ss.Row className="r1">
                                <Ss.InputBox>
                                    <label htmlFor="">
                                        <Text id="grupo_similar" dm="Grupo Similar" />
                                    </label>
                                    <input name="name" ref={register({ required: true })} />
                                    {errors.name && <S.Err>Digite o nome</S.Err>}
                                </Ss.InputBox>
                            </Ss.Row>
                        </Ss.BoxForm>
                        
                        <Ss.Btns>
                            <Ss.Button type="reset" className="cancel" onClick={history.goBack}>
                                <Text id="cancelar" dm="Cancelar" />
                            </Ss.Button>
                            <Ss.Button>
                                <Text id="salvar" dm="Salvar" />
                            </Ss.Button>
                        </Ss.Btns>

                    </Ss.Form>
            )}
            </S.Content>
          </S.Container>
        </Ss.Center>
    </>
  );
}
