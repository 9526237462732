import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../../../services/api";

// Components
import Header from "../../../components/Header";
import AsideMenu from "../../../components/AsideMenu";
import BtnAdd from "../../../components/BtnAdd";
import LoadPage from "../../../components/LoadPage";
import BtnBack from "../../../components/BtnBack";
import Text from "../../../components/Text";
import { Paginator } from '../../../components/shared';

//Images
import iconTit from "../../../assets/icons/icon-campaing.svg";
import iBom from "../../../assets/icons/icon-justification.svg";
import iSearch from "../../../assets/icons/icon-search.svg";

//CSS
import { Center, TopHeader } from "../../../styles/style";
import * as S from "./styles";

export default function Justify() {
  const [search, setSearch] = useState("");
  const [justify, setJustify] = useState([]);
  const [loading, setLoading] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    total: 0,
		perPage: 10,
		page: 1,
		lastPage: 1
  });

  let history = useHistory();

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, );

  const feachJustify = useCallback(async () => {
    setLoading(true);
    await api
      .get(`justification-n?paginate=${currentPage}`)
      .then((result) => {
        setJustify(result.data.data);
        setPagination(result.data.pagination);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setLoading(false);
      });
  }, [currentPage])

  useEffect(() => {
    feachJustify();
  }, [currentPage]);

  const results = !search
    ? justify
    : justify.filter((e) =>
        e.name.toLowerCase().includes(search.toLocaleLowerCase())
      );

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={() => history.push("/configuracoes")} />
        </TopHeader>
        <S.Container>
          <AsideMenu />

          <S.Content>
            <header>
              <h2>
                <img src={iBom} alt="" />
                <Text id="justificativa" dm="Justificativa" />
              </h2>

              <S.FilterConfig>
                <img src={iSearch} alt="" />
                <input
                  placeholder="Buscar justificativa"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </S.FilterConfig>

              <Link to="/configuracoes/justificativa/novo">
                <BtnAdd />
              </Link>
            </header>

            {loading ? (
              <LoadPage />
            ) : (
              <S.BomContainer>
                <S.HeaderList>
                  <p>
                    <Text id="codigo" dm="Código" />
                  </p>
                  <p>
                    <Text id="justificativa" dm="Justificativa" />
                  </p>
                  <p>
                    <Text id="descricao" dm="Descrição" />
                  </p>
                </S.HeaderList>

                {results.map((item) => (
                  <S.ItemList
                    key={item.id}
                    onClick={() => {
                      history.push(`/configuracoes/justificativa/${item.id}`);
                    }}
                  >
                    <p>{item.id}</p>
                    <p>{item.name}</p>
                    <p>{item.description}</p>
                  </S.ItemList>
                ))}
                <Paginator
                  pagination={pagination}
                  onPageChange={handlePageChange}
                  neighbors={4}
                />
              </S.BomContainer>
            )}
          </S.Content>
        </S.Container>
      </Center>
    </>
  );
}
