import React, {useState, useEffect} from 'react';
import api from '../../../services/api';
import moment from "moment";
import { useParams} from "react-router";
//images
import iconActivity from "../../../assets/icons/icon-atividades.svg";

// CSS
import { Container, Activity, Pin, User, Calendar } from './styles';


export default function Activities(props) {
    const [activity, setActivity] = useState([])
    let {idnf} = useParams()
  
    useEffect(() => {
        async function loadActivity() {
            const res = await api.get(`/activity?invoice_id=${idnf}`)
            setActivity(res.data.data)
        }
        loadActivity()
    }, [idnf, props])

  return (
    <Container>
         <h2>
            <img src={iconActivity} alt="Atividades" />
            Atividades
        </h2>
        {activity &&
            activity.map(log => (
            <Activity key={log.usuario_log_id}>
                <Pin />
                <User>
                <img src={log.user.avatar ? log.user.avatar : ''} alt="" />
                {log.user.name ? log.user.name : '-'}
                </User>
                <p>{log.usuario_log_acao}</p>
                <Calendar>{log.usuario_log_data ?  moment(log.usuario_log_data ).format('DD/MM/YYYY')  : ''}</Calendar>
            </Activity>
            ))} 
    </Container>
  );
}
