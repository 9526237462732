import React from 'react';

import { Container } from './styles';

export default function BtnFilter(props) {
  return (
    <Container className={props.className} onClick={props.onClick}>
        <div className="icon-filter">
          <span />
          <span />
          <span />
        </div>
        {props.title}
    </Container>
  );
}
