import styled from 'styled-components';
import { Loading } from '../../../styles/components';

export const Container = styled.div`
  width: 100%;
  padding: 32px 0;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

export const Button = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px transparent solid;
  margin-right: 4px;
  font-size: 14px;
  border-radius: 6px;
  transition: 300ms ease;
  color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.light};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.disable};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }

  &:last-child {
    margin-right: 0;
  }
  &.aux-page {
    width: auto;
    padding: 0 8px;
  }
`;

export const PageLoading = styled(Loading).attrs({ size: 20 })`
  margin-left: 8px;
`;

export const Description = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  color: ${({ theme }) => theme.colors.lightDarkBlue};
`;
