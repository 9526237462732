import styled from "styled-components";

export const ContainerItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Item = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr repeat(2, minmax(120px, auto));
  column-gap: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  height: 54px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
`;

export const Value = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.dark};
`;

export const PercentBox = styled.div`
  width: 80px;
  height: 54px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme, product }) =>
    product ? theme.colors.green : theme.colors.pink};
  font-family: ${({ theme }) => theme.fonts.titleBold};
  font-size: 18px;
`;
export const ContainerRawItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const RawItem = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr repeat(3, 120px) 150px;
  column-gap: 16px;
  background-color: ${({ theme }) => theme.colors.gray2};
  padding: 16px;
  height: 54px;
  border-radius: 6px;
`;
