import React, { useState } from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import * as S from "./styles";

export default function IconEdit({ edit, view, consumo }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <S.Container onClick={handleClick}>
        <span></span>
        <span></span>
        <span></span>
      </S.Container>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <S.Links to={edit}>
          <MenuItem>Editar</MenuItem>
        </S.Links>
        <S.Links to={view}>
          <MenuItem>Planejar</MenuItem>
        </S.Links>
        <S.Links to={consumo}>
          <MenuItem>Consumo</MenuItem>
        </S.Links>
      </Menu>
    </>
  );
}
