import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 20px;
    align-items: center;
    cursor: pointer;
    span{
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #000;
        margin: 2px 0;
    }
    &:hover{
        > div{
            visibility: visible;
            opacity: 1;
        }
    }
`;
export const Links = styled(Link)`
    color: #000;
`