import { put } from "redux-saga/effects";
import languageActions from "../ducks/language";

export function* fetchLanguage(action) {
  try {
    if (localStorage.getItem("@toller:language")) {
      const storagedLang = localStorage.getItem("@toller:language");
      const data = yield action.data ? action.data : storagedLang;
      yield put(languageActions.success(data));
      localStorage.setItem("@toller:language", data);
      return;
    }
    const data = yield action.data
      ? action.data
      : navigator.language.split(/[-_]/)[0];
    localStorage.setItem("@toller:language", data);

    yield put(languageActions.success(data));
  } catch (err) {
    yield put(languageActions.failure("Erro"));
  }
}
