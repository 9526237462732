import React, { useCallback } from "react";

import * as S from "./styles";

const ItemSapJustified = ({ data, onDelete }) => {
  const filterJustification = useCallback(
    (justifications) => {
      const sapJustification = justifications.filter(
        (justification) => justification.type === "SAP"
      );
      if (sapJustification.length)
        return (
          <S.Justificantion>
            <S.JustificantionText>
              {sapJustification[0].justification}
            </S.JustificantionText>
            <S.TrashButton onClick={() => onDelete(sapJustification[0].id)}>
              <S.IconTrash />
            </S.TrashButton>
          </S.Justificantion>
        );
    },
    [onDelete]
  );
  return (
    <S.Container>
      <S.Item>
        <S.Value>{data?.sap_name || data?.invoice_name}</S.Value>
        <S.Value>UN: {data?.unit}</S.Value>
        <S.Value>NF: {data?.invoice_quantity}</S.Value>
        <S.Value>SAP: {data?.sap_quantity || "---"}</S.Value>
        <S.Value className={`${data?.diff !== "0.000" && "diff"}`}>
          DIF: {data?.diff !== null ? data?.diff : "---"}
        </S.Value>
      </S.Item>
      {data?.justification && filterJustification(data.justification)}
    </S.Container>
  );
};

export default ItemSapJustified;
