import React from 'react';

import * as S from './styles';

const ItemBomApproved = ({ data }) => {
  return (
    <S.Container>
      <S.Item>
        <S.Value>
          {data?.product_bom_description || data?.product?.name}
        </S.Value>
        <S.Value>UN: {data?.unit}</S.Value>
        <S.Value>NF: {data?.invoice_quantity}</S.Value>
        <S.Value>BOM: {data?.bom_quantity || '---'}</S.Value>
        <S.Value className={`${data?.diff_invoice_bom !== '0.000' && 'diff'}`}>
          DIF:{' '}
          {data?.diff_invoice_bom !== null ? data?.diff_invoice_bom : '---'}
        </S.Value>
      </S.Item>
      {data?.justification_bom && (
        <S.Justificantion>
          <S.JustificantionText>{data.justification_bom}</S.JustificantionText>
        </S.Justificantion>
      )}
    </S.Container>
  );
};

export default ItemBomApproved;
