import React from "react";
import Routes from "./routes";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Theme from "./styles/Theme";
import "./config/ReactotronConfig";
import { store, persistor } from "./store";

// import "./styles/App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Lang from "./lang";

import GlobalStyle from "./styles/global";

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Theme>
            <GlobalStyle />
            <Lang>
              <ToastContainer />
              <Routes />
            </Lang>
          </Theme>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
