import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import AuthActions from "../ducks/auth";

export function* login(action) {
  try {
    const { data: post, history, setItem } = action;
    const { data } = yield call(api.post, "login", post);

    const tokenArray = data.token.split(".");
    const payload = tokenArray[1];
    const decoded = JSON.parse(Buffer.from(payload, "base64").toString());

    if (setItem) {
      setItem(data.token);
    }

    if (history) {
      history.push("/");
    }

    yield put(AuthActions.success({ ...decoded.data, token: data.token }));
  } catch (err) {
    yield put(AuthActions.failure(err.response.data.error.message));
  }
}
