import React, { useCallback, useEffect } from 'react';
import api from '../../../services/api';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import Text from '../../Text';
import moment from 'moment';
import { format } from "date-fns";
import * as S from './styles';

const DownloadXLS = () => {
  const [contentConsume, setContentConsume] = useState([]);
  const [consume, setConsume] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadConsume = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/branch-invoice?all=yes`)
      .then((result) => {
        setConsume(result.data.branch_invoices);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  }, [])

  useEffect(() => {
    loadConsume();
  }, [])

  const prepareDownload = useCallback(async () => {
    const headers = [
      'Campanha',
      'NF',
      'Emissao',
      'Produto',
      'Qtde',
      'Toller',
      'Dt. Importacao NF',
      'Dt. Lancamento SAP',
      'Tempo para Consumir',
      'Justificativa',
      'Comentario',
      'Status de Consumo'
    ];

    const consumeBody = [headers]

    if (consume && loading == false) {
      await consume.forEach((value) => {
        let qtdeDays = '';
        const launch_date = value?.bomSap[0] ? moment(value?.bomSap[0].launch_date).startOf('day') : '';
        const created_at = value?.invoiceItems[0] ? moment(value?.invoiceItems[0].created_at).startOf('day') : '';
        const created = value?.invoiceItems[0] ? format(new Date(value?.invoiceItems[0].created_at), "dd/MM/yyyy") : '';
        const launch = value?.bomSap[0] ? format(new Date(value?.bomSap[0].launch_date), "dd/MM/yyyy") : '';
        if (launch_date && created_at) {
          qtdeDays = launch_date.diff(created_at, 'days')
        }
        let data = [
          value.order?.alfa_id,
          value.number,
          format(new Date(value.emission_date), "dd/MM/yyyy"),
          value.product?.name,
          value.invoiceItems[0]?.quantity,
          value.branch?.company_name,
          created,
          launch,
          Math.abs(qtdeDays),
          value.justification?.justifications[0]?.name,
          value.justification?.description,
          (!value?.bomSap.length && !value?.finished_at) ? 'Pendente' : 'Consumido',
        ]
        consumeBody.push(data)
      })
      setContentConsume(consumeBody);
    }
  }, [consume, loading]);
  useEffect(() => {
    prepareDownload();
  }, [consume, prepareDownload]);

  return (
    <>
      <CSVLink
        data={contentConsume}
        separator={';'}
        filename={`RELATORIO_CONSUMO_${format(new Date(), "dd-MM-yyyy")}.csv`}
      >
        <S.ButtonDownload disabled={loading == true}>
          {loading ? <S.OnLoading /> :
            <Text id="exportar" dm="Exportar Excel" />}
        </S.ButtonDownload>
      </CSVLink>
    </>
  )
}

export default DownloadXLS;