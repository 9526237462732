import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import api from "../../../services/api";
import { useParams } from "react-router";
import ReactTooltip from "react-tooltip";

// Components
import Header from "../../../components/Header";
import LoadPage from "../../../components/LoadPage";
import IconStatus from "../../../components/IconStatus";
import BtnBack from "../../../components/BtnBack";
import ModalDetails from "./modalLista";
import ModalPrevisao from "./modalPrevisao";
import ModalInvoice from "./modalInvoice";
import ModalComex from "./modalComex";
import ModalPredictions from "./modalPredictions";
import Filter from "./filter";
import Text from "../../../components/Text";

//Images
import iconTit from "../../../assets/icons/icon-campaing.svg";
import logoIhara from "../../../assets/logos/logoihara.png";
import logoourofino from "../../../assets/logos/ourofino.svg";
import logodupont from "../../../assets/logos/dupont.png";
import logofmc from "../../../assets/logos/fmc.png";
import logoembag from "../../../assets/logos/Ebamag.jpg";
import logospicam from "../../../assets/logos/spicam.jpg";
import logoalphapack from "../../../assets/logos/logo_alphapack.png";
import logofdr from "../../../assets/logos/dow.png";

//CSS
import { Center, TopHeader } from "../../../styles/style";
import {
  HeaderDetails,
  HeaderItem,
  ProductList,
  ItemList,
  MaterialList,
  Tit,
  MaterialBox,
  ItemMaterial,
  BoxData,
  SpanData,
  IconSearch,
  BoxStatus,
} from "./styles";

export default function Details({ history }) {
  const [campaing, setCampaing] = useState([]);
  const [campaingCompleted, setCampaingCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [materialLoading, setMaterialLoading] = useState(false);
  const [datamodal, setDatamodal] = useState();
  const [datamp, setDatamp] = useState();
  const [dataInvoice, setDataInvoice] = useState();
  const [dataComex, setDataComex] = useState();
  const [productPredictionId, setProductPredictionId] = useState();
  const [open, setOpen] = useState(false);
  const [openmp, setOpenmp] = useState(false);
  const [openInovoice, setOpenInvoice] = useState(false);
  const [openComexModal, setOpenComexModal] = useState(false);
  const [openPredictions, setOpenPredictions] = useState(false);

  // Filters
  const [productsFiltered, setProductFiltered] = useState();
  const [name, setName] = useState(null);
  const [begin, setBegin] = useState(null);
  const [end, setEnd] = useState(null);
  const [status, setStatus] = useState(null);

  let { id } = useParams();

  async function loadCampaings() {
    await api
      .get(`/order/${id}`)
      .then((result) => {
        setCampaing(result.data.data);
        setCampaingCompleted(result.data.data.status === "atendida");
        setProductFiltered(result.data.data.products);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadCampaings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (campaing.products) {
      let { products } = campaing;

      if (name) {
        products = products.filter(
          (e) =>
            e.name.toLowerCase().includes(name.toLocaleLowerCase()) ||
            e.code.toLowerCase().includes(name.toLocaleLowerCase())
        );
      }

      if (begin && end) {
        let newProducts = new Array();

        products.forEach((product) => {
          const predictions = product.predictions.filter(
            (item) =>
              moment.utc(item.date).isSameOrAfter(begin) &&
              moment.utc(item.date).isSameOrBefore(end)
          );

          if (predictions && predictions[0]) {
            newProducts.push(product);
          }
        });

        products = newProducts;
      }

      if (status) {
        products = products.filter((e) => e.status === status);
      }

      setProductFiltered(products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaing, name, begin, end, status]);

  function handleCleanFilters() {
    setName("");
    setBegin(null);
    setEnd(null);
    setStatus("");
  }

  // Modal
  async function modal(data) {
    let orderQuantity = data.quantity;
    let bomQuantity = data.bom.core_bom_product.quantity;
    let proportion = orderQuantity / bomQuantity;
    let bomProduct = data.bom.core_bom_product.product.name;

    setDatamodal({
      lista: data.bom.bom_products_materials,
      orderQuantity,
      bomQuantity,
      proportion,
      bomProduct,
      final_date: moment(campaing.deadline_date).add(1, "days"),
    });
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setOpenmp(false);
    setOpenInvoice(false);
    setOpenComexModal(false);
    setOpenPredictions(false);
  };

  async function handleDate(date, product_id, order_id, previsions) {
    const prevision = previsions.filter(
      (prevision) => prevision.product_id === product_id
    );

    if (moment(new Date()).isBefore(date)) {
      setMaterialLoading(true);
      if (!prevision[0]) {
        await api
          .post(`/order-product-prevision/`, {
            product_id,
            order_id,
            date,
          })
          .then((result) => {
            // console.log(result.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await api
          .put(`/order-product-prevision/${prevision[0].id}`, {
            product_id,
            order_id,
            date,
          })
          .then((result) => {
            // console.log(result.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      await loadCampaings();
      setMaterialLoading(false);
    }
  }

  function getDate(previsions, product_id) {
    const data = previsions.filter(
      (prevision) => prevision.product_id === product_id
    );

    // console.log(data[0]);
    if (data[0]) {
      return data[0].date.substring(0, 10);
    }
  }

  function openModal(data) {
    setOpenmp(true);
    setDatamp(data);
  }

  function handleInvoices(nf_itens) {
    setDataInvoice(nf_itens);
    setOpenInvoice(true);
  }

  function handleComexModal(productsComex) {
    if (productsComex && productsComex[0]) {
      setDataComex(productsComex);
      setOpenComexModal(true);
    } else {
      toast.error("Sem informação do produto no ITO - COMEX");
    }
  }

  function handlePredictions(productId) {
    setProductPredictionId(productId);
    setOpenPredictions(true);
  }

  const ListaMateriais = () => {
    return productsFiltered.map((item) => (
      <ItemMaterial key={item.id}>
        <p>{item.code}</p>
        <p>{item.name}</p>
        <p>{item.quantity_pallet}</p>
        <p>{item.unit && item.unit.name}</p>
        <BoxData>
          {item.predictions.map((internalItem, i) => {
            if (
              (!begin && !end) ||
              (begin &&
                moment.utc(internalItem.date).isSameOrAfter(begin) &&
                end &&
                moment.utc(internalItem.date).isSameOrBefore(end))
            ) {
              return (
                <SpanData
                  hasSimilarProduct={internalItem.similar_product_id}
                  status={internalItem.status}
                  key={i}
                  onClick={(e) => {
                    !campaingCompleted &&
                      openModal({
                        name: internalItem?.similar_product?.name,
                        data: internalItem.date,
                        qtd: internalItem.quantity,
                        id: internalItem.id,
                        bom_product_id: internalItem.bom_product_id,
                        order_id: internalItem.order_id,
                        justification: internalItem.justification_id,
                        final_date: moment(campaing.deadline_date).add(
                          1,
                          "days"
                        ),
                      });
                  }}
                >
                  {internalItem.date ? new Date(internalItem.date).toLocaleDateString() : '---'}
                  <>
                    <strong>{internalItem.quantity}</strong>
                    {internalItem.similar_product ? <IconStatus theme="fabricacao"/> : ''} 
                  </>
                </SpanData>
              );
            }
            return <> </>;
          })}
        </BoxData>
        <p className="ptotal">{
        item.total_product.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
          }
        </p>
        <p className="t-right">
          {item.balance
            ? item.balance.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : "0,00"}
        </p>
        <p className="t-right">
          {item.balance
            ? item.total_product > item.balance &&
              (item.total_product - item.balance).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : "0,00"}
        </p>
        {/* <div className="dataprevia">24/04/2020</div> */}
        {(!item.balance || item.total_product > item.balance) &&
        item.status !== "atendida" ? (
          <div>
            <input
              min={moment(new Date()).format("YYYY-MM-DD")}
              max={moment(campaing.deadline_date)
                .add(1, "days")
                .format("YYYY-MM-DD")}
              defaultValue={getDate(campaing.order_product_previsions, item.id)}
              type="date"
              onChange={(e) =>
                handleDate(
                  e.target.value,
                  item.id,
                  campaing.id,
                  campaing.order_product_previsions
                )
              }
            />
          </div>
        ) : (
          <div />
        )}
        {item.productsComex && item.productsComex[0] ? (
          <IconStatus
            onClick={() => handleComexModal(item.productsComex)}
            theme="boat"
          />
        ) : (
          <div />
        )}

        {item.nf_itens && item.nf_itens[0] ? (
          <IconStatus
            onClick={() => handleInvoices([item.nf_itens,item.name])}
            theme="nfok"
          />
        ) : (
          <div />
        )}
        <IconStatus
          datatip={
            item.status === "atendida"
              ? "Finalizado"
              : item.status === "penEstoque"
              ? "Pendente de Estoque"
              : item.status === "penEmissao"
              ? "Pendente de Emissão de NF"
              : "Parcialmente Atendido"
          }
          theme={item.status}
          onClick={() => handlePredictions(item.id)}
        />
      </ItemMaterial>
    ));
  };

  const Logos = () => {
    switch (campaing.branch.cnpj) {
      case "61142550000130":
        return logoIhara;
      case "09100671000107":
        return logoourofino;
      case "61064929000330":
        return logodupont;
      case "04136367003707":
        return logofmc;
      case "03132620001225":
        return logoembag;
      case "23361306000179	":
        return logospicam;
      case "04359230000100":
        return logoalphapack;
      case "47180625002190":
        return logofdr;

      default:
        break;
    }
  };

  const handleOrderStatus = (status) => {
    switch (status) {
      case "parAtendido":
        return "Pendente";
      case "atendida":
        return "Finalizada";
      case "atrasada":
        return "Atrasada";
      default:
        return "";
    }
  };

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="campanha" dm="Campanha" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>

        {loading ? (
          <LoadPage />
        ) : (
          <>
            {campaing && (
              <>
                {datamp && (
                  <ModalPrevisao
                    id="1"
                    data={datamp}
                    openmp={openmp}
                    handleClose={handleClose}
                    loadCampaings={loadCampaings}
                    deadlineDate={campaing.deadline_date}
                  />
                )}
                {datamodal && (
                  <ModalDetails
                    id={id}
                    data={datamodal}
                    open={open}
                    handleClose={handleClose}
                    loadCampaings={loadCampaings}
                  />
                )}
                {dataInvoice && (
                  <ModalInvoice
                    id={id}
                    data={dataInvoice}
                    open={openInovoice}
                    handleClose={handleClose}
                    loadCampaings={loadCampaings}
                  />
                )}
                {dataComex && (
                  <ModalComex
                    id={id}
                    products={dataComex}
                    open={openComexModal}
                    handleClose={handleClose}
                    loadCampaings={loadCampaings}
                  />
                )}
                {openPredictions && (
                  <ModalPredictions
                    id={productPredictionId}
                    open={openPredictions}
                    handleClose={handleClose}
                    loadCampaings={loadCampaings}
                  />
                )}

                <HeaderDetails>
                  <HeaderItem>
                    <p>Toller</p>
                    <p>
                      <strong>
                        {Logos() ? (
                          <>
                            <img src={Logos()} alt="" />
                          </>
                        ) : (
                          campaing.branch.company_name
                        )}
                      </strong>
                    </p>
                  </HeaderItem>
                  <HeaderItem>
                    <p>
                      <Text id="codigo" dm="Código" />
                    </p>
                    <p>
                      <strong>
                        {campaing.alfa_id ? campaing.alfa_id : "-"}
                      </strong>
                    </p>
                  </HeaderItem>
                  <HeaderItem className="big">
                    <p>
                      <Text id="descricao" dm="Descrição" />
                    </p>
                    <p>
                      <strong>{campaing.description}</strong>
                    </p>
                  </HeaderItem>

                  <HeaderItem>
                    <p>
                      <Text id="datasolicitacao" dm="Data Solicitação" />
                    </p>
                    <p>
                      <strong>
                        {new Date(campaing.request_date).toLocaleDateString()}
                      </strong>
                    </p>
                  </HeaderItem>
                  <HeaderItem>
                    <p>
                      <Text id="prazoentrega" dm="Prazo Entrega" />
                    </p>
                    <p>
                      <strong>
                        {new Date(campaing.deadline_date).toLocaleDateString()}
                      </strong>
                    </p>
                  </HeaderItem>
                  <HeaderItem>
                    <p>
                      <Text id="status" dm="Status" />
                    </p>
                    <BoxStatus className={campaing.status}>
                      {handleOrderStatus(campaing.status)}
                    </BoxStatus>
                  </HeaderItem>
                </HeaderDetails>

                <ProductList>
                  <header>
                    <p>
                      <Text id="codigo" dm="Código" />
                    </p>
                    <p>
                      <Text id="produto" dm="Produto" />
                    </p>
                    <p>
                      <Text id="unmedida" dm="UN de Medida" />
                    </p>
                    <p>
                      <Text id="tipo" dm="Tipo" />
                    </p>
                    <p>
                      <Text id="quantidade" dm="Quantidade" />
                    </p>
                    <p />
                  </header>
                  {campaing.order_boms.map((item) => (
                    <ItemList key={item.id}>
                      <p>{item.bom_id}</p>
                      <p>{item.bom.core_bom_product.product.name}</p>
                      <p>{
                        item.bom.core_bom_product.product.unit &&
                        item.bom.core_bom_product.product.unit.name
                      }</p>
                      <p>{item.bom.core_bom_product.product.type.name}</p>
                      <p>{item.quantity}</p>
                      {!campaingCompleted ? (
                        <IconSearch
                          onClick={() => modal(item)}
                          data-tip="Adicionar Item"
                        />
                      ) : (
                        <div />
                      )}
                      <ReactTooltip effect="solid" />
                    </ItemList>
                  ))}
                </ProductList>

                <Filter
                  name={name}
                  begin={begin}
                  end={end}
                  status={status}
                  onNameChange={(e) => setName(e)}
                  onBeginChange={(e) => setBegin(e)}
                  onEndChange={(e) => setEnd(e)}
                  onStatusChange={(e) => setStatus(e)}
                  onClean={handleCleanFilters}
                />

                {materialLoading ? (
                  <LoadPage />
                ) : (
                  <MaterialList>
                    <Tit>
                      <Text id="listamaterial" dm="Lista de Material" />
                    </Tit>

                    <MaterialBox>
                      <header>
                        <p>
                          <Text id="codmat" dm="Código Mat." />
                        </p>
                        <p>
                          <Text id="descmat" dm="Descrição do Material" />
                        </p>
                        <p>
                          <Text id="pallet" dm="Pallet" />
                        </p>
                        <p>
                          <Text id="un" dm="UN" />
                        </p>
                        <p>
                          <Text id="dataqtd" dm="Data / Quantidade" />
                        </p>
                        <p>
                          <Text id="total" dm="Total" />
                        </p>
                        <p>
                          <Text id="estoque" dm="Estoque" />
                        </p>
                        <p>
                          <Text id="pendente" dm="Pendente" />
                        </p>
                        <p>
                          <Text id="previsao" dm="Previsão" />
                        </p>
                        <p>
                          <Text id="ito" dm="ITO" />
                        </p>
                        <p>
                          <Text id="nf" dm="NF" />
                        </p>
                        <p>
                          <Text id="status" dm="Status" />
                        </p>
                      </header>
                      <ListaMateriais />
                    </MaterialBox>
                  </MaterialList>
                )}
              </>
            )}
          </>
        )}
      </Center>
    </>
  );
}
