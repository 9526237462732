import styled, { css } from "styled-components";
import { GitCompare } from "@styled-icons/boxicons-regular";

import { Center, ModalBox, Button } from "../../../styles/components";

export {
  InnerHeader,
  Loading,
  Center,
  Button,
  ButtonMini,
  ModalBox,
  ModalHead,
  ModalHeadTitle,
  ModalTitle,
  WrapModalButtons,
} from "../../../styles/components";

export const Container = styled.main`
  width: 100%;
  /* max-width: 1920px; */
  margin: 0 auto;
`;

export const IconConsume = styled(GitCompare).attrs({
  size: 24,
})``;

export const Title = styled.h1`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.dark};
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

export const Indicators = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray2};
  padding: 32px;
`;

export const Box = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  padding: 24px 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px ${({ theme }) => theme.colors.darkRgba8} solid;
  padding: 0 32px;

  &:last-child {
    border-right: none;
  }
`;

export const Value = styled.h5`
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 8px;
  text-align: center;
  color: ${({ color, theme }) => (color ? color : theme.colors.primary)};
`;

export const Label = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleBold};
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkRgba50};
`;

export const Content = styled.section`
  width: 100%;
  padding: 40px 32px;
`;

export const Wrapper = styled(Center)`
  display: flex;
  flex-direction: column;
  /* max-width: 1130px; */
`;

export const WrapperHeader = styled(Center)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  /* max-width: 1130px; */
`;

export const ModalBoxNew = styled(ModalBox)`
  max-width: 800px;
  min-height: 200px;
  max-height: 600px;
`;

export const ButtonModalNew = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  width: 50%;
`;

export const ModalFlexButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContentTitle = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.blueDark};
`;

export const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
  /* max-width: 1130px; */
  border-top: 2px ${({ theme }) => theme.colors.line} dotted;
  border-bottom: 2px ${({ theme }) => theme.colors.line} dotted;
  padding: 24px 0;
  margin: 0 auto;

  button {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const NoRecords = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.darkRgba8};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  color: ${({ theme }) => theme.colors.darkRgba50};
`;

export const ContainerModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: ${({ open }) => (open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: auto;
`;

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  row-gap: 10px;
  margin: 20px 0px;
`;

export const ModalContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin: 20px 0px;
  overflow-y: auto;
`;

export const ModalGridItemHeader = styled.p`
  justify-self: center;
  font-size: 16px;
  font-weight: bold;
`;

export const ModalGridItem = styled.p`
  justify-self: center;
  font-size: 16px;
`;

export const ButtonLink = styled.a`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  transition: 300ms ease;
  background-color: #1976d2;
  color: white;

  :hover {
    transform: scale(0.95);
  }
`;

export const ContainerLink = styled.a`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid black;
  transition: 300ms ease;

  :hover {
    transform: scale(0.95);
  }
`;

export const DetailsHeaderConstume = styled.div`
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ButtonCiente = styled.button`
  font-size: 18px;
  background: #bd2031;
  color: white;
  width: 100px;
  padding: 15px 10px;
  border-radius: 5px;
`;

export const ContainerModalCiente = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: ${({ open }) => (open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: auto;
`;

export const ContainerComentary = styled.div`
  background: white;
  width: 500px;
  border-radius: 15px;
  height: 300px;
  padding: 35px 60px;
  display: flex;
`;

export const ButtonComentaryWrapper = styled.div`
  display: flex;
  width: 80%;
  margin-top: 10%;
  marign-left: 20%;
`;

export const CienteBy = styled.span`
  display: flex;
  padding: 0 32px;
  align-items: center;
  height: 54px;
  border-radius: 6px;
  font-size: 16px;
  background: #1ABC9C;
  color:  whitesmoke;
`