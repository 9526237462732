import React, { useState } from "react";
import api from "../../../services/api";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import { toast } from "react-toastify";

// Helpers 
import replaceComma from '../../../helpers/replaceComma';

import Modal from "../../../components/Modal";

// Css
import * as Ss from "../../../styles/style";
import * as S from "./styles";

registerLocale("pt-BR", pt);

export default function ModalDetails({
  id,
  data,
  open,
  handleClose,
  loadCampaings,
}) {
  const { register, handleSubmit, errors } = useForm();
  const [boxdata, setBoxdata] = useState(false);
  const [dataadd, setDataadd] = useState();
  const [infoModal, setinfoModal] = useState();
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const msgsuccess = (text) => toast.success(text);
  const msgError = (text) => toast.error(text);

  const openboxAdd = (data) => {
    setBoxdata(!boxdata);
    setDataadd("");
    setinfoModal(data);
  };

  const close = () => {
    handleClose();
    setBoxdata(false);
  };

  async function onSubmit(data) {
    console.log('data: ',data)
    const quantityFormatted = replaceComma(data.qtd);

    if(quantityFormatted > 0) {
      setLoadingBtn(true);
      setTxtBtn(false);

      await api
        .post(`/bom-product-order-prediction/`, {
          date: dataadd,
          quantity: quantityFormatted,
          bom_product_id: infoModal.id,
          order_id: id,
          similar_product_id: data.similar_product_id,
        })
        .then((result) => {
          setLoadingBtn(false);
          setTxtBtn(true);
          msgsuccess("Data adicionada com sucesso!");
          handleClose();
          setBoxdata(false);
          loadCampaings();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      msgError("Quantidade dever ser maior que 0");
    }
  }

  // const product_name = infoModal.

  console.log('infoModal', infoModal)

  return (
    <div>
      <Modal open={open} close={close}>
        <S.ContainerModal>
          <S.BtnFechar onClick={close} />
          {boxdata && (
            <S.BoxaddData>
              <div className="content">
                <S.BtnClose onClick={openboxAdd} />
                <div className="info">
                  <p>
                    <small>Produto:</small> {infoModal.product.name}
                  </p>
                  <p>
                    <small>Unidade:</small> {infoModal.product.unit && infoModal.product.unit.name}
                  </p>
                  <p>
                    <small>Qtd. Total:</small> {infoModal.quantity}
                  </p>
                </div>
                <Ss.Form onSubmit={handleSubmit(onSubmit)}>
                  {console.log('product modal', infoModal)}
                  <Ss.SelectBox>
                    <label htmlFor="">Selecione produtos semelhantes</label>
                    <Ss.SelectForm ref={register()} defaultValue={"ok"} name="similar_product_id">
                    <Ss.SelectOption value={null}></Ss.SelectOption>
                    {!infoModal.product.group_similar ? 
                    <Ss.SelectOption disabled="true" >{"Nenhum produto encontrado"}</Ss.SelectOption> :
                    infoModal.product.group_similar.products.map((product) => 
                        <Ss.SelectOption value={product.id}>{product.name}</Ss.SelectOption>
                      )} }
                      
                    </Ss.SelectForm>
                    {errors.select && <Ss.Err>Escolha um produto</Ss.Err>}
                  </Ss.SelectBox>
                  <Ss.InputsBox>
                    <Ss.InputBox>
                      <label htmlFor="">Quantidade</label>
                      <input
                        type="text"
                        name="qtd"
                        ref={register({ required: true })}
                      />
                      {errors.qtd && <Ss.Err>Digite a quantidade</Ss.Err>}
                    </Ss.InputBox>
                    <Ss.InputBox>
                      <label htmlFor="">Data</label>
                      <DatePicker
                        selected={dataadd}
                        onChange={(date) => setDataadd(date)}
                        locale="pt-BR"
                        dateFormat="P"
                        name="data"
                        autoComplete="off"
                        // minDate={new Date()}
                        // maxDate={new Date(data.final_date)}
                        />
                      <input
                        type="hidden"
                        name="dataa"
                        value={dataadd}
                        ref={register({ required: true })}
                      />
                      {errors.dataa && <Ss.Err>Selecione a data</Ss.Err>}
                    </Ss.InputBox>
                  </Ss.InputsBox>
                  <Ss.Button100>
                    {txtBtn && "Cadastrar"}
                    <Ss.LoadingBtn
                      marginTop={"10px"}
                      sizeUnit={"px"}
                      size={10}
                      height={2}
                      color={"#fff"}
                      loading={loadingBtn}
                    />
                  </Ss.Button100>
                </Ss.Form>
              </div>
            </S.BoxaddData>
          )}
          <S.QuantityModal>
            <h2>{data.bomProduct}</h2>
            <span>
              <p>
                <strong>Quantidade BOM:</strong> {data.bomQuantity}
              </p>
              <p>
                <strong>Quantidade Campanha:</strong> {data.orderQuantity}
              </p>
              <p>
                <strong>Proporção:</strong>{" "}
                {data.proportion.toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </p>
            </span>
          </S.QuantityModal>
          {/* <S.TitleModal>Lista de Material do BOM</S.TitleModal> */}
          <S.ContentListModal>
            <header>
              <p>Produto</p>
              <p>UN de Medida</p>
              <p>Tipo</p>
              <p>Qtd BOM</p>
              <p>Qtd Sugerida</p>
            </header>
            <S.ListModal>
              {data.lista.map((item) => (
                <S.ItemListModal key={item.id}>
                  <p>{item.product.name}</p>
                  <p>{item.product.unit && item.product.unit.name}</p>
                  <p>{item.product.type.name}</p>
                  <p>
                    {item.quantity.toLocaleString("pt-BR", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </p>
                  <p>
                    {(item.quantity * data.proportion).toLocaleString("pt-BR", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </p>
                  <S.IconCalendar
                    onClick={() => openboxAdd(item)}
                    title="Adicionar quantidade e data"
                  />
                </S.ItemListModal>
              ))}
            </S.ListModal>
          </S.ContentListModal>
        </S.ContainerModal>
      </Modal>
    </div>
  );
}
