import { all, takeLatest } from 'redux-saga/effects';

// auth
import { AuthTypes } from '../ducks/auth';
import { login } from './auth';

//Language
import { LanguageTypes } from '../ducks/language';
import { fetchLanguage } from './language';

// Branch Invoices
import { BranchInvoiceListTypes } from '../ducks/branch-invoices/list';
import { fetch as fetchBranchInvoices } from './branch-invoices/list';

import { BranchInvoiceCreateTypes } from '../ducks/branch-invoices/create';
import { create as createBranchInvoice } from './branch-invoices/create';

import { DeleteInvoiceTypes } from '../ducks/branch-invoices/delete-invoice';
import { destroy as deleteInvoice } from './branch-invoices/delete-invoice';

// Compare
import { InvoiceCompareTypes } from '../ducks/compare/invoice-compare';
import { fetch as compareBranchInvoice } from './compare/invoice-compare';

import { CompareApproveTypes } from '../ducks/compare/compare-approve';
import { create as compareApprove } from './compare/compare-approve';

import { CompareApprovedTypes } from '../ducks/compare/compare-approved';
import { fetch as compareApproved } from './compare/compare-approved';

import { CompareCampaignTypes } from '../ducks/compare/compare-campaign';
import { fetch as compareCampaign } from './compare/compare-campaign';

import { CompareCreateJustificationTypes } from '../ducks/compare/create-justification';
import { create as createJustification } from './compare/create-justification';

import { CompareDeleteJustificationTypes } from '../ducks/compare/delete-justification';
import { destroy as deleteJustification } from './compare/delete-justification';

// Bom Sap
import { SapCreateTypes } from '../ducks/sap/create';
import { create as createSap } from './sap/create';

import { SapCreateMultiplesTypes } from '../ducks/sap/create-multiples';
import { createMultiples as createSapMultiples } from './sap/create-multiples';

import { SapDeleteTypes } from '../ducks/sap/delete';
import { destroy as deleteSap } from './sap/delete';

// Orders Combobox
import { OrderComboBoxTypes } from '../ducks/orders/orders-combobox';
import { list as ordersCombobox } from './orders/orders-combobox';

// Bom Sap
import { BranchRawInvoiceCreateTypes } from '../ducks/branch-raw-invoices/create';
import { create as createBranchRawInvoice } from './branch-raw-invoices/create';

import { BranchRawInvoiceDeleteTypes } from '../ducks/branch-raw-invoices/delete';
import { destroy as deleteBranchRawInvoice } from './branch-raw-invoices/delete';

import { BranchRawInvoiceListTypes } from '../ducks/branch-raw-invoices/list';
import { fetch as branchRawInvoices } from './branch-raw-invoices/list';

import { BranchInvoiceUpdateOrderTypes } from '../ducks/branch-invoices/update-order';
import { update as branchInvoiceUpdateOrder } from './branch-invoices/update-order';

import { BranchInvoiceOrderNotFinishedTypes } from '../ducks/branch-invoices/get-order-not-finished';
import { fetch as branchInvoiceOrderNotFinished } from './branch-invoices/get-order-not-finished';

import { NotaFullSearchTypes } from '../ducks/notafull/search';
import { fetch as notaFullSearch } from './notafull/search';

export default function* rootSaga() {
  yield all([
    // auth
    takeLatest(AuthTypes.REQUEST, login),

    // Language
    takeLatest(LanguageTypes.REQUEST, fetchLanguage),

    // Branch Invoices
    takeLatest(BranchInvoiceListTypes.REQUEST, fetchBranchInvoices),
    takeLatest(BranchInvoiceCreateTypes.REQUEST, createBranchInvoice),
    takeLatest(DeleteInvoiceTypes.REQUEST, deleteInvoice),
    takeLatest(BranchInvoiceUpdateOrderTypes.REQUEST, branchInvoiceUpdateOrder),
    takeLatest(BranchInvoiceOrderNotFinishedTypes.REQUEST, branchInvoiceOrderNotFinished),

    // NotaFull
    takeLatest(NotaFullSearchTypes.REQUEST, notaFullSearch),

    // Bom Sap
    takeLatest(SapCreateTypes.REQUEST, createSap),
    takeLatest(SapCreateMultiplesTypes.REQUEST, createSapMultiples),
    takeLatest(SapDeleteTypes.REQUEST, deleteSap),

    // Compare
    takeLatest(InvoiceCompareTypes.REQUEST, compareBranchInvoice),
    takeLatest(CompareApprovedTypes.REQUEST, compareApproved),
    takeLatest(CompareApproveTypes.REQUEST, compareApprove),
    takeLatest(CompareCampaignTypes.REQUEST, compareCampaign),
    takeLatest(CompareCreateJustificationTypes.REQUEST, createJustification),
    takeLatest(CompareDeleteJustificationTypes.REQUEST, deleteJustification),

    // Orders Combobox
    takeLatest(OrderComboBoxTypes.REQUEST, ordersCombobox),

    // Branch Raw Invoice
    takeLatest(BranchRawInvoiceCreateTypes.REQUEST, createBranchRawInvoice),
    takeLatest(BranchRawInvoiceDeleteTypes.REQUEST, deleteBranchRawInvoice),
    takeLatest(BranchRawInvoiceListTypes.REQUEST, branchRawInvoices),
  ]);
}
