import { call, put, select } from "redux-saga/effects";
import { notify } from "../../../services/notification";
import api, { makeOptions } from "../../../services/api";
import ActionsSapCreate from "../../ducks/sap/create";

const getAuthData = (state) => state.auth.data;

export function* create(action) {
  try {
    const { data: postData, onSuccess } = action;

    const url = `bom-sap`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.post, url, postData, makeOptions(authData));

    yield put(ActionsSapCreate.success(data.data));
    notify("success", "Arquivo cadastrado com sucesso!");
    onSuccess();
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(ActionsSapCreate.failure(err.response.data.error.message));
  }
}
