import styled from 'styled-components';

export const Container = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  max-width: 430px;
  border-left: 2px #c8cfd4 solid;
  padding-left: 32px;

  @media screen and (max-width: 1075px) {
    margin-bottom: 48px;
  }

  h2 {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    color: #292d41;
    margin-bottom: 24px;

    img {
      margin-right: 16px;
    }
  }

  button {
    display: flex;
    justify-content: flex-start;
    background: transparent;
    color: #2ea1e8;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 16px;
  }
`;

export const Activity = styled.li`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 16px 32px 32px 16px;
  border-radius: 6px;
  position: relative;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 48px;

  &:after {
    content: "";
    position: absolute;
    left: -9px;
    top: 18px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
  }

  p {
    padding-left: 32px;
    font-size: 12px;
    color: #292d41;
    &.type{
      font-weight: 300;
      color: #a5a5a5
    }
  }
`;
export const Pin = styled.span`
  display: flex;
  position: absolute;
  left: -42px;
  top: 18px;
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 10px;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 3px;
  }
`;
export const User = styled.h3`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: #707385;
  margin-bottom: 12px;
  img {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 8px;
  }
`;

export const Calendar = styled.small`
  position: absolute;
  left: 0;
  bottom: -24px;
  font-size: 10px;
  font-weight: 600;
  color: #8b8d97;
  font-style: italic;
`;