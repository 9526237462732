import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    white: "#fff",
    grayLight: "#f5f5f5",
    gray: "#eaeaea",
    gray2: "#E8E8E8",
    black: "#2B2B2B",
    dark: "#292D41",
    darkRgba15: "rgba(41,45,65, 0.15)",
    darkRgba25: "rgba(41,45,65, 0.25)",
    darkRgba50: "rgba(41,45,65, 0.5)",
    darkRgba8: "rgba(41,45,65, 0.08)",
    blue: "#3498DB",
    blueDark: "#43475C",
    lightDarkBlue: "#616788",
    green: "#3BBCA3",
    green2: "#29DBB8",
    green3: "#1ABC9C",
    line: "#D8DAE8",
    primary: "#1976D2",
    danger: "#ec1751",
    warning: "#FF6043",
    yellow: "#FFFABF",
    beige: "#CBB25E",
    pink: "#FF608B",
    magenta: "#FF006C",
  },
  statusColors: {
    divergente: "#FF7600",
    aprovado: "#26C682",
  },

  fonts: {
    titleRegular: "Gilroy-Regular",
    titleBold: "Gilroy-Bold",
    titleExtraBold: "Gilroy-ExtraBold",
    titleSemiBold: "Gilroy-SemiBold",
    titleLight: "Gilroy-Light",
    default: "Open Sans",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
