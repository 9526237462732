import styled, { css } from 'styled-components';
import { Loader } from '@styled-icons/feather';

const buttonsStyles = {
  primary: css`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  `,
  cancel: css`
    background-color: ${({ theme }) => theme.colors.darkRgba8};
    color: ${({ theme }) => theme.colors.dark};
  `,
  danger: css`
    background-color: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.white};
  `,
  warning: css`
    background-color: ${({ theme }) => theme.colors.warning};
    color: ${({ theme }) => theme.colors.white};
  `,
  transparent: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.dark};
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.colors.darkRgba8};
    color: ${({ theme }) => theme.colors.white};
  `,
  alter: css`
    background-color: ${({ theme }) => theme.colors.green3};
    color: ${({ theme }) => theme.colors.white};
  `,
};

export const PageContainer = styled.main`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Button = styled.button`
  display: flex;
  padding: 0 32px;
  align-items: center;
  height: 54px;
  border-radius: 6px;
  font-size: 16px;
  transition: 300ms ease;
  position: relative;

  :hover {
    transform: scale(0.95);
  }

  &.run {
    &::before {
      content: "";
      position: absolute;
      border-radius: 3px;
      left: 10%;
      width: 80%;
      background: #1464d0;
      height: 5px;
    }
    
    &::after {
      content: "";
      position: absolute;
      border-radius: 3px;
      left: 10%;
      transition: width 1s;
      width: ${({ progress }) => progress * 0.8}%;
      background: white;
      height: 5px;
    }
  }

  ${({ btStyle }) => (btStyle ? buttonsStyles[btStyle] : buttonsStyles.primary)}
`;

export const ButtonMini = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  transition: 300ms ease;

  :hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) => (btStyle ? buttonsStyles[btStyle] : buttonsStyles.primary)}
`;

export const Badge = styled.div`
  font-family: ${({ theme }) => theme.fonts.titleBold};
  display: flex;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  transition: 300ms ease;

  span {
    color: ${({ theme }) => theme.colors.lightDarkBlue};
  }

  ${({ bgColor }) =>
    bgColor === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    `};

  ${({ bgColor }) =>
    bgColor === 'secondary' &&
    css`
      background-color: ${({ theme }) => theme.colors.darkRgba15};
      color: ${({ theme }) => theme.colors.dark};
    `}
`;

export const BoxContainer = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
  padding: 32px;
  margin-bottom: 24px;
`;

export const InnerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
`;

export const Loading = styled(Loader).attrs({
  className: 'icon-spin',
  size: 18,
})`
  margin: 0 8px;
  color: ${({ color }) => color || 'initial'};
`;

export const Center = styled.div`
  /* max-width: 1130px; */
  margin: 0 auto;
  width: 100%;

  @media (max-width: 1099px) {
    /* width: 92%; */
  }
`;

export const ModalBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 640px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 40px;
  animation-name: appear;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-direction: alternate;

  input {
    &[type='time']::-webkit-inner-spin-button {
      display: none;
    }
  }
  input {
    &[type='time']::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  @keyframes appear {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const ModalHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px ${({ theme }) => theme.colors.line} solid;
`;

export const ModalHeadTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.black};
`;

export const ModalTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin: 24px 0;
  color: ${({ theme }) => theme.colors.blueDark};
`;

export const WrapModalButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;
  border-left: none;
  border-right: none;

  button {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
