import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import Modal from "../../../components/Modal";
import api from '../../../services/api';
import LoadPage from "../../../components/LoadPage";

// Css
import * as S from "./styles";

registerLocale("pt-BR", pt);

export default function ModalDetails({
  id,
  open,
  handleClose,
}) {
const [loading, setLoading] = useState(false);
const [product, setProduct] = useState(null);
const [predictions, setPredictions] = useState([]);


  const close = () => {
    handleClose();
  };

  async function loadPredictions(id) {
    setLoading(true);

    const response = await api.get(`/product/prediction/${id}`);

    setProduct(response.data);
    setPredictions(response.data.predictions);
    setLoading(false);
  }

  useEffect(() => {
    loadPredictions(id);
  }, [id])

  return (
    <div>
      <Modal open={open} close={close}>
        { loading ? (
          <LoadPage />
          ) : 
        (
          <>
            {product && (
              <S.ContainerModal>
                <S.BtnFechar onClick={close} />
                <S.TitleModal>Lista de Previsões do Produto: {product.name}</S.TitleModal>
                <S.QuantityModal>
                  <span>
                    <p>
                      <strong>Quantidade Total Estoque: </strong> 
                      {product.balance && product.balance.toLocaleString("pt-BR", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })
                      }
                    </p>
                    <p>
                      <strong>Quantidade Total NF Pendente: </strong> 
                      {product.nf_pending && product.nf_pending.toLocaleString("pt-BR", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })
                      }
                    </p>
                    <p>
                      <strong>Quantidade Total Pendente: </strong> 
                      {product.pending && product.pending.toLocaleString("pt-BR", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })
                      }
                    </p>
                  </span>
                </S.QuantityModal>
                <S.ContentListPredictionModal>
                  <header>
                    <p>Campanha</p>
                    <p>Decrição</p>
                    <p>NF Emitido</p>
                    <p>Nf Pendente</p>
                    <p>Requisitado</p>
                  </header>
                  <S.ListModal>
                    {predictions && predictions[0] && predictions.map((prediction) => (
                      <S.ItemListPredictionModal key={prediction.order.id}>
                        <p>{prediction.order.alfa_id}</p>
                        <p>{prediction.order.description}</p>
                        <p>{prediction.total_nf}</p>
                        <p>{prediction.nf_pending}</p>
                        <p>
                          {prediction.total_product}
                        </p>
                      </S.ItemListPredictionModal>
                    ))}
                  </S.ListModal>
                </S.ContentListPredictionModal>
              </S.ContainerModal>
            )}
          </>
        )}
      </Modal>
    </div>
  );
}
