import styled from "styled-components";

import fabricacao from "../../assets/icons/icon-box.svg";
import atendida from "../../assets/icons/icon-ok.svg";
import nao from "../../assets/icons/icon-clock.svg";
import nf from "../../assets/icons/icon-nf.svg";
import box1 from "../../assets/icons/icon-box1.svg";
import box2 from "../../assets/icons/icon-box2.svg";
import box3 from "../../assets/icons/icon-box3.svg";

import aguardando from "../../assets/icons/icon-aguardando.svg";
import emtransito from "../../assets/icons/icon-emtransito.svg";

import air from "../../assets/icons/icon-air.png";
import boat from "../../assets/icons/icon-barco.png";

import info from "../../assets/icons/info.svg";
import reload from "../../assets/icons/reload.svg";

export const Container = styled.div`
  height: 28px;
  width: 28px;
  border: 0;
  border-radius: 50%;
  background: ${(props) => props.themes.bg};
  background-image: ${(props) => `url(${props.themes.img})`};
  background-size: ${(props) => props.themes.size};
  cursor: ${(props) => props.themes.cursor};
`;

export const themes = {
  air: {
    bg: "transparent no-repeat center",
    img: air,
    cursor: "pointer",
  },
  boat: {
    bg: "transparent no-repeat center",
    img: boat,
    cursor: "pointer",
  },
  atendida: {
    bg: "#1ABC9C no-repeat center",
    img: atendida,
    cursor: 'pointer',
  },
  naoatendida: {
    bg: "#ccc no-repeat center",
    img: nao,
  },
  atrasada: {
    bg: "#FF4E50 no-repeat center",
    img: nao,
    size: "60%",
    cursor: 'pointer',
  },
  fabricacao: {
    bg: "#F39C12 no-repeat center",
    img: fabricacao,
  },
  nf: {
    bg: "#d0d0d0 no-repeat center 52%",
    img: nf,
    size: "40%",
    cursor: "pointer",
  },
  nfok: {
    bg: "#3498DB no-repeat center 52%",
    img: nf,
    size: "40%",
    cursor: "pointer",
  },
  penEstoque: {
    bg: "#FF4E50 no-repeat center",
    img: box1,
    size: "60%",
    cursor: 'pointer',
  },
  penEmissao: {
    bg: "#3498DB no-repeat center",
    img: box3,
    size: "60%",
    cursor: 'pointer',
  },
  parAtendido: {
    bg: "#F39C12 no-repeat center",
    img: box2,
    size: "60%",
    cursor: 'pointer',
  },
  emtransito: {
    bg: "#3498DB no-repeat center",
    img: emtransito,
  },
  aguardando: {
    bg: "#F39C12 no-repeat center",
    img: aguardando,
  },
  info: {
    bg: "#d0d0d0 no-repeat center",
    img: info,
    size: "70%",
    cursor: 'pointer',
  },
  reload: {
    bg: "#FF4E50 no-repeat center",
    img: reload,
    size: "60%",
    cursor: 'pointer',
  },
};

Container.defaultProps = {
  themes: {
    bg: "#ccc no-repeat center",
    size: "60%",
    cursor: "default",
  },
};
