import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import { logout } from "../../services/auth";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";

// Components
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import ClipLoader from "react-spinners/PulseLoader";
import DatePicker from "react-datepicker";
import Activities from "./Activities";
import Justification from "./Justification";
import Map from "./Map";

//Images
import iconRemetente from "../../assets/icons/icon-remetente.svg";
import iconDestinatario from "../../assets/icons/icon-destinatario.svg";
import iconTransportadora from "../../assets/icons/icon-transportadoras.svg";
import iconConfig from "../../assets/icons/icon-options-table.svg";

// CSS
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  ContentMap,
  BoxRoute,
  NfHeader,
  Status,
  Wrap,
  Nf,
  Emissao,
  Prazo,
  Previsao,
  Entrega,
  Cfop,
  Content,
  WrapBoxes,
  Box,
  BoxNfItems,
  Totals,
  TotalItem,
  HeaderItems,
  TitleCodProd,
  TitleDescrProd,
  TitleQdt,
  TitleGrossWeight,
  TitleNetWeight,
  TitleUnitValue,
  TitleValue,
  ItemNf,
  CodProd,
  DescrProd,
  Qtd,
  GrossWeight,
  NetWeight,
  UnitValue,
  Value,
  Logs,
  themesf,
  Config,
  ConfigOptions,
  ModalConfig,
  Erro,
} from "./styles";

export default function Invoices() {
  const { register, handleSubmit, errors } = useForm();
  const [data, setData] = useState([]);
  const [carrierload, setCarrierload] = useState([]);
  const [typeJustification, setTypeJustification] = useState([]);
  const [justification] = useState([]);
  const [loading, setLoading] = useState(true);
  const [configOp, setConfigOp] = useState(false);
  const [valorTotal, setValorTotal] = useState();
  const [pesoliq, setPesoliq] = useState();
  const [pesobruto, setPesobruto] = useState();
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [msgok, setMsgOk] = useState(false);
  const [modalboxJustify, setModalboxJustify] = useState(false);
  const [modalboxPo, setModalboxPo] = useState(false);
  const [modalboxTranps, setmodalboxTranps] = useState(false);
  const [modalAbonar, setModalAbonar] = useState(false);
  const [modalCancelar, setModalCancelar] = useState(false);
  const [modalNeed, setModalNeed] = useState(false);
  const [modalPrev, setModalPrev] = useState(false);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [prevDate, setPrevDate] = useState("");
  const [user, setUser] = useState();
  const [km, setKm] = useState();

  let history = useHistory();
  let { idnf } = useParams();

  // css modal
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    loadNota();
    loadCarrier();
    userVal();
    typeJustify();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idnf]);

  // Permissão Usuario
  async function userVal() {
    const res = await api.post(`/me`);
    setUser(res.data.permission);
  }

  // dados da nota
  async function loadNota() {
    try {
      const res = await api.get(
        `/tracking/tracking-by-po/null/null?invoiceID=${idnf}`
      );
      setData(res.data.data[0]);
      setLoading(false);
      var vtotal = res.data.data[0].itens.reduce((sum, val) => {
        return sum + val.value;
      }, 0);

      var pesoliquido = res.data.data[0].notafull_peso_liq;

      var pesobruto = res.data.data[0].notafull_peso_brt;

      setValorTotal(vtotal);
      setPesoliq(pesoliquido);
      setPesobruto(pesobruto);
    } catch (err) {
      if (err.response.data.error.code === 401) {
        logout();
      }
    }
  }

  // Transportadora select
  async function loadCarrier() {
    const res = await api.get(`/carrier?perPage=10000`);
    setCarrierload(res.data.data);
  }

  // Trocar Transportadora
  const onchangeCarrier = async (carrier) => {
    setLoadingBtn(true);
    setTxtBtn(false);
    try {
      await api.patch(`invoice/change-carrier/${idnf}`, {
        carrier_id: carrier.alterartransp,
      });
      loadNota();
      handleClose();
      const notify = () =>
        toast.success("Transportadora Alterada com sucesso!");
      notify();
      setLoadingBtn(false);
      setTxtBtn(true);
    } catch (err) {
    }
  };

  // Data de necessidade
  const dateofNeed = async (needdate) => {
    setLoadingBtn(true);
    setTxtBtn(false);
    try {
      await api.patch(`invoice/change-need-date/${idnf}`, {
        needDate: startDate,
      });
      loadNota();
      handleClose();
      const notify = () => toast.success("Data de necessidade inserida!");
      notify();
      setLoadingBtn(false);
      setTxtBtn(true);
    } catch (err) {
    }
  };

  // Data de previsão
  const datePrev = async (needdate) => {
    setLoadingBtn(true);
    setTxtBtn(false);
    try {
      await api.patch(`invoice/change-forecast-date/${idnf}`, {
        forecast_date: prevDate,
      });
      loadNota();
      handleClose();
      const notify = () => toast.success("Data de previsão inserida!");
      notify();
      setLoadingBtn(false);
      setTxtBtn(true);
    } catch (err) {
    }
  };

  // Cancelar NF
  const cancelNf = async (cancel) => {
    setLoadingBtn(true);
    setTxtBtn(false);
    try {
      const res = await api.patch(`invoice/cancel/${idnf}`, {
        date: new Date(),
      });
      loadNota();
      handleClose();
      const notify = () => toast.error("Nota Cancelada");
      notify();
      setLoadingBtn(false);
      setTxtBtn(true);
    } catch (err) {
    }
  };

  // Abonar NF
  const abonarNf = async (cancel) => {
    setLoadingBtn(true);
    setTxtBtn(false);
    try {
      const res = await api.patch(`invoice/allowance/${idnf}`, {
        need_date: new Date(),
      });
      loadNota();
      handleClose();
      const notify = () => toast.success("Nota Abonada!!");
      setLoadingBtn(false);
      setTxtBtn(true);
      notify();
    } catch (err) {
      setLoadingBtn(false);
      setTxtBtn(true);
      const notify = () => toast.error(err.response.data.error.message);
      notify();
    }
  };

  //  justificativa type
  async function typeJustify() {
    const res = await api.get(
      `/justification-type?filters[0][>][justificativa_id]=17&perPage=10000`
    );
    setTypeJustification(res.data.data);
  }
  // Criar justificativa
  const onJustify = async (justi) => {
    try {
      setLoadingBtn(true);
      setTxtBtn(false);
      const response = await api.post(`justification`, {
        invoice_id: data.id,
        justification: justi.justificatva,
        justification_type: justi.tipo,
      });
      handleClose();
      const notify = () => toast.success("Justificativa criada com sucesso!");
      notify();
      setLoadingBtn(false);
      setTxtBtn(true);
    } catch (err) {
    }
  };

  // Alterar PO
  const changePoNumber = async (po) => {
    try {
      setLoadingBtn(true);
      setTxtBtn(false);
      const response = await api.patch(`invoice/change-po-number/${idnf}`, {
        po_number: po.po,
      });
      handleClose();
      const notify = () => toast.success("PO alterada com sucesso!");
      notify();
      setLoadingBtn(false);
      setTxtBtn(true);
      history.push(`/nf/${idnf}`);
      window.location.reload();
    } catch (err) {
    }
  };

  // Open modals
  const OpenConfig = () => {
    setConfigOp(!configOp);
    setMsgOk(false);
  };

  const openNeed = () => {
    setModalNeed(!modalNeed);
    setOpen(!open);
    setConfigOp(!configOp);
  };
  const openPrev = () => {
    setModalPrev(!modalPrev);
    setOpen(!open);
    setConfigOp(!configOp);
  };

  const openPo = () => {
    setModalboxPo(!modalboxPo);
    setOpen(!open);
    setConfigOp(!configOp);
  };

  const addJustification = () => {
    setModalboxJustify(!modalboxJustify);
    setOpen(!open);
    setConfigOp(!configOp);
  };

  const changeCarrier = () => {
    setmodalboxTranps(!modalboxTranps);
    setOpen(!open);
    setConfigOp(!configOp);
  };

  const abonarnote = () => {
    setModalAbonar(!modalAbonar);
    setOpen(!open);
    setConfigOp(!configOp);
  };

  const cancelnote = () => {
    setModalCancelar(!modalCancelar);
    setOpen(!open);
    setConfigOp(!configOp);
  };

  const handleClose = () => {
    setOpen(!open);
    setModalboxJustify(false);
    setmodalboxTranps(false);
    setModalAbonar(false);
    setModalCancelar(false);
    setModalNeed(false);
    setModalboxPo(false);
    setModalPrev(false);
  };

  return (
    <Container>
      <ToastContainer />
      <div className="notborerheader">
        <Header />
      </div>

      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 250,
        }}
      >
        <Fade in={open}>
          <ModalConfig>
            {modalboxPo && (
              <>
                {msgok || (
                  <form onSubmit={handleSubmit(changePoNumber)}>
                    <p className="tit">Alterar número da PO</p>
                    <label htmlFor="">Número PO</label>
                    <input
                      type="text"
                      name="po"
                      id=""
                      ref={register({ required: true })}
                    />
                    {errors.tipo && <Erro>Digite o número</Erro>}
                    <div className="btns">
                      <button type="submit">
                        {txtBtn && "Alterar"}
                        <ClipLoader
                          sizeUnit={"px"}
                          size={10}
                          color={"#ffff"}
                        />
                      </button>
                      <button onClick={handleClose} className="cancel">
                        Cancelar
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
            {modalboxJustify && (
              <>
                {msgok || (
                  <form onSubmit={handleSubmit(onJustify)}>
                    <p className="tit">Adicionar Justificativa</p>
                    <label htmlFor="">Tipo de justificativa</label>
                    <select
                      name="tipo"
                      id=""
                      ref={register({ required: true })}
                    >
                      <option value="">Selecione</option>
                      {typeJustification.map((item) => (
                        <option
                          value={item.justificativa_nome}
                          key={item.justificativa_id}
                        >
                          {item.justificativa_nome}
                        </option>
                      ))}
                    </select>
                    {errors.tipo && <Erro>Digite o tipo de justificativa</Erro>}
                    <label htmlFor="">Justificativa</label>
                    <textarea
                      name="justificatva"
                      id=""
                      cols="30"
                      rows="10"
                      ref={register({ required: true })}
                    ></textarea>
                    {errors.justificatva && <Erro>Digite a justificativa</Erro>}
                    <div className="btns">
                      <button type="submit">
                        {txtBtn && "Adicionar"}
                        <ClipLoader
                          sizeUnit={"px"}
                          size={10}
                          color={"#ffff"}
                          loading={loadingBtn}
                        />
                      </button>
                      <button onClick={handleClose} className="cancel">
                        Cancelar
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
            {modalboxTranps && (
              <>
                {msgok || (
                  <form onSubmit={handleSubmit(onchangeCarrier)}>
                    <p className="tit">Alterar Transportadora</p>
                    <label htmlFor="">Selecione a transportadora</label>
                    <select
                      name="alterartransp"
                      id=""
                      ref={register({ required: true })}
                    >
                      <option value="">Selecione</option>
                      {carrierload.map((item) => (
                        <option value={item.empresa_id} key={item.empresa_id}>
                          {item.empresa_abrev} - {item.empresa_cidade}
                        </option>
                      ))}

                      <option value="golden">Golden</option>
                    </select>
                    {errors.alterartransp && (
                      <Erro>Selecione a transportadora</Erro>
                    )}
                    <div className="btns">
                      <button type="submit">
                        {txtBtn && "Alterar"}
                        <ClipLoader
                          sizeUnit={"px"}
                          size={10}
                          color={"#ffff"}
                          loading={loadingBtn}
                        />
                      </button>
                      <button onClick={handleClose} className="cancel">
                        Cancelar
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
            {modalNeed && (
              <>
                {msgok || (
                  <form onSubmit={handleSubmit(dateofNeed)}>
                    <p className="tit">Alterar Necessidade</p>
                    <label htmlFor="">Data de necessidade</label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale="pt-BR"
                      dateFormat="P"
                      name="necessidade"
                      autoComplete="off"
                    />
                    {errors.alterartransp && <Erro>Digite a data</Erro>}
                    <div className="btns">
                      <button type="submit">
                        {txtBtn && "Inserir"}
                        <ClipLoader
                          sizeUnit={"px"}
                          size={10}
                          color={"#ffff"}
                          loading={loadingBtn}
                        />
                      </button>
                      <button onClick={handleClose} className="cancel">
                        Cancelar
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
            {modalPrev && (
              <>
                {msgok || (
                  <form onSubmit={handleSubmit(datePrev)}>
                    <p className="tit">Alterar Previsão</p>
                    <label htmlFor="">Data de previsão</label>
                    <DatePicker
                      selected={prevDate}
                      onChange={(date) => setPrevDate(date)}
                      locale="pt-BR"
                      dateFormat="P"
                      name="previsao"
                      autoComplete="off"
                    />
                    {errors.alterartransp && <Erro>Digite a data</Erro>}
                    <div className="btns">
                      <button type="submit">
                        {txtBtn && "Inserir"}
                        <ClipLoader
                          sizeUnit={"px"}
                          size={10}
                          color={"#ffff"}
                          loading={loadingBtn}
                        />
                      </button>
                      <button onClick={handleClose} className="cancel">
                        Cancelar
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
            {modalAbonar && (
              <>
                {msgok || (
                  <form onSubmit={handleSubmit(abonarNf)}>
                    <p className="tit">Abonar Nota</p>
                    <div className="btns">
                      <button type="submit">
                        {txtBtn && "Abonar"}
                        <ClipLoader
                          sizeUnit={"px"}
                          size={10}
                          color={"#ffff"}
                          loading={loadingBtn}
                        />
                      </button>
                      <button onClick={handleClose} className="cancel">
                        Cancelar
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
            {modalCancelar && (
              <>
                {msgok || (
                  <form onSubmit={handleSubmit(cancelNf)}>
                    <p className="tit">Cancelar Nota</p>
                    <div className="btns">
                      <button type="submit" className="remove">
                        {txtBtn && "Cancelar nota"}
                        <ClipLoader
                          sizeUnit={"px"}
                          size={10}
                          color={"#ffff"}
                          loading={loadingBtn}
                        />
                      </button>
                      <button onClick={handleClose} className="cancel">
                        Sair
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
          </ModalConfig>
        </Fade>
      </Modal>

      {loading ? (
        ""
      ) : (
        <ContentMap>
          <BoxRoute>
            <div className="startend">
              <div className="icons">
                <i className="fa fa-circle-o" aria-hidden="true" />
                <i className="fa fa-ellipsis-v" aria-hidden="true" />
                <i className="fa fa-map-marker" aria-hidden="true" />
              </div>
              <ul>
                <li>{loading ? "" : data.shipper.address.city}</li>
                <li>{loading ? "" : data.recipient.address.city}</li>
              </ul>
            </div>
          </BoxRoute>
          <NfHeader>
            {data.notafull_cancelado_data ? (
              <Status themesf={themesf.ca}>
                <p>Cancelada</p>
              </Status>
            ) : (
              <Status
                themesf={
                  data.status === "Aguardando Carregamento"
                    ? themesf.at
                    : data.status === "Em Atraso"
                    ? themesf.ta
                    : data.status === "Entregue com atraso"
                    ? themesf.ta
                    : data.status === "Em Trânsito"
                    ? themesf.et
                    : data.status === "Sem Status"
                    ? themesf.st
                    : data.status === "Nota Entregue"
                    ? themesf.en
                    : themesf.not
                }
              >
                <p>{data.status}</p>
              </Status>
            )}
            <Wrap>
              <Nf>
                <h3>Nota Fiscal</h3>
                <p>{data.nf_num}</p>
              </Nf>

              <Emissao>
                <h3>Emissão</h3>
                <p>
                  {data.emission_date
                    ? moment(data.emission_date).format("DD/MM/YYYY")
                    : "-"}
                </p>
              </Emissao>
              <Prazo>
                <h3>Prazo</h3>
                <p>
                  {data.deadline_date
                    ? moment(data.deadline_date).format("DD/MM/YYYY")
                    : "-"}
                </p>
              </Prazo>
              <Previsao>
                <h3>Previsão</h3>
                <p>
                  {data.forecast_date
                    ? moment(data.forecast_date).format("DD/MM/YYYY")
                    : "-"}
                </p>
              </Previsao>
              <Entrega>
                <h3>Entrega</h3>
                <p>
                  {data.delivery_date
                    ? moment(data.delivery_date).format("DD/MM/YYYY")
                    : "-"}
                </p>
              </Entrega>
              <Entrega>
                <h3>Data de Necessidade</h3>
                <p>
                  {
                    (
                      data.data_necessidade &&
                      moment(data.data_necessidade).format("DD/MM/YYYY")
                    ) || 
                    (
                      data.deadline_date &&
                      moment(data.deadline_date).format("DD/MM/YYYY")
                    ) ||
                    "-"
                  }
                </p>
              </Entrega>
              <Cfop>
                <h3>KMs</h3>
                <p>{data.km_remaining || '-'}</p>
              </Cfop>
            </Wrap>
            <Config>
              <img src={iconConfig} onClick={OpenConfig} alt="" />
              {configOp && (
                <ConfigOptions>
                  {user === "root" ? (
                    <>
                      <p onClick={addJustification}>Adicionar Justificativa</p>
                      <p onClick={changeCarrier}>Alterar Transportadora</p>
                      {data.notafull_cancelado_data ? (
                        ""
                      ) : (
                        <p onClick={openNeed}>Alterar Necessidade</p>
                      )}
                      {data.notafull_cancelado_data ? (
                        ""
                      ) : (
                        <p onClick={openPrev}>Alterar Previsão</p>
                      )}

                      {data.status === "Entregue com atraso" ? (
                        <>
                          {data.notafull_abono_data ? (
                            <span className="ab">Nota Abonada</span>
                          ) : justification[0] ? (
                            <p onClick={abonarnote}>Abonar</p>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {data.status === "Em Atraso" ? (
                        <>
                          {data.notafull_abono_data ? (
                            <span className="ab">Nota Abonada</span>
                          ) : justification[0] ? (
                            <p onClick={abonarnote}>Abonar</p>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {data.notafull_cancelado_data ? (
                        <span>Nota Cancelada</span>
                      ) : (
                        <p onClick={cancelnote}>Cancelar NF</p>
                      )}
                    </>
                  ) : user === "administradora-col" ? (
                    <>
                      <p onClick={changeCarrier}>Alterar Transportadora</p>
                      {data.status === "Cancelada" ? (
                        ""
                      ) : (
                        <p onClick={openNeed}>Alterar Necessidade</p>
                      )}
                      {data.status === "Entregue com atraso" ? (
                        <>
                          {data.notafull_abono_data ? (
                            <span className="ab">Nota Abonada</span>
                          ) : justification[0] ? (
                            <p onClick={abonarnote}>Abonar</p>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {data.status === "Em Atraso" ? (
                        <>
                          {data.notafull_abono_data ? (
                            <span className="ab">Nota Abonada</span>
                          ) : justification[0] ? (
                            <p onClick={abonarnote}>Abonar</p>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {data.notafull_cancelado_data ? (
                        <span>Nota Cancelada</span>
                      ) : (
                        <p onClick={cancelnote}>Cancelar NF</p>
                      )}
                    </>
                  ) : (
                    <>
                      <p onClick={addJustification}>Adicionar Justificativa</p>
                    </>
                  )}
                </ConfigOptions>
              )}
            </Config>
          </NfHeader>

          <Map data={data} setNewKm={(newKm) => setKm(newKm)} />
        </ContentMap>
      )}
      {loading ? (
        <Loading />
      ) : (
        <Content>
          <WrapBoxes>
            <Box>
              <h2>
                <img src={iconRemetente} alt="Remetente" /> Remetente
              </h2>
              <div className="info">
                <span>Razão Social</span>
                <p>{data.shipper.name ? data.shipper.name : "----"}</p>
              </div>
              <div className="info">
                <span>CNPJ</span>
                <p>{data.shipper.cnpj ? data.shipper.cnpj : "----"}</p>
              </div>
              <div className="info">
                <span>Endereço</span>
                <p>
                  {data.shipper.address.address}, {data.shipper.address.number}{" "}
                  {data.shipper.address.district} - {data.shipper.address.city}{" "}
                  <br />
                  CEP: {data.shipper.address.cep}
                </p>
              </div>
            </Box>
            <Box>
              <h2>
                <img src={iconTransportadora} alt="Transportadora" />
                Transportadora
              </h2>
              <div className="info">
                <span>Razão Social</span>
                <p>
                  {data.shipping_company.name
                    ? data.shipping_company.name
                    : "----"}
                </p>
              </div>
              <div className="info">
                <span>CNPJ</span>
                <p>
                  {data.shipping_company.cnpj
                    ? data.shipping_company.cnpj
                    : "----"}
                </p>
              </div>
              <div className="info">
                <span>Endereço</span>
                <p>
                  {data.shipping_company.address.address},{" "}
                  {data.shipping_company.address.number}{" "}
                  {data.shipping_company.address.district} -{" "}
                  {data.shipping_company.address.city} <br />
                  CEP: {data.shipping_company.address.cep}
                </p>
              </div>
            </Box>
            <Box>
              <h2>
                <img src={iconDestinatario} alt="Destinatário" /> Destinatário
              </h2>
              <div className="info">
                <span>Razão Social</span>
                <p>{data.recipient.name ? data.recipient.name : "----"}</p>
              </div>
              <div className="info">
                <span>CNPJ</span>
                <p>{data.recipient.cnpj ? data.recipient.cnpj : "----"}</p>
              </div>
              <div className="info">
                <span>Endereço</span>
                <p>
                  {data.recipient.address.address},{" "}
                  {data.recipient.address.number}{" "}
                  {data.recipient.address.district} -{" "}
                  {data.recipient.address.city} <br />
                  CEP: {data.recipient.address.cep}
                </p>
              </div>
            </Box>
          </WrapBoxes>
          <BoxNfItems>
            <Totals>
              <TotalItem>
                <span>Valor Total</span>
                <big>
                  <NumberFormat
                    value={valorTotal}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"R$ "}
                  />
                </big>
              </TotalItem>
              <TotalItem>
                <span>Peso Líquido</span>
                <big>
                  <NumberFormat
                    value={pesoliq}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix={" KG"}
                  />
                </big>
              </TotalItem>
              <TotalItem>
                <span>Peso Bruto</span>
                <big>
                  <NumberFormat
                    value={pesobruto}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix={" KG"}
                  />
                </big>
              </TotalItem>
            </Totals>
            <HeaderItems>
              <TitleCodProd>Código Prod.</TitleCodProd>
              <TitleDescrProd>Descrição do produto</TitleDescrProd>
              <TitleQdt>Quantidade</TitleQdt>
              <TitleGrossWeight>Peso Bruto</TitleGrossWeight>
              <TitleNetWeight>Peso Líquido</TitleNetWeight>
              <TitleUnitValue>Valor unitário</TitleUnitValue>
              <TitleValue>Valor</TitleValue>
            </HeaderItems>
            {data.itens &&
              data.itens.map((item) => (
                <ItemNf key={item.id}>
                  <CodProd>{item.cod}</CodProd>
                  <DescrProd>{item.item}</DescrProd>
                  <Qtd>
                    <NumberFormat
                      value={item.amount}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                    />
                  </Qtd>
                  <GrossWeight>
                    <NumberFormat
                      value={item.gross_weight}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      suffix={" KG"}
                    />
                  </GrossWeight>
                  <NetWeight>
                    <NumberFormat
                      value={item.net_weight}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      suffix={" KG"}
                    />
                  </NetWeight>
                  <UnitValue>
                    <NumberFormat
                      value={item.uni_value}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                    />
                  </UnitValue>
                  <Value>
                    <NumberFormat
                      value={item.value}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                    />
                  </Value>
                </ItemNf>
              ))}
          </BoxNfItems>
          <Logs>
            <Activities props={(activies) => activies()} />
            <Justification props={(loadjust) => loadjust()} />
          </Logs>
        </Content>
      )}
    </Container>
  );
}
