import { call, put, select } from 'redux-saga/effects';
import { notify } from '../../../services/notification';
import api, { makeOptions } from '../../../services/api';
import ActionsSapCreateMultiples from '../../ducks/sap/create-multiples';

const getAuthData = (state) => state.auth.data;

export function* createMultiples(action) {
  try {
    const { data: postData, onSuccess } = action;

    const url = `bom-sap/multiple`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.post, url, postData, makeOptions(authData));

    yield put(ActionsSapCreateMultiples.success(data));
    onSuccess();
  } catch (err) {
    notify('error', err.response.data.error.message);
    yield put(
      ActionsSapCreateMultiples.failure(err.response.data.error.message)
    );
  }
}
