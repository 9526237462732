// Images

// import iCompany from '../assets/icons/icon-company.svg'
import iProducts from "../assets/icons/icon-products.svg";
import iReference from "../assets/icons/icon-produdctReference.svg";
import iBom from "../assets/icons/icon-bom.svg";
import iUpload from "../assets/icons/icon-upload.svg";
import iToller from "../assets/icons/icon-toller.svg";
import iJustify from "../assets/icons/icon-justification.svg";

//  ----------------------------------------------------------------

export const links = [
  {
    label: "Campanhas",
    url: "/",
    txt: "campanhas",
  },
  {
    label: "Consumo",
    url: "/consumo",
    txt: "menu.consumo",
  },
  {
    label: "Configurações",
    url: "/configuracoes/",
    txt: "menu.config",
  },
  {
    label: "Dashboard",
    url: "/dashboard/",
    txt: "menu.dashboard",
  },
  /*{
    label: "Tracking",
    url: "/monitoramento/",
    txt: "menu.tracking"
  }*/
];

export const linksMenuRight = [
  {
    label: "Campanhas",
    url: "/",
    txt: "campanhas",
  },
  {
    label: "Configurações",
    url: "/configuracoes/",
    txt: "menu.config",
  },
  {
    label: "Dashboard",
    url: "/dashboard/",
    txt: "menu.dashboard",
  },
  {
    label: "Manual do Sistema",
    url: "/manual/",
    txt: "manual",
  },
  {
    label: "Alertas",
    url: "/alertas/",
    txt: "alertas",
  },
];

export const asideLinks = [
  {
    label: "Produtos",
    url: "/configuracoes/produtos",
    img: iProducts,
    txt: "menu.produtos",
  },
  {
    label: "Referencia de Produtos",
    url: "/configuracoes/referenciaprodutos",
    img: iReference,
    txt: "referenciaprodutos",
  },
  {
    label: "BOM (Bill Of Materials)",
    url: "/configuracoes/bom",
    img: iBom,
    txt: "bom",
  },
  {
    label: "Importação Campanha",
    url: "/configuracoes/importacao",
    img: iUpload,
    txt: "importacaocampanha",
  },
  {
    label: "Importação Saldo",
    url: "/configuracoes/importacaoSap",
    img: iUpload,
    txt: "importacaosaldo",
  },
  {
    label: "Toller",
    url: "/configuracoes/toller",
    img: iToller,
    txt: "toller",
  },
  {
    label: "Justificativa",
    url: "/configuracoes/justificativa",
    img: iJustify,
    txt: "justificativa",
  },
  {
    label: "Grupo Similar",
    url: "/configuracoes/grupo-similar",
    img: iProducts,
    txt: "grupo_similar",
  },
];
