import React, { useState, useEffect } from "react";
import moment from "moment";
import { css } from "@emotion/core";
import { useForm } from "react-hook-form";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";

// Components
import Header from "../../../../components/Header";
import AsideMenu from "../../../../components/AsideMenu";
import BtnBack from "../../../../components/BtnBack";
import Text from "../../../../components/Text";

//Images
import iconTit from "../../../../assets/icons/icon-campaing.svg";
import iProducts from "../../../../assets/icons/icon-products.svg";

//CSS
import {
  Center,
  TopHeader,
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  Button,
  Btns,
} from "../../../../styles/style";
import {
  Container,
  Content,
  Err,
  Loading,
  LoadingBtn,
  InfoProduct,
} from "../styles";

// Helpers
import replaceComma from "../../../../helpers/replaceComma";

const cssloading = css`
  display: flex;
  margin: 40px auto;
  justify-content: center;
`;

export default function Details({ history }) {
  const { register, handleSubmit, errors } = useForm();

  const [units, setUnits] = useState([]);
  const [types, setTypes] = useState([]);
  const [similars, setSimilars] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [txtDelete, setTxtDelete] = useState(true);
  const [loadBtnDelete, setloadBtnDelete] = useState(false);

  let { id } = useParams();

  const msgsuccess = () => toast.success("Produto atualizado!");
  const msgdelete = () => toast.success("Produto removido com sucesso!");

  async function onSubmit(data, e) {
    setLoadingBtn(true);
    setTxtBtn(false);

    const quantityUnit = replaceComma(data.quantity_unit);
    const quantityPallet = replaceComma(data.quantity_pallet);
    const quantityBallast = replaceComma(data.quantity_ballast);
    const quantityPiled = replaceComma(data.quantity_piled);

    await api
      .patch(`/product/${id}`, {
        code: data.cod,
        name: data.description,
        product_types_id: data.type,
        product_units_id: data.product_unit,
        quantity_unit: quantityUnit,
        quantity_pallet: quantityPallet,
        quantity_ballast: quantityBallast,
        quantity_piled: quantityPiled,
        group_similar_id: data.group_similar,
      })
      .then((result) => {
        e.target.reset();
        msgsuccess();
        history.push("/configuracoes/produtos");
        setLoadingBtn(false);
        setTxtBtn(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    async function load(url, set, loading) {
      await api
        .get(url)
        .then((result) => {
          set(result.data.data);
          setLoading(loading);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function loadModules() {
      await load(`/product-type?perPage=false`, setTypes, true);
      await load(`/product-unit?perPage=false`, setUnits, true);
      await load(`/group-similar?perPage=false`, setSimilars, true);
      await load(`/product/${id}`, setProducts, false);
    }
    loadModules();
  }, [id]);

  async function removeProduct() {
    setloadBtnDelete(true);
    setTxtDelete(false);
    withSwalInstance(
      swal
        .fire({
          title: "Você tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1ABC9C",
          cancelButtonColor: "#FF4E50",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim, pode deletar!",
        })
        .then(async (result) => {
          if (result.value) {
            await api
              .delete(`/product/${id}`)
              .then((result) => {
                setloadBtnDelete(false);
                setTxtDelete(true);
                msgdelete();
                history.push("/configuracoes/produtos");
                swal.fire("Excluído!", "Produto foi excluído", "success");
              })
              .catch((error) => {
                console.log(error);
                setloadBtnDelete(false);
                setTxtDelete(true);
                swal.fire(
                  "Cancelado",
                  "O Produto não pode ser excluído",
                  "error"
                );
              });
          } else {
            setloadBtnDelete(false);
            setTxtDelete(true);
          }
        })
    );
  }

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>

        <Container>
          <AsideMenu />

          <Content>
            <header className="content">
              <h2>
                <img src={iProducts} alt="" />
                <Text id="produto" dm="Produto" />
              </h2>
            </header>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <DescriptionTitle>
                <Text id="info.produto" dm="Informações do produto" />
              </DescriptionTitle>

              {loading ? (
                <Loading
                  css={cssloading}
                  sizeUnit={"px"}
                  size={10}
                  height={2}
                  color={"#0459a9"}
                />
              ) : (
                <>
                  <BoxForm>
                    <Row className="r2">
                      <InputBox>
                        <label htmlFor="">
                          <Text id="cod.sap" dm="Código SAP" />
                        </label>
                        <input
                          name="cod"
                          defaultValue={products.code}
                          ref={register({ required: true })}
                        />
                        {errors.cod && <Err>Digite o código</Err>}
                      </InputBox>
                      <InputBox>
                        <label htmlFor="">
                          <Text id="tipo" dm="Tipo" />
                        </label>
                        <select
                          // disabled
                          defaultValue={products.product_types_id}
                          name="type"
                          ref={register({ required: true })}
                          id=""
                        >
                          {types.map((typ) => (
                            <option key={typ.id} value={typ.id}>
                              {typ.name}
                            </option>
                          ))}
                        </select>
                        {errors.type && <Err>Selecione o tipo</Err>}
                      </InputBox>
                    </Row>
                    <Row className="r2">
                      <InputBox>
                        <label htmlFor="">
                          <Text id="qtd.kg.un" dm="Quantidade KG/L Unidade" />
                        </label>
                        <input
                          name="quantity_unit"
                          defaultValue={products.quantity_unit}
                          ref={register()}
                        />
                        {errors.quantity_unit && (
                          <Err>Digite a quantidade de kg/l por unidade</Err>
                        )}
                      </InputBox>
                      <InputBox>
                        <label htmlFor="">
                          <Text
                            id="qtd.un.pallet"
                            dm="Quantidade Unidade/Pallet"
                          />
                        </label>
                        <input
                          defaultValue={products.quantity_pallet}
                          name="quantity_pallet"
                          ref={register()}
                        />
                        {errors.quantity_pallet && (
                          <Err>Digite a quantidade de unidades por pallet</Err>
                        )}
                      </InputBox>
                    </Row>
                    <Row className="r2">
                      <InputBox>
                        <label htmlFor="">
                          <Text id="qtd.lastro" dm="Quantidade Lastro" />
                        </label>
                        <input
                          defaultValue={products.quantity_ballast}
                          name="quantity_ballast"
                          ref={register()}
                        />
                        {errors.quantity_ballast && (
                          <Err>Digite a quantidade</Err>
                        )}
                      </InputBox>
                      <InputBox>
                        <label htmlFor="">
                          <Text id="qtd.emp" dm="Quantidade Empilhado" />
                        </label>
                        <input
                          defaultValue={products.quantity_piled}
                          name="quantity_piled"
                          ref={register()}
                        />
                        {errors.quantity_piled && (
                          <Err>Digite a quantidade</Err>
                        )}
                      </InputBox>
                    </Row>
                    <Row className="r2-1">
                      <InputBox>
                        <label htmlFor="">
                          <Text id="descricao" dm="Descrição" />
                        </label>
                        <input
                          name="description"
                          defaultValue={products.name}
                          ref={register({ required: true })}
                        />
                        {errors.description && (
                          <Err>Digite o nome do produto</Err>
                        )}
                      </InputBox>
                      <InputBox>
                        <label htmlFor="">
                          <Text id="un.medida" dm="Unidade de Medida" />
                        </label>

                        <select
                          defaultValue={products.product_units_id}
                          name="product_unit"
                          ref={register({ required: true })}
                          id=""
                        >
                          {units.map((unit) => (
                            <option key={unit.id} value={unit.id}>
                              {unit.name}
                            </option>
                          ))}
                        </select>
                        {errors.product_unit && <Err>Selecione o tipo</Err>}
                      </InputBox>
                    </Row>

                    <Row className="r2">
                      <InputBox>
                        <label htmlFor="">
                          <Text id="estoque" dm="Estoque" />
                        </label>
                        <input
                          defaultValue={
                            products.balance
                              ? products.balance.toLocaleString()
                              : "Não informado!"
                          }
                          disabled
                        />
                      </InputBox>
                      <InputBox>
                        <label htmlFor="">
                          <Text id="depoisto" dm="Depósito" />
                        </label>
                        {}
                        <input
                          defaultValue={
                            products.last_product_stocks === null
                              ? "Não informado!"
                              : products.last_product_stocks.deposit
                          }
                          disabled
                        />
                      </InputBox>
                    </Row>
                    <Row className="r1">
                      <InputBox>
                        <label htmlFor="">
                          <Text id="grupo_similar" dm="Grupo Similar" />
                        </label>
                        <select
                          defaultValue={products.group_similar_id}
                          name="group_similar"
                          ref={register()}
                          id=""
                        >
                          <>
                            <option value="" />
                            {similars.map((similar) => (
                              <option key={similar.id} value={similar.id}>
                                {similar.name}
                              </option>
                            ))}
                          </>
                        </select>
                        {errors.group_similar && <Err>Selecione o similar</Err>}
                      </InputBox>
                    </Row>
                    <InfoProduct>
                      <p>
                        {products.last_product_stocks === null
                          ? ""
                          : "Atualização:"}

                        {products.last_product_stocks === null
                          ? ""
                          : "" +
                            moment(
                              new Date(products.last_product_stocks.created_at)
                            )
                              .subtract(3, "hours")
                              .format("DD/MM/YYYY HH:mm")}
                      </p>
                    </InfoProduct>
                  </BoxForm>
                  <Btns className="crem">
                    <Button
                      type="reset"
                      className="delete"
                      onClick={removeProduct}
                    >
                      {txtDelete && <Text id="remover" dm="Remover" />}
                      <LoadingBtn
                        marginTop={"10px"}
                        sizeUnit={"px"}
                        size={10}
                        height={2}
                        color={"#fff"}
                        loading={loadBtnDelete}
                      />
                    </Button>
                    <span>
                      <Button
                        type="reset"
                        className="cancel"
                        onClick={history.goBack}
                      >
                        <Text id="cancelar" dm="Cancelar" />
                      </Button>
                      <Button>
                        {txtBtn && <Text id="salvar" dm="Salvar" />}
                        <LoadingBtn
                          marginTop={"10px"}
                          sizeUnit={"px"}
                          size={10}
                          height={2}
                          color={"#fff"}
                          loading={loadingBtn}
                        />
                      </Button>
                    </span>
                  </Btns>
                </>
              )}
            </Form>
          </Content>
        </Container>
      </Center>
    </>
  );
}
