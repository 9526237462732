import { call, put, select } from "redux-saga/effects";
import { notify } from "../../../services/notification";
import api, { makeOptions } from "../../../services/api";
import ActionsCompareApproved from "../../ducks/compare/compare-approved";

const getAuthData = (state) => state.auth.data;

export function* create(action) {
  try {
    const { post, onSuccess } = action;
    const url = `bom-compare`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.post, url, post, makeOptions(authData));

    yield put(ActionsCompareApproved.success(data));
    onSuccess();
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(ActionsCompareApproved.failure(err.response.data.error.message));
  }
}
