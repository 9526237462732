import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import api from "../../../services/api";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// Components
import Header from "../../../components/Header";
import AsideMenu from "../../../components/AsideMenu";
import BtnAdd from "../../../components/BtnAdd";
import LoadPage from "../../../components/LoadPage";
import BtnExcel from "../../../components/BtnExcel";
import BtnBack from "../../../components/BtnBack";
import Text from "../../../components/Text";

//Images
import iconTit from "../../../assets/icons/icon-campaing.svg";
import iSearch from "../../../assets/icons/icon-search.svg";
import iReference from "../../../assets/icons/icon-produdctReference.svg";

//CSS
import { Center, TopHeader } from "../../../styles/style";
import {
  Container,
  Content,
  FilterConfig,
  ProductContainer,
  HeaderList,
  ItemList,
} from "./styles";

export default function ProductReference() {
  const [search, setSearch] = useState("");
  const [reference, setReference] = useState([]);
  const [loading, setLoading] = useState(true);

  const [file, setFile] = useState("");
  const [fileName, seFileName] = useState("");

  let history = useHistory();

  useEffect(() => {
    async function feachReference() {
      await api
        .get(`/product-branch?perPage=10000`)
        .then((result) => {
          setReference(result.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    feachReference();
  }, []);

  useEffect(() => {
    function generateExcelData() {
      let arrayExcel = [];

      reference.forEach((refe) => {
        const EMPRESA = refe.branch.company_name || "-";
        const PRODUTO = refe.product.name || "-";
        const CODIGO_EMPRESA = refe.code || "-";
        const CODIGO_CORTEVA = refe.product.code || "-";

        const data = {
          EMPRESA,
          PRODUTO,
          CODIGO_EMPRESA,
          CODIGO_CORTEVA,
        };

        arrayExcel.push(data);
      });

      return arrayExcel;
    }

    if (reference[0]) {
      const newFile = generateExcelData();

      setFile(newFile);

      const newFileName = `REFERENCIA_DE_PRODUTOS_${moment(new Date()).format(
        "DD_MM_YYYY"
      )}`;

      seFileName(newFileName);
    }
  }, [reference]);

  function exportToCSV() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(file);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const results = !search
    ? reference
    : reference.filter(
        (e) =>
          e.product.name.toLowerCase().includes(search.toLocaleLowerCase()) ||
          e.product.code.toLowerCase().includes(search.toLocaleLowerCase()) ||
          e.code.toLowerCase().includes(search.toLocaleLowerCase())
      );

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>
        <Container>
          <AsideMenu />

          <Content>
            <header>
              <h2>
                <img src={iReference} alt="" />
                <Text id="referenciaprodutos" dm="Referencia de Produtos" />
              </h2>

              <FilterConfig>
                <img src={iSearch} alt="" />
                <input
                  placeholder="Buscar produto"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FilterConfig>

              {!loading && (
                <BtnExcel
                  type="button"
                  disabled={!reference}
                  onClick={() => exportToCSV()}
                  text="Exportar Relatório"
                />
              )}

              <Link to="referenciaprodutos/novo">
                <BtnAdd />
              </Link>
            </header>

            <ProductContainer>
              <HeaderList>
                <p>
                  <Text id="empresa" dm="Empresa" />
                </p>
                <p>
                  <Text id="produto" dm="Produto" />
                </p>
                <p>
                  <Text id="codigo" dm="Código" />{" "}
                  <Text id="toller" dm="Toller" />
                </p>
                <p>
                  <Text id="codigo" dm="Código" /> Corteva
                </p>
              </HeaderList>
              {loading ? (
                <LoadPage />
              ) : (
                <>
                  {results.map((refe) => (
                    <ItemList
                      key={refe.id}
                      onClick={() => {
                        history.push(`referenciaprodutos/${refe.id}`);
                      }}
                    >
                      <p>{refe.branch.company_name}</p>
                      <p>{refe.product.name}</p>
                      <p>{refe.code}</p>
                      <p>{refe.product.code}</p>
                    </ItemList>
                  ))}
                </>
              )}
            </ProductContainer>
          </Content>
        </Container>
      </Center>
    </>
  );
}
