import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl-hooks";

import locale_en from "./en.json";
import locale_pt from "./pt.json";
import locale_es from "./es.json";

import LanguageActions from "../store/ducks/language";

// import { Container } from './styles';

function Lang({ children }) {
  const dispatch = useDispatch();
  const data = {
    pt: locale_pt,
    en: locale_en,
    es: locale_es,
  };

  const { data: dataLanguage } = useSelector((state) => state.language);

  const loadLang = useCallback(() => {
    dispatch(LanguageActions.request());
  }, [dispatch]);

  useEffect(() => {
    loadLang();
  }, [loadLang]);

  return (
    <IntlProvider
      locale={dataLanguage}
      messages={data[dataLanguage]}
      defaultLocale="pt"
    >
      {children}
    </IntlProvider>
  );
}

export default Lang;
