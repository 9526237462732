import styled from "styled-components";
import { Warning } from "@styled-icons/entypo";
import { CommentDetail, Trash } from "@styled-icons/boxicons-regular";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Item = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr repeat(4, minmax(90px, auto));
  column-gap: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  height: 54px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
`;

export const Value = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.dark};

  &.diff {
    color: ${({ theme }) => theme.colors.magenta};
  }
`;

export const IconWarning = styled(Warning).attrs({ size: 20 })`
  color: ${({ theme }) => theme.statusColors.divergente};
`;

export const IconComment = styled(CommentDetail).attrs({ size: 20 })`
  color: ${({ theme }) => theme.colors.lightDarkBlue};
`;

export const IconTrash = styled(Trash).attrs({ size: 20 })`
  color: ${({ theme }) => theme.colors.lightDarkBlue};
`;

export const Justificantion = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.darkRgba8};
  border-radius: 0 0 6px 6px;
`;

export const JustificantionText = styled.article`
  width: 100%;
  margin-right: 16px;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme }) => theme.colors.lightDarkBlue};
`;

export const TrashButton = styled.button.attrs({ type: "button" })`
  width: 20px;
  height: 20px;
  display: flex;
  transition: 300ms ease;

  &:hover {
    transform: scale(1.1);
  }
`;
