import styled from 'styled-components';

import { BarLoader } from 'react-spinners';

import icondelete from '../assets/icons/icon-delete.svg';
import date from '../assets/icons/icon-calendar.png';

export const Center = styled.div`
  /* max-width: 1130px; */
  padding: 0 32px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 1099px) {
    width: 92%;
  }
`;
export const Content = styled.div`
  width: 100%;
  padding: 30px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
`;
export const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  min-height: 45px;
  align-items: center;
  .icontit {
    margin-right: 20px;
  }
  h1 {
    font-size: 20px;
  }
  span {
    display: flex;
    align-items: center;
  }
  .bAwPaa {
    margin-left: 20px;
  }
  .active {
    .sc-dnqmqq {
      background: #fff;
      .icon-filter {
        span {
          &:before {
            left: 10px;
          }
          &:nth-child(2n) {
            &:before {
              left: 2px;
            }
          }
        }
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  padding: 10px 0;
  margin-bottom: 5%;
  font-family: "Gilroy-Bold";
`

export const BtnDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  cursor: pointer;
  span {
    display: block;
    background: #000;
    width: 4px;
    height: 4px;
    margin: 5px 0;
    transition: ease-in 0.1s;
  }
  &:after,
  &:before {
    content: '';
    width: 4px;
    height: 4px;
    display: block;
    background: #000;
    transition: ease-in 0.2s;
  }
  &:before {
    transition: ease-in 0.3s;
  }
  &:hover {
    span {
      transform: scale(1.2) translateX(2px);
    }
    &:after,
    &:before {
      transform: scale(1.2) translateX(-2px);
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 845px;
  margin-bottom: 20px;
  &.mt {
    padding-top: 40px;
    margin-bottom: 80px;
    border-top: solid 1px #ccc;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container input {
    background: url(${date}) no-repeat 90%;
  }
  .basic-single {
    input {
      height: 38px;
    }
  }
  label {
    display: block;
    color: #292d41;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  input,
  select {
    width: 100%;
    border: solid 1px #c2c2c2;
    border-radius: 6px;
    padding: 0 10px;
    height: 50px;
    color: #4c5062;
    font-size: 14px;
    font-weight: 300;
    &::placeholder {
      color: #bbb;
    }
    &:disabled {
      background: #f8f8f8;
    }
    &.hidden {
      position: absolute;
      visibility: hidden;
      left: 0;
    }
  }
`;
export const DescriptionTitle = styled.p`
  font-size: 16px;
  margin-bottom: 25px;
  color: #292d41;
  font-weight: 300;
`;

export const BoxForm = styled.div`
  width: 100%;
  padding: 25px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
`;
export const Row = styled.div`
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
  &.r1-3 {
    grid-template-columns: 1fr 2fr;
  }
  &.r2-1 {
    grid-template-columns: 2fr 1fr;
  }
  &.r2 {
    grid-template-columns: 1fr 1fr;
  }
  &.r3 {
    grid-template-columns: repeat(3, 1fr);
  }
  &.r311 {
    grid-template-columns: 3fr 1fr 0.6fr;
    align-items: end;
  }
  &.r211 {
    grid-template-columns: 2fr 2fr 1fr 0.6fr;
    align-items: end;
  }
`;
export const SelectBox = styled.div`
  width: 100%;
`

export const SelectForm = styled.select`
  display: flex;
  flex: 1;
  margin: 5px 0;
`
export const SelectOption = styled.option``


export const InputBox = styled.div`
  position: relative;
`;

export const InputsBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const InputBoxJustification = styled.select`
  display: flex;
  padding: 6px;
  flex-direction: column;
  width: 100%;
  height: 33px;
  border-radius: 4px;
  border-width: 2px;
  border-color: #e3e6e8;
  margin-bottom: 5px;

  option{
    margin: 0;
    padding: 0;
  }

`;

export const InputReadOnly = styled.div`
  display: flex;
  padding: 6px;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 4px;
  border-width: 2px;
  border-color: #e3e6e8;
  margin-bottom: 5px;
  color: black;

  label{
    height: auto;
    color: #707070;
  }

`;

export const Button = styled.button`
  height: 50px;
  border-radius: 6px;
  background: #1abc9c;
  color: #fff;
  min-width: 100px;
  padding: 0 20px;
  &.cancel {
    background: #8b8d97;
  }
  &.delete {
    background: #ff4e50;
  }
  div {
    margin: auto;
  }
  &.sticky {
    position: sticky;
    top: 20px;
  }
  &.eye {
    display: flex;
    align-items: center;
    background: #3498db;
    img {
      width: 20px;
      margin-right: 10px;
      filter: invert(1);
    }
  }
`;
export const Button100 = styled(Button)`
  width: 100%;
  &:disabled {
    background-color: #bcbcbc;
  }
`;
export const ButtonDelete = styled.button`
  width: 24px;
  height: 24px;
  background: url(${icondelete}) no-repeat center;
  background-size: cover;
  border-radius: 50%;
`;
export const MaterialList = styled.div`
  header {
    display: grid;
    grid-template-columns: 1fr 2.4fr 2.4fr 0.2fr;
    padding: 0 20px;
    margin-bottom: 20px;
    p {
      font-size: 14px;
      font-weight: 300;
    }
  }
`;
export const ReceptorList = styled(MaterialList)`
  width: 100%;
  max-width: 845px;
  header {
    grid-template-columns: 1.6fr 1.6fr 1fr 0.2fr;
  }
`;

export const MaterialItem = styled.div`
  width: 100%;
  padding: 20px;
  padding-right: 10px;
  border-radius: 6px;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 2.4fr 2.4fr 0.2fr;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: all ease 0.1s;
  p {
    font-size: 11px;
    color: #8b8d97;
    font-weight: 300;
    /* @media screen and (max-width: 700px) {
            font-size: 10px;
            &:nth-child(3), &:nth-child(4), &:nth-child(5){
                display: none;
            }
        } */
    &:first-child {
      font-weight: 700;
    }
  }
  .status {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ReceptorItem = styled(MaterialItem)`
  grid-template-columns: 1.6fr 1.6fr 1fr 0.2fr;
`;
export const Btns = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 40px 0;
  span {
    display: flex;
  }
  button {
    margin-left: 10px;
  }
  &.crem {
    justify-content: space-between;
    button {
      &:first-child {
        margin-left: 0;
      }
    }
  }
`;
export const Err = styled.div`
  font-size: 10px;
  color: red;
  margin: 5px 0;
`;
export const LoadingBtn = styled(BarLoader)``;
