import styled from "styled-components";

import date from "../../assets/icons/icon-calendar.png";
import check from "../../assets/icons/icon-check.png";
import atendida from "../../assets/icons/icon-ok.svg";
import nao from "../../assets/icons/icon-clock.svg";
import all from "../../assets/icons/icon-allstatus.png";
import box2 from "../../assets/icons/icon-box2.svg";

export const Container = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 6px;
  border-left: solid 7px #4c5062;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
  position: relative;
  transition: all ease 0.3s;
  transform: translate(0, -20px);
  opacity: 1;
  z-index: 9;

  &:before {
    content: "";
    position: absolute;
    top: -6px;
    right: 100px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #ffffff transparent;
  }
`;

export const FormFilter = styled.form`
  display: grid;
  grid-template-columns: 0.9fr 1.4fr 1.2fr repeat(2, 1fr) 0.4fr 0.5fr;
  grid-gap: 10px;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  label {
    display: block;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
    text-align: left;
  }
  input,
  select {
    width: 100%;
    height: 50px;
    border: solid 1px #c2c2c2;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 300;
    transition: all ease 0.3s;
    &:focus {
      border: solid 1px #292d41;
    }
    &.date {
      background: url(${date}) no-repeat 90%;
    }
  }
  input[type="checkbox"] {
    width: 18px !important;
    height: 18px;
    appearance: none;
    padding: 0;
    border: solid 2px #929292;
    background-position: center;
    cursor: pointer;
    &:checked {
      background: url(${check}) no-repeat center;
      border: solid 2px #292d41;
    }
  }
  .react-datepicker__input-container input {
    background: url(${date}) no-repeat 90%;
  }
`;
export const ItemForm = styled.div`
  position: relative;
`;
export const Button = styled.button`
  height: 50px;
  width: 100%;
  background: #1abc9c;
  border: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
`;

export const BtnStatus = styled.div`
  width: 50px;
  height: 50px;
  background: ${(props) => props.themes.bg};
  background-image: ${(props) => `url(${props.themes.img})`};
  border-radius: 50%;
  cursor: pointer;
  background-size: 50%;
  margin: auto;
`;

export const StatusFilter = styled.div`
  position: absolute;
  width: 185px;
  padding: 20px 20px;
  border-radius: 6px;
  left: calc(-92px + 50%);
  margin-top: 10px;
  background: #4c5062;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.26);

  &:before {
    content: "";
    position: absolute;
    top: -8px;
    right: 0;
    left: 0;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #4c5062 transparent;
  }
  label {
    display: flex !important;
    align-items: center;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-bottom: solid 1px #707385;
    padding-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
      border: 0;
      padding: 0;
    }
  }
  input[type="radio"] {
    width: 1px;
    height: 1px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }
`;

export const Icon = styled.div`
  width: 28px;
  height: 28px;
  background: #8b8d97;
  border-radius: 50%;
  margin-right: 10px;
  flex: none;
  background: ${(props) => props.themes.bg};
  background-image: ${(props) => `url(${props.themes.img})`};
  background-size: 70%;
`;

export const themes = {
  td: {
    bg: "#8b8d97 no-repeat center",
    img: all,
  },
  atendida: {
    bg: "#1ABC9C no-repeat center",
    img: atendida,
  },
  atrasada: {
    bg: "#FF4E50 no-repeat center",
    img: nao,
    size: "60%",
    cursor: 'pointer',
  },
  parAtendido: {
    bg: "#F39C12 no-repeat center",
    img: box2,
    size: "60%",
    cursor: 'pointer',
  },
};

Container.defaultProps = {
  themes: {
    bg: "#ccc no-repeat center",
    size: "60%",
    cursor: "default",
  },
};

BtnStatus.defaultProps = {
  themes: {
    bg: "#ccc no-repeat center",
    size: "60%",
    cursor: "default",
  },
};

Icon.defaultProps = {
  themes: {
    bg: "#ccc no-repeat center",
    size: "60%",
    cursor: "default",
  },
};
