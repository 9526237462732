import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

export default function RoutePrivate({
  comp: Component,
  authed,
  title,
  ...rest
}) {
  const location = useLocation();
  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: title,
      page_path: location.pathname + location.search,
      page_location: window.location.href,
    });
  }, [location, title]);
  return (
    <Route
      {...rest}
      render={(props) =>
        !authed ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
}
