import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import api from "../../../../services/api";
import { toast } from "react-toastify";

// Components
import Header from "../../../../components/Header";
import AsideMenu from "../../../../components/AsideMenu";
import LoadPage from "../../../../components/LoadPage";
import BtnBack from "../../../../components/BtnBack";
import Text from "../../../../components/Text";

//Images
import iconTit from "../../../../assets/icons/icon-campaing.svg";
import iBom from "../../../../assets/icons/icon-justification.svg";

//CSS
import * as Ss from "../../../../styles/style";
import * as S from "../styles";

export default function New({ history }) {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const msgsuccess = () => toast.success("Justificativa cadastrada!");

  async function onSubmit(data, e) {
    await api
      .post(`justification-n`, {
        name: data.name,
        description: data.description || null,
      })
      .then((result) => {
        msgsuccess();
        e.target.reset();
        history.push(`/configuracoes/justificativa/`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <Ss.Center>
        <Ss.TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </Ss.TopHeader>

        <S.Container>
          <AsideMenu />
          <S.Content>
            <header className="content">
              <h2>
                <img src={iBom} alt="" />
                <Text id="justificativa" dm="Justificativa" />
              </h2>
            </header>

            {loading ? (
              <LoadPage />
            ) : (
              <Ss.Form onSubmit={handleSubmit(onSubmit)}>
                <Ss.DescriptionTitle>
                  <Text
                    id="info.justificativa"
                    dm="Informações da justificativa"
                  />
                </Ss.DescriptionTitle>
                <Ss.BoxForm>
                  <Ss.Row>
                    <Ss.InputBox>
                      <label htmlFor="">
                        <Text id="justificativa" dm="Justificativa" />
                      </label>
                      <input name="name" ref={register({ required: true })} />
                      {errors.name && <S.Err>Digite a justificativa</S.Err>}
                    </Ss.InputBox>
                  </Ss.Row>
                  <Ss.Row>
                    <Ss.InputBox>
                      <label htmlFor="">
                        <Text id="descricao" dm="Descrição" />
                      </label>
                      <input name="description" ref={register()} />
                      {errors.description && <S.Err>Digite a descrição</S.Err>}
                    </Ss.InputBox>
                  </Ss.Row>
                </Ss.BoxForm>

                <Ss.Btns>
                  <Ss.Button
                    className="cancel"
                    type="reset"
                    onClick={history.goBack}
                  >
                    <Text id="cancelar" dm="Cancelar" />
                  </Ss.Button>
                  <Ss.Button type="submit">
                    <Text id="salvar" dm="Salvar" />
                  </Ss.Button>
                </Ss.Btns>
              </Ss.Form>
            )}
          </S.Content>
        </S.Container>
      </Ss.Center>
    </>
  );
}
