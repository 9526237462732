import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import NumberFormat from "react-number-format";
import api from "../../../services/api";

// Components
import Header from "../../../components/Header";
import AsideMenu from "../../../components/AsideMenu";

import BtnBack from "../../../components/BtnBack";
import Text from "../../../components/Text";

//Images
import iconTit from "../../../assets/icons/icon-campaing.svg";
import iconToller from "../../../assets/icons/icon-toller.svg";
import iSearch from "../../../assets/icons/icon-search.svg";

//CSS
import { Center, TopHeader } from "../../../styles/style";
import {
  Container,
  Content,
  FilterConfig,
  ProductContainer,
  HeaderList,
  ItemList,
  Loading,
} from "./styles";

const cssloading = css`
  display: flex;
  margin: 40px auto;
  justify-content: center;
`;

export default function Toller() {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  let history = useHistory();

  useEffect(() => {
    async function feachProduct() {
      await api
        .get(`/branch-n?perPage=10000`)
        .then((result) => {
          setProducts(result.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    feachProduct();
  }, []);

  const results = !search
    ? products
    : products.filter(
        (e) =>
          e.company_name.toLowerCase().includes(search.toLocaleLowerCase()) ||
          e.cnpj.toLowerCase().includes(search.toLocaleLowerCase())
      );

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>
        <Container>
          <AsideMenu />

          <Content>
            <header>
              <h2>
                <img src={iconToller} alt="" />
                Toller
              </h2>
              <FilterConfig>
                <img src={iSearch} alt="" />
                <input
                  placeholder="Buscar toller"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FilterConfig>
            </header>

            <ProductContainer>
              <HeaderList>
                <p>CNPJ</p>
                <p>
                  <Text id="nome" dm="Nome" />
                </p>
                <p>
                  <Text id="config.title" dm="Telefone" />
                </p>
                <p>
                  <Text id="config.title" dm="Endereço" />
                </p>
                <p>
                  <Text id="config.title" dm="Cidade" />
                </p>
                <p>
                  <Text id="config.title" dm="Estado" />
                </p>
              </HeaderList>

              {loading ? (
                <Loading
                  css={cssloading}
                  sizeUnit={"px"}
                  size={10}
                  height={2}
                  color={"#0459a9"}
                />
              ) : (
                <>
                  {results.map((item) => (
                    <ItemList
                      key={item.id}
                      //   onClick={() => {
                      //     history.push(`produtos/${item.id}`);
                      //   }}
                    >
                      <NumberFormat
                        value={item.cnpj ? item.cnpj : "-"}
                        displayType={"text"}
                        format="##.###.###/####-##"
                      />
                      <p>{item.company_name ? item.company_name : "-"}</p>
                      <p>{item.telephone ? item.telephone : "-"}</p>
                      <p>{item.adress ? item.adress : "-"}</p>
                      <p>{item.city ? item.city : "-"}</p>
                      <p>{item.state ? item.state : "-"}</p>
                    </ItemList>
                  ))}
                </>
              )}
            </ProductContainer>
          </Content>
        </Container>
      </Center>
    </>
  );
}
