import { call, put, select, take, fork } from "redux-saga/effects";
import { END, eventChannel } from "redux-saga";
import { notify } from "../../../services/notification";
import api, { makeOptions } from "../../../services/api";
import ActionsBranchRawInvoiceCreate from "../../ducks/branch-raw-invoices/create";

const getAuthData = (state) => state.auth.data;

function createChannel(postData, authData) {
  let emitter;

  const url = "branch-raw-invoice";

  const event = eventChannel((emit) => {
    emitter = emit;
    return () => {};
  });

  const promise = new Promise((resolve, reject) => {
    api
    .post(url, postData, {
      onUploadProgress: emitter,
      ...makeOptions(authData),
    })
    .then((data) => {
        emitter({ loaded: 100, total: 100 });
        setTimeout(() => {
          emitter(END);
          resolve(data.data);
        }, 3000);
      })
      .catch((err) => {
        reject(err);
        emitter(END);
      });
  });

  return [promise, event];
}

function* onProgress(channel) {
  while (true) {
    const progress = yield take(channel);
    const loaded = (progress.loaded * 100) / progress.total;
    yield put(ActionsBranchRawInvoiceCreate.load(Math.floor(loaded)));
  }
}

export function* create(action) {
  try {
    const { data: postData, onSuccess } = action;

    const authData = yield select(getAuthData);
    const [promise, event] = createChannel(postData, authData);

    yield fork(onProgress, event);
    const { data } = yield call(() => promise);
    onSuccess && onSuccess();
    yield put(ActionsBranchRawInvoiceCreate.success(data));
    yield put(ActionsBranchRawInvoiceCreate.load(0));
    notify("success", "Arquivo cadastrado com sucesso!");
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ActionsBranchRawInvoiceCreate.failure(err.response.data.error.message)
    );
  }
}
