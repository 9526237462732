import styled from "styled-components";

export const Container = styled.button`
  box-sizing: border-box;
  height: 40px;
  border-radius: 6px;
  padding: 0 10px;
  background: #f9f9f9;
  transition: all ease 0.2s;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  span {
    position: absolute;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-200%);
    transition: all ease 0.2s;
    margin-left: -10px;
  }
  img {
    height: 25px;
    transition: all ease 0.2s;
  }
  p {
    font-size: 12px;
    line-height: 40px;
    transition: all ease 0.4s;
    transform: translateY(0%);
    color: #999;
  }
  &:hover {
    background: #408c44;
    span {
      transform: translateY(0);
    }
    p {
      transform: translateY(200%);
      opacity: 1;
    }
  }
  @media (max-width: 520px) {
    display: none;
  }
`;
