import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import AuthActions from "../../store/ducks/auth";

import { login } from "../../services/auth";

import logoLogin from "../../assets/logo.png";

import {
  Container,
  ContentLogin,
  Form,
  Button,
  Erro,
  ErroMsg,
  LoadingBtn,
} from "./styles";

export default function Login({ history }) {
  const dispatch = useDispatch();
  const [erroMsg, setErroMsg] = useState({
    check: false,
    msg: "",
  });
  const [txtBtn, setTxtBtn] = useState(true);
  const { register, handleSubmit, errors } = useForm();

  const { loading, error } = useSelector((state) => state.auth);

  const onSubmit = useCallback(
    (data) => {
      try {
        setTxtBtn(false);
        setErroMsg(false);

        dispatch(
          AuthActions.request(
            {
              email: data.email,
              password: data.passwd,
            },
            history,
            login
          )
        );
      } catch (error) {
        setErroMsg({ check: true, msg: "E-mail ou senha inválida!" });
        setTxtBtn(true);
      }
    },
    [dispatch, history]
  );

  // const onSubmit = async (data) => {
  //   try {
  //     setTxtBtn(false);
  //     setErroMsg(false);
  //     setLoadingbtn(true);
  //     const response = await api.post(`login`, {
  //       email: data.email,
  //       password: data.passwd,
  //     });
  //     login(response.data.token);
  //     history.push("/");
  //   } catch (err) {
  //     if (err === 500) {
  //       setErroMsg({ check: true, msg: err.response.data.error.message });
  //       setTxtBtn(true);
  //       setLoadingbtn(false);
  //     } else {
  //       setErroMsg({ check: true, msg: "E-mail ou senha inválida!" });
  //       setTxtBtn(true);
  //       setLoadingbtn(false);
  //       console.log(err);
  //     }
  //   }
  // };
  return (
    <Container>
      <ContentLogin>
        <img src={logoLogin} alt="" />
        <div className="box-login">
          <p className="tit">Módulo Toller</p>
          <p>Bem vindo! Digite seus dados de acesso.</p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="email"
              name="email"
              ref={register({ required: true })}
              placeholder="E-mail"
            />
            {errors.email && <Erro>Digite o e-mail</Erro>}

            <input
              type="password"
              name="passwd"
              ref={register({ required: true })}
              placeholder="Senha"
            />
            {errors.passwd && <Erro>Digite sua senha</Erro>}

            <Button type="submit">
              {txtBtn && "Entrar"}

              <LoadingBtn
                marginTop={"10px"}
                sizeUnit={"px"}
                size={10}
                height={2}
                color={"#fff"}
                loading={loading}
              />
            </Button>
            {(erroMsg.check || error) && <ErroMsg>{erroMsg.msg ?? error}</ErroMsg>}
          </Form>
        </div>
      </ContentLogin>
    </Container>
  );
}
