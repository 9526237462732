import { call, put, select } from "redux-saga/effects";
import { notify } from "../../../services/notification";
import api, { makeOptions, queryBuilder } from "../../../services/api";
import ActionsCompareApproved from "../../ducks/compare/compare-approved";

const getAuthData = (state) => state.auth.data;

export function* fetch(action) {
  try {
    const { params, onSuccess } = action;
    const query = params ? queryBuilder(params) : "";
    const url = `bom-compare?${query}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.get, url, makeOptions(authData));

    yield put(ActionsCompareApproved.success(data));

    onSuccess && onSuccess(data);
    
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(ActionsCompareApproved.failure(err.response.data.error.message));
  }
}
