import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import format from 'date-fns/format';
import * as S from './styles';

const DownloadXLS = (Orders) => {
  const [contentConsume, setContentConsume] = useState([]);
  
  const prepareDownload = useCallback(() => {
    //CABEÇALHO INICIAL
    const headers = [
      'Campanha',
      'NF',
      'Tipo de Operacao',
      'Descricao',
      'Codigo Corteva',
      'Emissao',
      'Qtd.',
      'Un. Medida Prod.',
      'Un. Medida SAP',
      'Qtd. Consumida',
      'Diferenca SAP x NF',
      'Justificativa NF x SAP',
      'Data de Consumo',
      'Obs'
    ];

    const campaignBody = [headers]
    
    if(Orders.orders){
      Orders.orders.forEach((value)=>{
        value.branch_invoices?.length ? 
        value.branch_invoices.forEach((item)=>{
          let itemUnit = item.item?.unit;
          let sapUnit = item.bomSap[0]?.unit;
          let itemQuantity = item.item?.quantity;
          let sapQuantity = item.bomSap[0]?.quantity;
          let productDescription = item.product?.name;
          let bomSap = item.bomSap.length ? item.bomSap : false;
          let rawNumberInvoice = item.branch_raw_invoice[0]?.number;
          let rawDateInvoice = item.branch_raw_invoice[0]?.emission_date;

          //SUBCABEÇALHO INFORMAÇÕES PRODUTO ACABADO
          let dataInit = [
            value.alfa_id,
            item.number,
            'produto acabado',
            productDescription,
            item.product?.code,
            new Date(item.emission_date).toLocaleDateString(),
            itemQuantity,
            itemUnit,
            sapUnit,
            sapQuantity,
            '',
            '',
            '',
            '',
          ];
          campaignBody.push(dataInit)

          bomSap ? item.invoiceItems.forEach((invoiceitem)=>{
            let obs = '';
            let diff = 0;
            let unitSap = '';
            let isSearch = false;
            let productCode = '';
            let quantityItem = 0;
            let quantityItemSap = 0;
            let invoiceItemUnit = '';
            let productNameItem = '';
            let justificationItem = '';
            let consumeDate = bomSap[bomSap.length-1].launch_date;

            invoiceItemUnit = invoiceitem.unit;
            quantityItem = invoiceitem.quantity;
            productCode = invoiceitem.product?.code;
            productNameItem = invoiceitem.product?.name;
            justificationItem = invoiceitem.justification[0]?.type === 'SAP' ?
              invoiceitem.justification[0].justification : '';

            bomSap[bomSap.length-1].items.filter((content)=>{
              if (content.product_id === invoiceitem.product_id){
                quantityItemSap = content.quantity;
                diff = quantityItem + (quantityItemSap);
                unitSap = content.unit;
                isSearch = true;
              }
            });
            isSearch ? obs = '' : obs = 'Sem item SAP';

            //INFORMAÇÕES PLANILHA SAP E MATERIA PRIMA
            let dataItem = [
              value.alfa_id,
              rawNumberInvoice,
              'materia prima',
              productNameItem,
              productCode,
              new Date(rawDateInvoice).toLocaleDateString(),
              quantityItem.toFixed(2),
              invoiceItemUnit,
              unitSap,
              quantityItemSap.toFixed(2),
              diff.toFixed(2),
              justificationItem,
              new Date(consumeDate).toLocaleDateString(),
              obs,
            ]
            campaignBody.push(dataItem);
          }) : campaignBody.push([value.alfa_id,item.number,'','','','','','','','','','','','Sem planilha SAP']);
        }) : campaignBody.push([value.alfa_id,'','','','','','','','','','','','','Sem Consumo']);
      })
      setContentConsume(campaignBody);
    }
  },[Orders]);


useEffect(()=>{
  prepareDownload();
}, [Orders,prepareDownload]);


  return (
    <>
      <CSVLink
        data={contentConsume}
        separator={';'}
        filename={`RELATORIO_CAMPANHA_${format(new Date(),"dd-MM-yyyy")}.csv`}
      >
        <S.ButtonExport>Exportar Excel</S.ButtonExport>
      </CSVLink>
    </>
  )
}

export default DownloadXLS