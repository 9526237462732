import React from "react";

// Components
import Header from "../../components/Header";
import BtnBack from "../../components/BtnBack";
import Text from "../../components/Text";

//Images
import iconTit from "../../assets/icons/icon-campaing.svg";

//CSS
import { Center, TopHeader } from "../../styles/style";
import { Container } from "./styles";

export default function Manual({ history }) {
  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="manual" dm="Manual do Sistema" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>
        <Container>
          <iframe
            width="100%"
            height="600"
            src="https://docs.google.com/document/d/e/2PACX-1vQHqTJ-eWH7SC6EusNqfg_Smn0_ZsPnH1ZnJOkFP0m7LTFCLkxOB_PZ82kxaYJ9hw/pub?embedded=true"
          />
        </Container>
      </Center>
    </>
  );
}
