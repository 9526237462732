import { ArrowRight2 } from '@styled-icons/icomoon/ArrowRight2';
import styled from "styled-components";

export const ArrowButton = styled(ArrowRight2).attrs({
    size: 50,
  })`
    padding: 8px;
    color: #292D41;
    border-radius: 100%;
    box-shadow: -2px 3px 5px #b2b2b2;
    transition: 300ms ease;
    position: absolute;
    right: 65px;

    :hover{
      cursor: pointer;
      transform: scale(0.95);
    }
  
    &.anime-shadow {
      animation-name: shadowMove;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
  
      @keyframes shadowMove {
        0% { box-shadow: -2px 3px 5px #b2b2b2 }
        25% { box-shadow: 0px 4px 5px #b2b2b2 }
        50% { box-shadow: 2px 0px 5px #b2b2b2 }
        75% { box-shadow: 0px -4px 5px #b2b2b2 }
        100% { box-shadow: -2px 3px 5px #b2b2b2 }
      }
    }
    &#noNext{
      color: #D9D9D9;
    }
  
  `;
  