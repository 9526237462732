import React from 'react';

import * as S from './styles';

const ItemSapApproved = ({ data }) => {
  return (
    <S.Container>
      <S.Item>
        <S.Value>
          {data?.product_sap_description || data?.product?.name}
        </S.Value>
        <S.Value>UN: {data?.unit}</S.Value>
        <S.Value>NF: {data?.invoice_quantity}</S.Value>
        <S.Value>SAP: {data?.sap_quantity || '---'}</S.Value>
        <S.Value className={`${data?.diff_invoice_sap !== '0.000' && 'diff'}`}>
          DIF:{' '}
          {data?.diff_invoice_sap !== null ? data?.diff_invoice_sap : '---'}
        </S.Value>
      </S.Item>
      {data?.justification_sap && (
        <S.Justificantion>
          <S.JustificantionText>{data.justification_sap}</S.JustificantionText>
        </S.Justificantion>
      )}
    </S.Container>
  );
};

export default ItemSapApproved;
