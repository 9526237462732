import React from "react";
import { Link } from "react-router-dom";

import Text from "../Text";

//Links
import { asideLinks } from "../../content";

// Css
import * as S from "./styles";

export default function AsideMenu() {
  return (
    <S.Container>
      {asideLinks.map((link, i) => (
        <Link to={link.url} key={i}>
          <span>
            <img src={link.img} alt="" />
            <Text id={link.txt} dm={link.label} />
          </span>
        </Link>
      ))}
    </S.Container>
  );
}
