import React, { useState, useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useValidation } from "../../../../hooks";

import ActionsCompareCreateJustification from "../../../../store/ducks/compare/create-justification";

import * as S from "./styles";
import { Textarea } from "../../../shared";

const CreateJustification = ({ selectedItem, setSelectedItem, onCreated }) => {
  const [open, setOpen] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { handleFormErrors } = useValidation();
  const { loading: loadingCreateJustification } = useSelector(
    (state) => state.compareCreateJustification
  );

  const onSuccess = useCallback(
    ({ reset }, refresh) => {
      reset();
      setSelectedItem(null);
      refresh();
      setOpen(false);
    },
    [setSelectedItem]
  );

  const onClose = useCallback(() => {
    setSelectedItem(null);
    setOpen(false);
  }, [setSelectedItem]);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          justification: Yup.string().required("Digite sua justificativa"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const post = {
          branch_invoice_item_ids: selectedItem.invoice_item_ids
            ? selectedItem.invoice_item_ids
            : [selectedItem.invoice_item_id],
          justification: data.justification,
          type: selectedItem.type,
        };
        dispatch(
          ActionsCompareCreateJustification.request(post, () =>
            onSuccess({ reset }, onCreated)
          )
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onCreated, onSuccess, selectedItem]
  );

  useEffect(() => {
    if (selectedItem) return setOpen(true);
  }, [selectedItem]);

  return (
    <S.Container open={open}>
      <S.Box>
        <S.Head>
          <S.Title>
            <S.IconFile />
            Justificativa
          </S.Title>
          <S.ProductName>
            {selectedItem?.type === "SAP"
              ? selectedItem?.sap_name || selectedItem?.invoice_name
              : selectedItem?.bom_name || selectedItem?.invoice_name}
          </S.ProductName>
        </S.Head>
        <S.ItemInfo>
          <S.GroupValues>
            <S.Value>NF: {selectedItem?.invoice_quantity || "---"}</S.Value>
            {selectedItem?.type === "BOM" && (
              <S.Value>BOM: {selectedItem?.bom_quantity || "---"}</S.Value>
            )}
            {selectedItem?.type === "SAP" && (
              <S.Value>SAP: {selectedItem?.sap_quantity || "---"}</S.Value>
            )}
            <S.Value className="diff">
              DIF: {selectedItem?.diff !== null ? selectedItem?.diff : "---"}
            </S.Value>
          </S.GroupValues>
        </S.ItemInfo>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.WrapInputs>
            <Textarea
              name="justification"
              placeholder="Digite aqui sua justificativa …"
            />
          </S.WrapInputs>
          <S.WrapButtons>
            <S.Button btStyle="cancel" type="button" onClick={onClose}>
              Voltar
            </S.Button>
            <S.Button btStyle="primary" type="submit">
              {loadingCreateJustification ? <S.OnLoading /> : "Salvar"}
            </S.Button>
          </S.WrapButtons>
        </Form>
      </S.Box>
    </S.Container>
  );
};

export default CreateJustification;
