import React, { useState, useEffect } from "react";
import api from "../../services/api";

// Components
import Header from "../../components/Header";
import BtnBack from "../../components/BtnBack";
import LoadPage from "../../components/LoadPage";
import Text from "../../components/Text";

//Images
import iconTit from "../../assets/icons/icon-alert.svg";

//CSS
import * as Ss from "../../styles/style";
import * as S from "./styles";

function Alerts({ history }) {
  const [alert, setAlerts] = useState();
  const [loading, setLoading] = useState(true);
  async function loadAlerts() {
    setLoading(true);
    await api
      .get(`/alert-n`)
      .then((result) => {
        setAlerts(result.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadAlerts();
  }, []);

  return (
    <>
      <Header />
      <Ss.Center>
        <Ss.TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="alertas" dm="Alertas" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </Ss.TopHeader>
        <S.Container>
          <S.HeaderList>
            <p>
              <Text id="codigo" dm="Código" />
            </p>
            <p>
              <Text id="nome" dm="Nome" />
            </p>
            <p>
              <Text id="descricao" dm="Descrição" />
            </p>
          </S.HeaderList>
          <S.List>
            {loading ? (
              <LoadPage />
            ) : (
              <>
                {alert.map((item) => (
                  <S.ItemList
                    onClick={() => {
                      history.push(`/alertas/${item.id}`);
                    }}
                    key={item.id}
                  >
                    <p>{item.id}</p>
                    <p>{item.name}</p>
                    <p>{item.description}</p>
                  </S.ItemList>
                ))}
              </>
            )}
          </S.List>
        </S.Container>
      </Ss.Center>
    </>
  );
}

export default Alerts;
