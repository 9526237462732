import React from 'react';
import { css } from "@emotion/core";

import  {BeatLoader}  from "react-spinners";

const cssloading = css`
  display: flex;
  margin: 40px auto;
  justify-content: center;
`;

 const  LoadPage = () =>  (

    <BeatLoader
        css={cssloading}
        sizeUnit={"px"}
        size={10}
        height={2}
        color={"#0459a9"} 
    />
  )

  export default LoadPage
