import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import NextButton from "../../../components/NextButton";

import ActionsCompareApproved from "../../../store/ducks/compare/compare-approved";
import ActionsCompareCampaign from "../../../store/ducks/compare/compare-campaign";

import * as S from "./styles";

import Header from "../../../components/Header";

import { ItemBomApproved, ItemSapApproved } from "../../../components/Consume";
import Text from '../../../components/Text';

const CompareApproved = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { invoice, branch, product } = useParams();

  const { data: dataCompare, loading: compareLoading } = useSelector(
    (state) => state.compareApproved
  );

  const { data: compareData, loading } = useSelector(
    (state) => state.compareCampaign
  );

  const getDataCompare = useCallback((data) => {
    if(data?.branchInvoice?.order_id){
      dispatch(ActionsCompareCampaign.request(data?.branchInvoice?.order_id));
    }
  }, [dispatch, ActionsCompareCampaign]);

  const fetchData = useCallback(() => {
    dispatch(
      ActionsCompareApproved.request({
        branch_invoice_id: invoice,
        branch_id: branch,
        product_id: product,
      }, getDataCompare)
    );
  }, [branch, dispatch, invoice, product, getDataCompare]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <S.Container>
      <Header noMargin />
      <S.InnerHeader>
        <S.Wrapper>
          <S.Title>
            <S.IconConsume />
            Consumo {compareLoading && <S.Loading />}
          </S.Title>
          <S.DivContainerCompare>
            <S.Box>
              <S.Item>
                <S.Value>{compareData?.totalQuantityCampaign}</S.Value>
                <S.Label><Text id="totalAProduzir" dm="Total a produzir" /></S.Label>
              </S.Item>
              <S.Item>
                <S.Value>{compareData?.totalQuantityConcluido}</S.Value>
                <S.Label><Text id="produzido" dm="Produzido" /></S.Label>
              </S.Item>
              <S.Item>
                <S.Value color="#FF006C">
                  {compareData?.percentFinalProduction > 0 ? compareData?.percentFinalProduction : '0'}%
                </S.Value>
                <S.Label><Text id="pendenteDeExpedicao" dm="Pendente de Expedição" /></S.Label>
              </S.Item>
              <S.Item>
                <S.Value color="#FF006C">
                  {compareData?.percentFinalPendenteConsumo > 0 ? compareData?.percentFinalPendenteConsumo : '0'}%
                </S.Value>
                <S.Label><Text id="pendentedeConsumo" dm="Pendente de Consumo" /></S.Label>
              </S.Item>
            </S.Box>
          </S.DivContainerCompare>
          <S.DivGhost>
          </S.DivGhost>
        </S.Wrapper>
      </S.InnerHeader>
      <S.Content>
        {dataCompare && (
          <>
            <S.Wrapper>
              <S.NameProduct>
                <span>NF {dataCompare?.branchInvoice?.number} - </span>
                <span>{dataCompare?.productFinished?.product_code}</span>
                {dataCompare?.productFinished?.product_description}
              </S.NameProduct>
              <S.QtyProduct>
                QTD. {dataCompare?.productFinished?.quantity}
              </S.QtyProduct>
            </S.Wrapper>
            <S.WrapperPending className="bg">
              <S.Pending>
                <S.CompareTitle>Nota Fiscal x B.O.M </S.CompareTitle>
                {dataCompare?.branchInvoiceFinish?.map((item) => (
                  <ItemBomApproved key={item.invoice_item_id} data={item} />
                ))}
              </S.Pending>
              <S.Pending>
                <S.CompareTitle>Nota Fiscal x SAP</S.CompareTitle>
                {dataCompare?.branchInvoiceFinish?.map((item) => (
                  <ItemSapApproved key={item.invoice_item_id} data={item} />
                ))}
              </S.Pending>
            </S.WrapperPending>
            <S.Wrapper>
              <S.NameProduct>
                <span>NF {dataCompare?.branchInvoice?.number} - </span>
                <span>{dataCompare?.productFinished?.product_code}</span>
                {dataCompare?.productFinished?.product_description}
              </S.NameProduct>
              <S.QtyProduct>
                QTD. {dataCompare?.productFinished?.quantity}
              </S.QtyProduct>
            </S.Wrapper>
          </>
        )}
        <S.WrapperButtons>
          <S.Button btStyle="cancel" onClick={() => history.push("/consumo")}>
            voltar
          </S.Button>
          <NextButton Loading={compareLoading}/>
        </S.WrapperButtons>
      </S.Content>
    </S.Container>
  );
};

export default CompareApproved;
