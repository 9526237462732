/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

// Components
import Header from "../../components/Header";
import BtnBack from "../../components/BtnBack";
import Text from "../../components/Text";

//Images
import iconTit from "../../assets/icons/icon-campaing.svg";

//CSS
import { Center } from "../../styles/style";
import * as S from "./styles";

export default function Manual({ history }) {
  return (
    <>
      <Header />
      <Center>
        <S.HeaderPanel>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="menu.dashboard" dm="Dashboard" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </S.HeaderPanel>
        <S.Container>
          <iframe
            width="100%"
            height="800"
            src="https://analytics.zoho.com/open-view/1432280000074593413/c73729f69d84bb305e46ae693e838488"
          />
        </S.Container>
      </Center>
    </>
  );
}
