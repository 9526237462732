import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import Select from "react-select";

// Components
import Text from "../../../components/Text";

//CSS
import {
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  ButtonDelete,
  Button,
  MaterialList,
  MaterialItem,
} from "../../../styles/style";
import { ErrFloat, LoadingBtn } from "../styles";

const defaultValues = {
  bom: "",
};

export default function AddProducts({
  onSubmit,
  materialProduct,
  id,
  list,
  deleteProduct,
}) {
  const { register, handleSubmit, errors, control, reset } = useForm({
    defaultValues,
  });

  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [produse, setProduse] = useState("");

  async function addBom(data, e) {
    setLoadingBtn(true);
    setTxtBtn(false);
    await onSubmit({
      order_id: id,
      bom_id: data.bom.id,
      quantity: data.qtd,
    }).then((result) => {
      setProduse("");
      e.target.reset();
      setLoadingBtn(false);
      setTxtBtn(true);
      reset(defaultValues);
    });
  }

  async function deleteBom(data) {
    withSwalInstance(
      swal
        .fire({
          title: "Você tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1ABC9C",
          cancelButtonColor: "#FF4E50",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim, pode deletar!",
        })
        .then(async (result) => {
          if (result.value) {
            await deleteProduct({
              id: data,
            });
            //   swal.fire(
            //     'Excluído!',
            //     'Produto foi excluído',
            //     'success'
            //   )
          }
        })
    );
  }

  return (
    <>
      <Form className="mt" onSubmit={handleSubmit(addBom)}>
        <DescriptionTitle>
          <Text id="producao" dm="Produção" />
        </DescriptionTitle>

        <BoxForm>
          <Row className="r311">
            <InputBox>
              <label htmlFor="">
                <Text id="produto" dm="Produto" />
              </label>
              <Controller
                as={
                  <Select
                    className="basic-single"
                    isClearable
                    placeholder="Selecione o BOM"
                    value={produse}
                    options={materialProduct.filter(item => item.core_bom_product)}
                    getOptionLabel={({ core_bom_product }) =>
                      `${core_bom_product.product.code} - ${core_bom_product.product.name}`
                    }
                    getOptionValue={({ id }) => id}
                  />
                }
                control={control}
                name="bom"
                rules={{ required: true }}
                defaultValue={produse}
              />
              {errors.materia && <ErrFloat>Selecione o produto</ErrFloat>}
            </InputBox>
            <InputBox>
              <label htmlFor="">
                <Text id="quantidade" dm="Quantidade" />
              </label>
              <input
                name="qtd"
                autoComplete="off"
                ref={register({ required: true })}
              />
              {errors.qtd && <ErrFloat>Digite a quantidade</ErrFloat>}
            </InputBox>
            <InputBox>
              <Button>
                {txtBtn && <Text id="adicionar" dm="Adicionar" />}
                <LoadingBtn
                  marginTop={"10px"}
                  sizeUnit={"px"}
                  size={10}
                  height={2}
                  color={"#fff"}
                  loading={loadingBtn}
                />
              </Button>
            </InputBox>
          </Row>
        </BoxForm>

        {list.length === 0 ? (
          ""
        ) : (
          <MaterialList>
            <header>
              <p>
                <Text id="codigo" dm="Código" />
              </p>
              <p>
                <Text id="produto" dm="Produto" />
              </p>
              <p>
                <Text id="quantidade" dm="Quantidade" />
              </p>
              <p></p>
            </header>

            {list.map((item, index) => (
              <MaterialItem key={item.id}>
                <p>{item.bom.core_bom_product.product.code}</p>
                <p>{item.bom.core_bom_product.product.name}</p>
                <p>{item.quantity}</p>
                <ButtonDelete
                  type="button"
                  onClick={() => deleteBom(item.id)}
                />
              </MaterialItem>
            ))}
          </MaterialList>
        )}
      </Form>
    </>
  );
}
