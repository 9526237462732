import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../../../services/api";

// Components
import Header from "../../../components/Header";
import AsideMenu from "../../../components/AsideMenu";
import BtnAdd from "../../../components/BtnAdd";
import LoadPage from "../../../components/LoadPage";
import BtnBack from "../../../components/BtnBack";
import Text from "../../../components/Text";

//Images
import iconTit from "../../../assets/icons/icon-campaing.svg";
import iProduct from "../../../assets/icons/icon-products.svg";
import iSearch from "../../../assets/icons/icon-search.svg";

//CSS
import { Center, TopHeader } from "../../../styles/style";
import * as S from "./styles";

export default function GroupSimilar() {
  const [search, setSearch] = useState("");
  const [similars, setSimilars] = useState([]);
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  async function feachJustify() {
    await api
      .get(`group-similar?perPage=false`)
      .then((result) => {
        setSimilars(result.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    feachJustify();
  }, []);

  const results = !search
    ? similars
    : similars.filter((e) =>
        e.name.toLowerCase().includes(search.toLocaleLowerCase())
      );

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={() => history.push('/configuracoes')} />
        </TopHeader>
        <S.Container>
          <AsideMenu />

          <S.Content>
            <header>
              <h2>
                <img src={iProduct} alt="" />
                <Text id="grupo_similar" dm="Grupo Similar" />
              </h2>

              <S.FilterConfig>
                <img src={iSearch} alt="" />
                <input
                  placeholder="Buscar grupo similar"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </S.FilterConfig>

              <Link to="/configuracoes/grupo-similar/novo">
                <BtnAdd />
              </Link>
            </header>


            {loading ? (
              <LoadPage />
            ) : (
              <S.BomContainer>
                <S.HeaderList>
                  <p>
                    <Text id="codigo" dm="Código" />
                  </p>
                  <p>
                    <Text id="descricao" dm="Descrição" />
                  </p>                
                </S.HeaderList>

                {results && results[0] && results.map((similar) => (
                  <S.ItemList
                    key={similar.id}
                    onClick={() => {
                      history.push(`/configuracoes/grupo-similar/${similar.id}`);
                    }}
                  >
                    <p>{similar.id}</p>
                    <p>{similar.name}</p>
                  </S.ItemList>
                  ))}
                </S.BomContainer>
              )}
          </S.Content>
        </S.Container>
      </Center>
    </>
  );
}
