import ReactTooltip from 'react-tooltip';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from './styles';

import ActionsBranchInvoiceList from '../../store/ducks/branch-invoices/list'

const NextButton = ({ Loading }) => {
  let idLists = JSON.parse(localStorage.getItem("idList"));
  let idSelect = JSON.parse(localStorage.getItem('@idxSelected'));
  let localFilters = JSON.parse(localStorage.getItem('filter'));
  let localSearch = JSON.parse(localStorage.getItem('search'));
  const initalSearch = null;
  const dispatch = useDispatch();
  const history = useHistory();

  const didMount = useRef(false);
  
  const initalFilter = {
    status: null,
    startDate: null,
    endDate: null,
  };

  const [search, setSearch] = useState(localSearch ? localSearch : initalSearch);
  const [filter, setFilter] = useState(localFilters ? localFilters : initalFilter);  
  const [idList, setIdList] = useState(idLists);
  const [idxSelected, setIdxSelected] = useState(idSelect ? idSelect : 0);
  const [ isNext, setIsNext ] = useState(idLists ? true : false);

  const { data: branchInvoiceData, loading, pagination } = useSelector(
    (state) => state.branchInvoices
  );
  const [ currentPage , setCurrentPage] = useState(pagination?.page || 1)

  const fecthBranchInvoice = useCallback(() => {
    let nextPage = currentPage + 1;
    dispatch(
      ActionsBranchInvoiceList.request({ page: nextPage, search, ...filter })
    );
  }, [dispatch, currentPage, search, filter, loading]);
  
  const nextNavigate = () => {
    if(isNext){
      setIdxSelected(idxSelected + 1);
    }
  }

  const navigate = (index) => {
    if(isNext){
      if(idList[index]?.finished_at){
        return history.push(
          `/consumo-aprovado/${idList[index]?.id}/${idList[index]?.branch_id}/${idList[index]?.product_id}`
        );
      }
      return history.push(
        `/consumo/${idList[index]?.id}/${idList[index]?.branch_id}/${idList[index]?.product_id}`
      );
    }
  }

  const nextIdList = useCallback((data) => {
    const newIdList = [];
    if(data){
      data.forEach((item)=>{
        let { id, branch_id, product_id, finished_at } = item;
          newIdList.push({
            'id': id,
            'branch_id': branch_id,
            'product_id': product_id,
            'finished_at': finished_at
          })
      })
      setIdList(newIdList)
    }
  },[branchInvoiceData, loading])
  
  useEffect(()=>{
    if(idxSelected > 9){
      nextIdList(branchInvoiceData)
    }
  }, [branchInvoiceData])

  useEffect(()=>{
    if(idxSelected > 9){
      localStorage.setItem('idList', JSON.stringify(idList))
      setIdxSelected(0)
    }
  }, [idList])
  
  useEffect(()=>{
    if(didMount.current){
      if(idxSelected <= 9){
        localStorage.setItem('@idxSelected',JSON.stringify(idxSelected))
        navigate(idxSelected)
      }
      else{
        fecthBranchInvoice()
      }
    }
    else{
      didMount.current = true;
    }
  }, [idxSelected])
  
  useEffect(()=>{
    if(idList.length <= 1){
      setIsNext(false)
    }
  },[])

  return (
    <>
      <S.ArrowButton onClick={nextNavigate}
          className={Loading ? 'anime-shadow' : ''}
          id={isNext ? '' : 'noNext'}
          data-tip data-for="ArrowButton"
        >
          <span className='tooltip-text'>Avançar</span>
        </S.ArrowButton>
        <ReactTooltip id="ArrowButton" place="top" effect="solid" delayShow='1000'>
          Próximo Consumo
        </ReactTooltip>
    </>
  )
}

export default NextButton;