import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import Select from "react-select";

// Components
import Header from "../../../../components/Header";
import AsideMenu from "../../../../components/AsideMenu";
import BtnBack from "../../../../components/BtnBack";
import Text from "../../../../components/Text";

//Images
import iconTit from "../../../../assets/icons/icon-campaing.svg";
import iProducts from "../../../../assets/icons/icon-products.svg";

//CSS
import {
  Center,
  TopHeader,
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  Button,
  Btns,
} from "../../../../styles/style";

import { Container, Content, Err, LoadingBtn } from "../styles";

// Helpers
import replaceComma from "../../../../helpers/replaceComma";

export default function New({ history }) {
  const { register, handleSubmit, errors, control } = useForm();

  const [units, setUnits] = useState([]);
  const [types, setTypes] = useState([]);
  // const [ loading, setLoading] = useState(true)
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [similars, setSimilars] = useState([]);

  const msgsuccess = (text) => toast.success(text);
  const msgerror = (text) => toast.error(text);

  useEffect(() => {
    async function load(url, set) {
      await api
        .get(url)
        .then((result) => {
          set(result.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    load(`/product-type?perPage=false`, setTypes);
    load(`/product-unit?perPage=false`, setUnits);
    load(`/group-similar?perPage=false`, setSimilars, true);
  }, []);


  async function onSubmit(data, e) {
    setLoadingBtn(true);
    setTxtBtn(false);

    const quantityUnit = replaceComma(data.quantity_unit);
    const quantityPallet = replaceComma(data.quantity_pallet);
    const quantityBallast = replaceComma(data.quantity_ballast);
    const quantityPiled = replaceComma(data.quantity_piled);

    await api
      .post(`/product`, {
        code: data.cod,
        name: data.description,
        product_types_id: data.type.id,
        product_units_id: data.product_unit.id,
        quantity_unit: quantityUnit,
        quantity_pallet: quantityPallet,
        quantity_ballast: quantityBallast,
        quantity_piled: quantityPiled,
        group_similar_id: data.group_similar ? data.group_similar.id : null,
      })
      .then((result) => {
        e.target.reset();
        msgsuccess("Produto cadastrado com sucesso!");
        setLoadingBtn(false);
        setTxtBtn(true);
        history.push(`/configuracoes/produtos`);
      })
      .catch((err) => {
        msgerror(err.response.data.error.message);
        setLoadingBtn(false);
        setTxtBtn(true);
        console.log(err.response);
        console.log(err.response.message);
      });
  }

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>

        <Container>
          <AsideMenu />

          <Content>
            <header className="content">
              <h2>
                <img src={iProducts} alt="" />
                <Text id="novo" dm="Novo" /> <Text id="produto" dm="Produto" />
              </h2>
            </header>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <DescriptionTitle>
                <Text id="info.produto" dm="Informações do produto" />
              </DescriptionTitle>

              <BoxForm>
                <Row className="r2">
                  <InputBox>
                    <label htmlFor="">
                      <Text id="cod.sap" dm="Código SAP" />
                    </label>
                    <input name="cod" ref={register({ required: true })} />
                    {errors.cod && <Err>Digite o código</Err>}
                  </InputBox>
                  <InputBox>
                    <label htmlFor="">
                      <Text id="tipo" dm="Tipo" />
                    </label>
                    <Controller
                      as={
                        <Select
                          className="basic-single"
                          placeholder="Selecione o tipo"
                          options={types}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      }
                      control={control}
                      name="type"
                      rules={{ required: true }}
                    />

                    {errors.type && <Err>Selecione o tipo</Err>}
                  </InputBox>
                </Row>
                <Row className="r2">
                  <InputBox>
                    <label htmlFor="">
                      <Text id="qtd.kg.un" dm="Quantidade KG/L Unidade" />
                    </label>
                    <input name="quantity_unit" ref={register()} />
                    {errors.quantity_unit && (
                      <Err>Digite a quantidade de kg/l por unidade</Err>
                    )}
                  </InputBox>
                  <InputBox>
                    <label htmlFor="">
                      <Text id="qtd.un.pallet" dm="Quantidade Unidade/Pallet" />
                    </label>
                    <input name="quantity_pallet" ref={register()} />
                    {errors.quantity_pallet && (
                      <Err>Digite a quantidade de unidades por pallet</Err>
                    )}
                  </InputBox>
                </Row>
                <Row className="r2">
                  <InputBox>
                    <label htmlFor="">
                      <Text id="qtd.lastro" dm="Quantidade Lastro" />
                    </label>
                    <input name="quantity_ballast" ref={register()} />
                    {errors.quantity_ballast && <Err>Digite a quantidade</Err>}
                  </InputBox>
                  <InputBox>
                    <label htmlFor="">
                      <Text id="qtd.emp" dm="Quantidade Empilhado" />
                    </label>
                    <input name="quantity_piled" ref={register()} />
                    {errors.quantity_piled && <Err>Digite a quantidade</Err>}
                  </InputBox>
                </Row>
                <Row className="r2-1">
                  <InputBox>
                    <label htmlFor="">
                      <Text id="descricao" dm="Descrição" />
                    </label>
                    <input
                      name="description"
                      ref={register({ required: true })}
                    />
                    {errors.description && <Err>Digite o nome do produto</Err>}
                  </InputBox>
                  <InputBox>
                    <label htmlFor="">
                      <Text id="un.medida" dm="Unidade de Medida" />
                    </label>
                    <Controller
                      as={
                        <Select
                          className="basic-single"
                          placeholder="Selecione o tipo"
                          options={units}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      }
                      control={control}
                      name="product_unit"
                      rules={{ required: true }}
                    />
                    {/* <select name="product_unit" ref={register({ required: true })} id="">
                                        <option value="">Selecione a unidade</option>
                                        {units.map(unit => (
                                            <option key={unit.id} value={unit.id}>{unit.name}</option>
                                        ))}
                                    </select> */}
                    {errors.product_unit && <Err>Selecione a unidade</Err>}
                  </InputBox>
                </Row>
                <Row className="r1">
                  <InputBox>
                    <label htmlFor="">
                      <Text id="grupo_similar" dm="Grupo Similar" />
                    </label>                    
                    <Controller
                      as={
                        <Select
                          className="basic-single"
                          placeholder="Selecione o grupo similar"
                          options={[{id: '', name: ''}, ...similars]}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      }
                      control={control}
                      name="group_similar"
                    />

                    {errors.group_similar && <Err>Selecione o grupo similar</Err>}
                  </InputBox>
                </Row>
              </BoxForm>

              <Btns>
                <Button
                  type="reset"
                  className="cancel"
                  onClick={history.goBack}
                >
                  <Text id="cancelar" dm="Cancelar" />
                </Button>
                <Button>
                  {txtBtn && <Text id="salvar" dm="Salvar" />}
                  <LoadingBtn
                    sizeUnit={"px"}
                    size={10}
                    height={2}
                    color={"#fff"}
                    loading={loadingBtn}
                  />
                </Button>
              </Btns>
            </Form>
          </Content>
        </Container>
      </Center>
    </>
  );
}
