import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	padding: 12px 15px;
	border-radius: 6px;
	cursor: pointer;
	transition: .3s;
    &.mr{
        margin-right: 20px;
    }
	img{
			margin-right: 10px;
	}
	.icon-filter{
        margin-right: 10px;
        span{
            display: block;
            width: 17px;
            height: 2px;
            background: #000;
            margin-bottom: 4px;
            position: relative;

            &:before{
                content: '';
                display: block;
                position: absolute;
                top: -2px;
                left: 2px;
                border-radius: 50%;
                width: 2.5px;
                height: 2px;
                border: solid 2px #000;
                background: #ECECEC;
                transition: all ease 0.2s;
            }
            &:nth-child(2n){
                &:before{
                    left: 10px;
                    transition: all ease 0.4s;
                }
            }
            &:last-child{
                &:before{
                    transition: all ease 0.6s;
                }
            }
        }
	}
	&:hover, &.active{
		background: #fff;
        .icon-filter{
            span{
                &:before{
                    left: 10px;
                }
                &:nth-child(2n){
                    &:before{
                            left: 2px;
                    }
                }
            }
        }
	}
`