import React from "react";
import moment from "moment";

// Components
import IconStatus from "../../../components/IconStatus";
import Text from "../../../components/Text";

//Images
import iSearch from "../../../assets/icons/icon-search.svg";

//CSS
import { Tit, FilterLine, FilterInput } from "./styles";

function Filter({
  name,
  begin,
  end,
  status,
  onNameChange,
  onBeginChange,
  onEndChange,
  onStatusChange,
  onClean,
}) {
  const statusOptions = [
    { label: "Finalizado", value: "atendida" },
    { label: "Pend. de Estoque", value: "penEstoque" },
    { label: "Pend. NF", value: "penEmissao" },
    { label: "Parc. Atendido", value: "parAtendido" },
  ];

  return (
    <FilterLine>
      <Tit>
        <Text id="filtrarmaterial" dm="Filtrar Material" />
      </Tit>

      <ul>
        <FilterInput>
          <img src={iSearch} alt="" />
          <input
            placeholder="Buscar material"
            value={name}
            onChange={(e) => onNameChange(e.target.value)}
          />
        </FilterInput>
        <FilterInput>
          <p>Início</p>
          <input
            type="date"
            value={moment(begin).format("YYYY-MM-DD")}
            onChange={(e) =>
              onBeginChange(
                e.target.value
                  ? moment.utc(e.target.value).startOf("day")
                  : null
              )
            }
          />
        </FilterInput>
        <FilterInput>
          <p>Final</p>
          <input
            type="date"
            value={moment(end).format("YYYY-MM-DD")}
            disabled={!begin}
            min={moment(begin).format("YYYY-MM-DD")}
            onChange={(e) =>
              onEndChange(
                e.target.value ? moment.utc(e.target.value).endOf("day") : null
              )
            }
          />
        </FilterInput>
        <FilterInput>
          <p>Status</p>
          <select onChange={(e) => onStatusChange(e.target.value)}>
            <option value="" />
            {statusOptions.map((item) => (
              <option
                selected={item.value === status ? true : false}
                id={item.value}
                value={item.value}
              >
                {item.label}
              </option>
            ))}
          </select>
        </FilterInput>
        <FilterInput className="reset">
          <IconStatus onClick={() => onClean()} theme="reload" />
        </FilterInput>
      </ul>
    </FilterLine>
  );
}

export default Filter;
