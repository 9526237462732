import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

// Components
import Header from '../../../components/Header';
import AsideMenu from '../../../components/AsideMenu';
import BtnAdd from '../../../components/BtnAdd';
import LoadPage from '../../../components/LoadPage';
import BtnExcel from '../../../components/BtnExcel';
import BtnBack from '../../../components/BtnBack';
import Text from '../../../components/Text';

//Images
import iconTit from '../../../assets/icons/icon-campaing.svg';
import iBom from '../../../assets/icons/icon-bom.svg';
import iSearch from '../../../assets/icons/icon-search.svg';

//CSS
import { Center, TopHeader } from '../../../styles/style';
import * as S from './styles';

export default function Bom() {
  const [search, setSearch] = useState('');
  const [bom, setBom] = useState([]);
  const [loading, setLoading] = useState(true);

  const [file, setFile] = useState('');
  const [fileName, seFileName] = useState('');

  let history = useHistory();

  useEffect(() => {
    async function feachBom() {
      await api
        .get(`/bom?filters[0][equal][bom_type_id]=2&perPage=100`)
        .then((result) => {
          setBom(result.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    feachBom();
  }, []);

  useEffect(() => {
    function generateExcelData() {
      let arrayExcel = [];

      bom.forEach((item) => {
        const PRODUTO_ACABADO = item.core_bom_product?.product.name || '-';
        const QUANTIDADE_MÍNIMA = item.core_bom_product?.quantity || '-';
        const EMPRESA = item.branch?.company_name || '-';

        const data = {
          PRODUTO_ACABADO,
          QUANTIDADE_MÍNIMA,
          EMPRESA,
        };

        arrayExcel.push(data);
      });

      return arrayExcel;
    }

    if (bom[0]) {
      const newFile = generateExcelData();

      setFile(newFile);

      const newFileName = `BOM_${moment(new Date()).format('DD_MM_YYYY')}`;

      seFileName(newFileName);
    }
  }, [bom]);

  function exportToCSV() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(file);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const results = !search
    ? bom
    : bom.filter((e) =>
        e.core_bom_product?.product?.name
          .toLowerCase()
          .includes(search.toLocaleLowerCase())
      );

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>
        <S.Container>
          <AsideMenu />

          <S.Content>
            <header>
              <h2>
                <img src={iBom} alt="" />
                <Text id="bom" dm="BOM (Bill Of Materials)" />
              </h2>

              <S.FilterConfig>
                <img src={iSearch} alt="" />
                <input
                  placeholder="Buscar produto"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </S.FilterConfig>
              {!loading && (
                <BtnExcel
                  type="button"
                  disabled={!bom}
                  onClick={() => exportToCSV()}
                  text="Exportar Relatório"
                />
              )}

              <Link to="bom/novo">
                <BtnAdd />
              </Link>
            </header>

            {loading ? (
              <LoadPage />
            ) : (
              <S.BomContainer>
                <S.HeaderList>
                  <p>
                    <Text id="produto.acabado" dm="Produto Acabado" />
                  </p>
                  <p>
                    <Text id="qtd.min" dm="Quantidade mínima" />
                  </p>
                  <p>
                    <Text id="empresa" dm="Empresa" />
                  </p>
                </S.HeaderList>

                {results.map((item) => (
                  <S.ItemList
                    key={item.id}
                    onClick={() => {
                      history.push(`bom/${item.id}`);
                    }}
                  >
                    <p>{item.core_bom_product?.product.name}</p>
                    <p>{item.core_bom_product?.quantity}</p>
                    <p>{item.branch?.company_name}</p>
                  </S.ItemList>
                ))}
              </S.BomContainer>
            )}
          </S.Content>
        </S.Container>
      </Center>
    </>
  );
}
