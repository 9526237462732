import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 40px;
`;

export const HeaderList = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 5fr;
  padding: 0 20px;
  margin-bottom: 20px;

  p {
    font-size: 14px;
  }
`;
export const List = styled.div``;
export const ItemList = styled.div`
  width: 100%;
  padding: 20px;
  padding-right: 10px;
  border-radius: 6px;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 2fr 5fr;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: all ease 0.1s;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
  p {
    font-size: 12px;
    color: #8b8d97;
    &:first-child {
      font-weight: 700;
    }
  }
`;

export const Description = styled.p`
  font-size: 14px;
`;
