import { call, put, select } from "redux-saga/effects";
import { notify } from "../../../services/notification";
import api, { makeOptions } from "../../../services/api";
import OrdersComboboxActions from "../../ducks/orders/orders-combobox";

const getAuthData = (state) => state.auth.data;

export function* list(action) {
  try {
    const url = `order-combobox`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.get, url, makeOptions(authData));

    yield put(OrdersComboboxActions.success(data));
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(OrdersComboboxActions.failure(err.response.data.error.message));
  }
}
