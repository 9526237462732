import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { isBefore } from 'date-fns';
import { notify } from '../../../services/notification';
import { Search } from '../../../components/shared'
import ActionsBranchInvoiceList from '../../../store/ducks/branch-invoices/list';
import * as Yup from 'yup';

import { Input, Select } from '../../shared';
import { useValidation } from '../../../hooks';
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';


const initalSearch = null;

const initalFilter = {
  status: null,
  startDate: null,
  endDate: null,
};

const Filter = ({ handleFilter, filter }) => {
  
  const {
    data: branchInvoiceData,
    loading,
    pagination,
  } = useSelector((state) => state.branchInvoices);

  const dispatch = useDispatch();
  
  let localSearch = JSON.parse(localStorage.getItem('search'));
  let localFilters = JSON.parse(localStorage.getItem('filter'));

  const [currentPage, setCurrentPage] = useState(pagination?.page || 1);
  const [filterSearch, setFilterSearch] = useState(localFilters ? localFilters : initalFilter);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(localSearch ? localSearch : initalSearch);
  const formRef = useRef();
  const { handleFormErrors } = useValidation();

  const onSearch = useCallback((query) => {
    setSearch(query);
    setCurrentPage(1);
  }, []);

  const fecthData = useCallback(() => {
    dispatch(
      ActionsBranchInvoiceList.request({ page: currentPage, search, ...filterSearch })
    );
  }, [dispatch, currentPage, search, filterSearch]);

  useEffect(() => {
    fecthData();
  }, [fecthData, filterSearch, reload]);

  const reloadData = () => {
    setReload(!reload)
  }
  useEffect(()=>{
    localStorage.setItem('filter',JSON.stringify(filter))
  },[filterSearch]);

  useEffect(()=>{
    if(localSearch){
      setSearch(localSearch)
    }
  },[]);

  useEffect(()=>{
    localStorage.setItem('search',JSON.stringify(search))
  },[search]);

  

  const putFilter = useCallback((data) => {
    if (formRef.current) {
      formRef.current.setData(data);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.startDate !== '' && data.endDate !== '') {
          if (isBefore(new Date(data.endDate), new Date(data.startDate))) {
            return notify(
              'error',
              'Data inicial não pode ser maior que a data final'
            );
          }
        }
        console.log('data:',data,'reset:',reset)
        handleFilter(data, reset);
        setOpen(false);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFilter, handleFormErrors]
  );

  useEffect(() => {
    putFilter(filter);
  }, [filter, putFilter]);
  return (
    <S.Container>
      <S.ButtonFilter onClick={() => setOpen(true)}>
        <S.IconFilter />
      </S.ButtonFilter>
      <S.Overlay open={open}>
        <S.Box>
          <S.Head>
            <S.Title>
              <S.IconFilterModal />
              Filtrar consumo
            </S.Title>
          </S.Head>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.WrapFilters>
              {/* <Input name="search" label="Filtrar"  /> */}
              <Input name="filter_campaign" label="Campanha"  />
              <Input name="filter_nf" label="NF"  />
              {/* <Input name="qtd_nf" label="QTD.NF" /> */}
              <Input name="filter_product" label="PRODUTO" />
              <Input name="filter_toller" label="TOLLER" />
            </S.WrapFilters>
            <S.WrapInputs>
              <Input name="startDate" label="Data emissão de:" type="date" />
              <Input name="endDate" label="Data emissão até:" type="date" />
            </S.WrapInputs>
            <Select
              name='status'
              label="Status:"
              options={[
                { label: 'Finalizados', value: 'finalizados' },
                { label: 'Pendentes', value: 'pendentes' },
                { label: 'Pendente de consumo SAP', value: 'pendentesConsumoSap' },
                { label: 'Pendente de importação de NF', value: 'pendentesImportNf' },
              ]}
            />
            <S.WrapButtons>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => setOpen(false)}
              >
                Voltar
              </S.Button>
              <S.Button btStyle="primary" type="submit">
                Filtrar
              </S.Button>
            </S.WrapButtons>
          </Form>
        </S.Box>
      </S.Overlay>
    </S.Container>
  );
};

export { Filter };
