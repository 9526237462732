import React from "react";

import * as S from "./styles";

export default function Button({
  classN,
  type,
  text,
  theme,
  onClick,
  disabled,
  datatip,
}) {
  function Theme() {
    switch (theme) {
      case "atendida":
        return S.themes.atendida;
      case "naoatendida":
        return S.themes.naoatendida;
      case "atrasada":
        return S.themes.atrasada;
      case "air":
        return S.themes.air;
      case "boat":
        return S.themes.boat;
      case "fabricacao":
        return S.themes.fabricacao;
      case "nf":
        return S.themes.nf;
      case "nfok":
        return S.themes.nfok;
      case "penEstoque":
        return S.themes.penEstoque;
      case "penEmissao":
        return S.themes.penEmissao;
      case "parAtendido":
        return S.themes.parAtendido;
      case "emtransito":
        return S.themes.emtransito;
      case "aguardando":
        return S.themes.aguardando;
      case "info":
        return S.themes.info;
      case "reload":
        return S.themes.reload;
      default:
        break;
    }
  }
  return (
    <>
      <S.Container
        onClick={onClick}
        data-tip={datatip}
        className={classN}
        themes={Theme()}
      >
        {text}
      </S.Container>
    </>
  );
}
