import axios from 'axios';
import querystring from 'querystring';
import { getToken } from './auth';

const headers = {
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
});

const queryBuilder = (params) => {
  const cleanParams = {};
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) cleanParams[key] = value;
  });
  return querystring.stringify(cleanParams);
};

const makeOptions = (authData) => ({
  headers: {
    ...headers,
    ...(authData && {
      Authorization: `Bearer ${authData.token}`,
    }),
  },
});

api.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
  }
);

export default api;
export { makeOptions, queryBuilder };
