import React from 'react';

import * as S from './styles';

const Paginator = ({
  pagination,
  onPageChange,
  neighbors = 1,
  loading = false,
}) => {
  function handleClick(page) {
    if (onPageChange) onPageChange(page);
  }

  const Buttons = () => {
    if (pagination === null) return <></>;

    const { page, lastPage } = pagination;

    const pages = lastPage;

    const targetSize = neighbors * 2;
    const middle = Math.floor(targetSize / 2);
    const paginatorArray = [];

    if (pages > 1) {
      let inicio = page > middle ? page - middle : 1;
      inicio = inicio > pages - targetSize ? pages - targetSize : inicio;
      inicio = inicio < 1 ? 1 : inicio;
      let t = inicio + targetSize;
      t = pages < t ? pages : t;
      for (let i = inicio; i <= t; i++) paginatorArray.push(i);
    }

    return (
      <S.Container>
        <S.Content>
          {page > 5 && lastPage > 9 && (
            <S.Button
              onClick={() => handleClick(1)}
              active={false}
              className="aux-page"
            >
              Primeira
            </S.Button>
          )}
          {paginatorArray.map((index) => (
            <S.Button
              active={page === index}
              key={index}
              onClick={() => handleClick(index)}
            >
              {index}
            </S.Button>
          ))}
          {page <= lastPage - 5 && lastPage > 9 && (
            <S.Button
              onClick={() => handleClick(lastPage)}
              active={false}
              className="aux-page"
            >
              Última
            </S.Button>
          )}
          {loading && <S.PageLoading />}
        </S.Content>
        <S.Description>
          Exibindo página {pagination.page}/{pagination.lastPage} de um total de{' '}
          {pagination.total} registros
        </S.Description>
      </S.Container>
    );
  };
  return <Buttons />;
};

export default Paginator;
