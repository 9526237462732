import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import Select from "react-select";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import moment from "moment";

// Helpers 
import replaceComma from '../../../helpers/replaceComma';

import Modal from "../../../components/Modal";

import * as S from "./styles";
import * as Ss from "../../../styles/style";

export default function ModalPrevisao({
  openmp,
  handleClose,
  data,
  loadCampaings,
  deadlineDate,
}) {
  const { register, handleSubmit, errors, control } = useForm();
  const [dataadd, setDataadd] = useState();
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [txtDelete, setTxtDelete] = useState(true);
  const [loadBtnDelete, setloadBtnDelete] = useState(false);
  const [justifications, setJustifications] = useState([]);

  const id = data.id;
  const order_id = data.order_id;
  const bom_product_id = data.bom_product_id;


  console.log('data modal', data)

  const close = () => {
    handleClose();
    setDataadd(null);
  };

  const msgsuccess = (text) => toast.success(text);
  const msgError = (text) => toast.error(text);

  async function loadJustifications() {
    try {
      const response = await api.get('justification-n?perPage=false');
      setJustifications(response.data.data)
     } catch(err) {
       console.log(err);
     }
  };

  useEffect(() => {
    loadJustifications();
  }, [data]);

  const todayDate = new Date();
  const lock = moment(todayDate).isAfter(moment(deadlineDate));

  async function onSubmit(data) {
    const quantityFormatted = replaceComma(data.qtd);

    if(quantityFormatted > 0) {
      setLoadingBtn(true);
      setTxtBtn(false);

      await api
        .put(`/bom-product-order-prediction/${id}`, {
          date: dataadd,
          quantity: quantityFormatted,
          bom_product_id: bom_product_id,
          order_id: order_id,
          justification_id: data.justification
        })
        .then((result) => {
          setLoadingBtn(false);
          setTxtBtn(true);
          msgsuccess("Data Atualizada");
          handleClose();
          loadCampaings();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      msgError("Quantidade dever ser maior que 0");
    }
  }

  // Delete bom
  async function deleteData() {
    setloadBtnDelete(true);
    setTxtDelete(false);

    await api
      .delete(`/bom-product-order-prediction/${id}`)
      .then((result) => {
        setloadBtnDelete(false);
        setTxtDelete(true);
        msgsuccess("Data removida");
        handleClose();
        loadCampaings();
      })
      .catch((error) => {
        console.log(error);
        setloadBtnDelete(false);
        setTxtDelete(true);
      });
  }

  return (
    <Modal open={openmp} close={close}>
      {data && (
        <S.ContainerModalSmall>
          <S.BtnFechar onClick={close} />
          <Ss.Form onSubmit={handleSubmit(onSubmit)}>
            {data?.name ? <Ss.Title>Produto similar: <br></br>{data.name}</Ss.Title> : '' }
            <Ss.Row>
              <Ss.InputBox>
                <label htmlFor="">Quantidade</label>
                <input
                  type="text"
                  name="qtd"
                  defaultValue={data.qtd}
                  ref={register({ required: true })}
                />
                {errors.qtd && <Ss.Err>Digite a quantidade</Ss.Err>}
              </Ss.InputBox>
            </Ss.Row>


            <Ss.Row>
              <Ss.InputBox>
                <label htmlFor="">Data</label>
                <input
                  type="date"
                  name="data"
                  onChange={(date) => {
                    setDataadd(date.target.value)
                    }
                  }
                  value={dataadd}
                  max={new Date()}
                  min={new Date(data.final_date)}
                  />
              </Ss.InputBox>
            </Ss.Row>


            <Ss.Row>
              <Ss.InputBox>
                <label htmlFor="">Justificativa</label>
                  <select
                    name="justification"
                    ref={register({ required: true })}
                  >
                    <option value="" />
                    {justifications.map(justification => (
                      <option selected={justification.id === data.justification} value={justification.id}>{justification.name}</option>
                    ))}
                  </select>
                {/*
                  <Controller
                      as={
                        <Select
                          className="basic-single"
                          placeholder="Selecione a justificativa"
                          options={justifications}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      }
                      control={control}
                      name="justification"
                      rules={{ required: true }}
                      defaultValue={data.justification}
                    /> 
                  */}
                {errors.justification && <Ss.Err>Escolha a justificativa</Ss.Err>}
              </Ss.InputBox>
            </Ss.Row>
              <Ss.Button100 disabled={lock}>
              {txtBtn && "Atualizar"}
              <Ss.LoadingBtn
                marginTop={"10px"}
                sizeUnit={"px"}
                size={10}
                height={2}
                color={"#fff"}
                loading={loadingBtn}
              />
            </Ss.Button100>
            <Ss.Button100 type="reset" className="delete" onClick={deleteData} disabled={lock}>
              {txtDelete && "Remover Data"}
              <Ss.LoadingBtn
                marginTop={"10px"}
                sizeUnit={"px"}
                size={10}
                height={2}
                color={"#fff"}
                loading={loadBtnDelete}
              />
            </Ss.Button100>
        
          </Ss.Form>
        </S.ContainerModalSmall>
      )}
    </Modal>
  );
}
