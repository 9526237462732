import styled from "styled-components";
import { Trash } from "@styled-icons/boxicons-regular";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Item = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 100px 180px 100px auto 32px;
  column-gap: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  height: 54px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
`;

export const Value = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.dark};

  &.last {
    text-align: right;
  }
`;

export const TrashButton = styled.button.attrs({ type: "button" })`
  width: 20px;
  height: 20px;
  display: flex;
  transition: 300ms ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const IconTrash = styled(Trash).attrs({ size: 20 })`
  color: ${({ theme }) => theme.colors.lightDarkBlue};
`;
