import React, { useState } from "react";
import { Route, Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import Menu from "../Menu";
import Lang from "./Language";
import Text from "../Text";

// Links
import { links } from "../../content";

// Images
import user from "../../assets/user-header.png";
import logo from "../../assets/logo.png";

// CSS
import { Center } from "../../styles/style";
import * as S from "./styles";

export default function Header(props) {
  const { data: authData } = useSelector((state) => state.auth);
  const [menu, setMenu] = useState({
    right: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMenu({ ...menu, [side]: open });
  };
  return (
    <>
      <Menu menu={menu} onToggleDrawer={toggleDrawer} />

      <S.Container noMargin={props.noMargin}>
        <Center className="center">
          <img src={logo} alt="" />
          <S.Navigation className="main-nav">
            <Route>
              {links.map((link, i) => (
                <Link to={link.url} key={i}>
                  <Text id={link.txt} dm={link.label} />
                </Link>
              ))}
            </Route>
          </S.Navigation>
          <Lang />
          <S.UserHeader>
            {/* <img src={user} alt="" /> */}
            <S.Username>{authData?.user_name}</S.Username>
            <S.IconMenu onClick={toggleDrawer("right", true)}>
              <span />
              <span />
              <span />
            </S.IconMenu>
          </S.UserHeader>
        </Center>
      </S.Container>
    </>
  );
}
