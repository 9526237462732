import styled from "styled-components";
import { Alert } from "@styled-icons/remix-fill/";
import { CheckCircle } from "@styled-icons/boxicons-solid/";
import { Comment } from "@styled-icons/material-outlined/Comment"

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const Content = styled.a`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns:
    repeat(5, minmax(80px, auto))
    1fr 0.8fr 1fr 1fr 32px;
  column-gap: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 200ms ease;

  :hover {
    transform: scale(1.015);
  }
`;

export const Value = styled.span`
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.dark};
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: ${({ bg, theme }) => (bg ? bg : theme.colors.gray)};
  color: ${({ theme }) => theme.colors.white};
  position: relative;

  :hover {
    span {
      display: flex;
    }
  }

  span {
    display: none;
    position: absolute;
    top: -32px;
    width: max-content;
    padding: 4px;
    border-radius: 2px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.blueDark};
  }
`;

export const IconAlert = styled(Alert).attrs({ size: 20 })`
  color: ${({ theme }) => theme.statusColors.divergente};
  justify-self: center;
`;

export const IconCheck = styled(CheckCircle).attrs({ size: 20 })`
  color: ${({ theme }) => theme.statusColors.aprovado};
  justify-self: center;
`;
export const IconComment = styled(Comment).attrs({ size: 25 })`
  justify-self: center;
  margin:10px;
  cursor:pointer;
  transition: 200ms ease;
  :hover {
    transform: scale(1.15);
  }
`;
