import React from 'react';

import { Container } from './styles';

import icon from '../../assets/icons/icon-plus.svg'

export default function BtnAdd() {
  return (
    <Container>
        <img src={icon} alt=""/>
    </Container>
  );
}
