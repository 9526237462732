import { call, put, select } from "redux-saga/effects";
import { notify } from "../../../services/notification";
import api, { makeOptions } from "../../../services/api";
import ActionsBranchInvoicesCreate from "../../ducks/branch-invoices/create";

const getAuthData = (state) => state.auth.data;

export function* create(action) {
  try {
    const { data: postData, onSuccess } = action;

    const url = `branch-invoice`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.post, url, postData, makeOptions(authData));

    yield put(ActionsBranchInvoicesCreate.success(data.data));
    notify("success", "Nota fiscal cadastrada com sucesso!");
    onSuccess();
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ActionsBranchInvoicesCreate.failure(err.response.data.error.message)
    );
  }
}
