import React from 'react';
import { Switch } from 'react-router-dom';

import RoutePrivate from './RoutePrivate';

import Consume from '../pages/Consume';
import Compare from '../pages/Consume/Compare';
import CompareApproved from '../pages/Consume/CompareApproved';
import CompareCampaign from '../pages/Consume/CompareCampaign';

const RoutesConsumo = ({ loggedIn }) => {
  return (
    <Switch>
      <RoutePrivate
        title="Consumo"
        authed={loggedIn}
        path="/consumo"
        exact
        comp={Consume}
      />
      <RoutePrivate
        title="Consumo - Comparativo"
        authed={loggedIn}
        path="/consumo/:invoice/:branch/:product"
        exact
        comp={Compare}
      />
      <RoutePrivate
        title="Consumo - Comparativo Aprovado"
        authed={loggedIn}
        path="/consumo-aprovado/:invoice/:branch/:product"
        exact
        comp={CompareApproved}
      />
      <RoutePrivate
        title="Consumo - Campanha"
        authed={loggedIn}
        path="/consumo-campanha/:order"
        exact
        comp={CompareCampaign}
      />
    </Switch>
  );
};

export default RoutesConsumo;
