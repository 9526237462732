import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  
`;
export const Content = styled.div`
    width: calc(100% - 305px);
    header.content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 40px;
    }
    h2{
        display: flex;
        font-size: 16px;
        align-items: center;
        img{
            margin-right: 20px;
        }
    }
`
export const Err = styled.div`
    font-size: 10px;
    color: red;
    margin: 5px 0;
`