import styled from 'styled-components';
import { File } from '@styled-icons/boxicons-regular/';
import { Close } from '@styled-icons/evaicons-solid/';

import { Loading } from '../../../styles/components';

export { ButtonMini, Button, Loading } from '../../../styles/components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: auto;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  max-width: 780px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 40px;
  animation-name: appear;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-direction: alternate;

  input {
    &[type='time']::-webkit-inner-spin-button {
      display: none;
    }
  }
  input {
    &[type='time']::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  @keyframes appear {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const Head = styled.div`
  width: 100%;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px ${({ theme }) => theme.colors.line} solid;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.black};
`;

export const WrapInputs = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const WrapButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;
  border-left: none;
  border-right: none;

  button:first-child {
    margin-right: 16px;
  }
`;

export const IconFile = styled(File).attrs({ size: 32 })`
  color: ${({ theme }) => theme.colors.black};
  margin-right: 8px;
`;

export const OnLoading = styled(Loading)`
  color: #fff;
`;

export const IconClose = styled(Close).attrs({ size: 20 })`
  margin-left: 8px;
`;

export const ResponseWrapper = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.darkRgba8};
  border-radius: 4px;
`;

export const Response = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 2px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  color: ${({ theme }) => theme.colors.white};
`;

export const ResponseError = styled(Response)`
  background-color: ${({ theme }) => theme.colors.danger};
`;

export const ResponseSuccess = styled(Response)`
  background-color: ${({ theme }) => theme.colors.green};
`;
