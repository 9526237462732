import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { withSwalInstance } from 'sweetalert2-react';
import swal from 'sweetalert2';
import Select from 'react-select';

// Helpers
import replaceComma from '../../../../helpers/replaceComma';

// Components
import Header from '../../../../components/Header';
import AsideMenu from '../../../../components/AsideMenu';
import LoadPage from '../../../../components/LoadPage';
import BtnBack from '../../../../components/BtnBack';
import Text from '../../../../components/Text';
import AddProducts from './addProducts';

//Images
import iconTit from '../../../../assets/icons/icon-campaing.svg';
import iBom from '../../../../assets/icons/icon-bom.svg';

//CSS
import {
  Center,
  TopHeader,
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  Button,
  Btns,
} from '../../../../styles/style';

import { Container, Content, Err, LoadingBtn } from '../styles';

export default function Details({ history }) {
  const { register, handleSubmit, errors, control } = useForm();

  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [bom, setBom] = useState();
  const [finishedProduct, setfinishedProduct] = useState([]);
  const [materialProduct, setmaterialProduct] = useState([]);
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [txtDelete, setTxtDelete] = useState(true);
  const [loadBtnDelete, setloadBtnDelete] = useState(false);

  let { id } = useParams();
  const msgsuccess = (text) => toast.success(text);

  async function load(url, set) {
    await api
      .get(url)
      .then((result) => {
        set(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function loadProduct() {
    await api
      .get(`/product?perPage=10000`)
      .then((result) => {
        setfinishedProduct(
          result.data.data.filter(
            (e) => e.type && e.type.name.includes('PRODUTO ACABADO')
          )
        );
        setmaterialProduct(
          result.data.data.filter(
            (e) => !(e.type && e.type.name.includes('PRODUTO ACABADO'))
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    async function loadModules() {
      await load(`/bom/${id}`, setBom);
      await load(`/branch-n?perPage=false`, setCompanies);
      await loadProduct();
      setLoading(false);
    }
    loadModules();
  }, [id]);

  // Atualizando bom
  async function onSubmit(data, e) {
    setLoadingBtn(true);
    setTxtBtn(false);

    const quantityFormatted = replaceComma(data.quantidade);

    await api
      .patch(`/bom/${id}`, {
        branch_id: data.toller.id,
        product_id: data.produto.id,
        quantity: quantityFormatted,
        bom_product_id: bom.core_bom_product.id,
      })
      .then((result) => {
        setLoadingBtn(false);
        setTxtBtn(true);
        msgsuccess('Bom atualizado!');
        e.target.reset();
        history.push('/configuracoes/bom');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Delete bom
  async function deleteBom() {
    setloadBtnDelete(true);
    setTxtDelete(false);
    withSwalInstance(
      swal
        .fire({
          title: 'Deletar o BOM?',
          text: 'Você não poderá reverter isso!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ABC9C',
          cancelButtonColor: '#FF4E50',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sim, pode deletar!',
        })
        .then(async (result) => {
          if (result.value) {
            await api
              .delete(`/bom/${id}`)
              .then((result) => {
                setloadBtnDelete(false);
                setTxtDelete(true);
                msgsuccess('Bom removido com sucesso!');
                history.push('/configuracoes/bom');
              })
              .catch((error) => {
                console.log(error);
              });
            swal.fire('Excluído!', 'Produto foi excluído', 'success');
          } else {
            setloadBtnDelete(false);
            setTxtDelete(true);
          }
        })
    );
  }

  // Add materia prima
  async function addMaterial(data) {
    const quantityFormatted = replaceComma(data.quantity);

    const dataFormatted = {
      ...data,
      quantity: quantityFormatted,
    };

    await api.post(`/bom-product/`, dataFormatted).then((result) => {
      msgsuccess('Produto adicionado');
      load(`/bom/${id}`, setBom);
    });
  }

  // Remover materia prima
  async function deleteMaterial(data) {
    await api
      .delete(`/bom-product/${data.id}`)
      .then((result) => {
        msgsuccess('Máteria prima removida');
        load(`/bom/${id}`, setBom);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>

        <Container>
          <AsideMenu />
          <Content>
            <header className="content">
              <h2>
                <img src={iBom} alt="" />
                <Text id="bom" dm="BOM (Bill Of Materials" />
              </h2>
            </header>
            {loading ? (
              <LoadPage />
            ) : (
              <>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <DescriptionTitle>
                    <Text id="info.bom" dm="Informações do BOM" />
                  </DescriptionTitle>
                  <BoxForm>
                    <Row className="r2">
                      <InputBox>
                        <label htmlFor="">
                          <Text id="toller" dm="Toller" />
                        </label>
                        <Controller
                          as={
                            <Select
                              className="basic-single"
                              placeholder="Selecione o toller"
                              options={companies}
                              getOptionLabel={({ company_name }) =>
                                company_name
                              }
                              getOptionValue={({ id }) => id}
                            />
                          }
                          control={control}
                          name="toller"
                          rules={{ required: true }}
                          defaultValue={bom.branch}
                        />
                        {errors.toller && <Err>Selecione o toller</Err>}
                      </InputBox>
                    </Row>
                    <Row className="r2">
                      <InputBox>
                        <label htmlFor="">
                          <Text id="produto.acabado" dm="Produto Acabado" />
                        </label>
                        <Controller
                          as={
                            <Select
                              className="basic-single"
                              placeholder="Selecione o produto"
                              options={finishedProduct}
                              getOptionLabel={({ name }) => name}
                              getOptionValue={({ id }) => id}
                            />
                          }
                          control={control}
                          name="produto"
                          rules={{ required: true }}
                          defaultValue={bom.core_bom_product?.product}
                        />
                        {errors.produto && <Err>Selecione o produto</Err>}
                      </InputBox>
                      <InputBox>
                        <label htmlFor="">
                          <Text id="quantidade" dm="Quantidade" />
                        </label>
                        <input
                          name="quantidade"
                          defaultValue={bom.core_bom_product?.quantity}
                          ref={register({ required: true })}
                        />
                        {errors.quantidade && <Err>Digite a quantidade</Err>}
                      </InputBox>
                    </Row>
                  </BoxForm>

                  <Btns className="crem">
                    <Button type="reset" className="delete" onClick={deleteBom}>
                      {txtDelete && <Text id="deletar" dm="Deletar" />}
                      <LoadingBtn
                        marginTop={'10px'}
                        sizeUnit={'px'}
                        size={10}
                        height={2}
                        color={'#fff'}
                        loading={loadBtnDelete}
                      />
                    </Button>
                    <span>
                      <Button type="reset" className="cancel">
                        <Text id="cancelar" dm="Cancelar" />
                      </Button>
                      <Button>
                        {txtBtn && <Text id="salvar" dm="Salvar" />}
                        <LoadingBtn
                          marginTop={'10px'}
                          sizeUnit={'px'}
                          size={10}
                          height={2}
                          color={'#fff'}
                          loading={loadingBtn}
                        />
                      </Button>
                    </span>
                  </Btns>
                </Form>

                <AddProducts
                  onSubmit={addMaterial}
                  materialProduct={materialProduct}
                  id={id}
                  list={bom.bom_products_materials}
                  deleteProduct={deleteMaterial}
                />
              </>
            )}
          </Content>
        </Container>
      </Center>
    </>
  );
}
