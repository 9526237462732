import React, {useState, useEffect} from 'react';
import { useForm } from 'react-hook-form'
import api from '../../../../services/api'
import { toast } from 'react-toastify';
import { useParams} from "react-router";

// Components
import Header from '../../../../components/Header'
import AsideMenu from '../../../../components/AsideMenu'
import BtnBack from "../../../../components/BtnBack";
import Text from "../../../../components/Text";

//Images
import iconTit from '../../../../assets/icons/icon-campaing.svg'
import iProducts from "../../../../assets/icons/icon-products.svg";


//CSS
import { Center, TopHeader, Form, DescriptionTitle, BoxForm, Row, InputBox, Button, Btns } from '../../../../styles/style'

import { Container, Content, Err} from './styles';

export default function Details({history}) {
    const [similar, setSimilar] = useState([])
    const [loading, setLoading] = useState(true)
    const { register, handleSubmit, errors } = useForm()

    let {id} = useParams()

    const msgsuccess = () => toast.success("Grupo similar atualizada!");
    const msgdelete = () => toast.success("Grupo similar removido com sucesso!");

    async function onSubmit(data, e){
        e.preventDefault()
        await api.patch(`/group-similar/${id}`, {
            name: data.name || null,
        })
        .then(result => {
            msgsuccess()
            history.push('/configuracoes/grupo-similar')
        })
        .catch(error => {
            console.log(error)
            history.push('/configuracoes/grupo-similar')
        })
    }

    async function removeGroupSimilar(){
        await api.delete(`/group-similar/${id}`)
        .then(result => {
            msgdelete()
            history.push('/configuracoes/grupo-similar')
        })
        .catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        async function loadGroupSimilar(){
            
            await api.get(`/group-similar/${id}`)
            .then(result => {
                setSimilar(result.data.data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
        }

        loadGroupSimilar()
    }, [id])

  return (
    <>
        <Header />
        <Center>
            <TopHeader>
                <span>
                    <img src={iconTit} className="icontit" alt="" />
                    <h1>
                    <Text id="config.title" dm="Configurações" />
                    </h1>
                </span>
                <BtnBack onClick={history.goBack} />
            </TopHeader>

            <Container>
                <AsideMenu />
                
                <Content>
                    <header className="content">
                        <h2>
                            <img src={iProducts} alt=""/> 
                            <Text id="grupo_similar" dm="Grupo Similar" />
                        </h2>
                    </header>
                    
                    {loading ? '' : 
                    
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <DescriptionTitle>
                                <Text
                                    id="info.grupo_similar"
                                    dm="Informações do grupo similar"
                                />
                            </DescriptionTitle>
                            <BoxForm>
                                <Row className="r1">
                                    <InputBox>
                                        <label htmlFor="">
                                            <Text id="grupo_similar" dm="Grupo Similar" />
                                        </label>  
                                        <input 
                                            name="name" 
                                            defaultValue={similar.name} 
                                            ref={register({ required: true })}
                                        />
                                        {errors.name && <Err>Digite o nome</Err>}
                                    </InputBox>
                                </Row>
                            </BoxForm>

                            <DescriptionTitle>
                                Endereço
                            </DescriptionTitle>

                            
                            <Btns className="crem">
                                {!(similar.products && similar.products[0]) && (
                                    <Button
                                        type="reset"
                                        className="delete"
                                        onClick={removeGroupSimilar}                    
                                    >
                                        <Text id="remover" dm="Remover" />
                                    </Button>
                                )}
                                <span>
                                <Button type="reset" className="cancel">
                                    <Text id="cancelar" dm="Cancelar" />
                                </Button>
                                <Button>
                                    <Text id="salvar" dm="Salvar" />
                                </Button>
                                </span>
                            </Btns>

                        </Form>
                    }
                </Content>
            </Container>
        </Center>
    </>
  );
}
