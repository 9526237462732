import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
// import { useSelector } from 'react-redux';

import { isAuthenticated } from '../services/auth';

// Pages
import Login from '../pages/Login';
import Campaign from '../pages/Campaigns';
import DetailsCampaing from '../pages/Campaigns/Details';
import EditCampaing from '../pages/Campaigns/Edit';
import NewCampaign from '../pages/Campaigns/New';

import Bom from '../pages/Settings/Bom';
import BomNew from '../pages/Settings/Bom/New';
import BomDetails from '../pages/Settings/Bom/Details';

import Products from '../pages/Settings/Products';
import ProductsNew from '../pages/Settings/Products/New';
import ProductsDetails from '../pages/Settings/Products/Details';

import ProductReference from '../pages/Settings/ProductReference';
import ProductReferenceNew from '../pages/Settings/ProductReference/New';
import ProductReferenceDetails from '../pages/Settings/ProductReference/Details';

import Company from '../pages/Settings/Company';
import CompanyNew from '../pages/Settings/Company/New';
import CompanyDetails from '../pages/Settings/Company/Details';

import Import from '../pages/Settings/Import';
import ImportSap from '../pages/Settings/ImportSap';

import Toller from '../pages/Settings/Toller';

import Justify from '../pages/Settings/Justify';
import JustifyNew from '../pages/Settings/Justify/New';
import JustifyDetails from '../pages/Settings/Justify/Details';

import GroupSimilar from '../pages/Settings/GroupSimilar';
import GroupSimilarNew from '../pages/Settings/GroupSimilar/New';
import GroupSimilarDetails from '../pages/Settings/GroupSimilar/Details';

import Manual from '../pages/Manual';
import Dashboard from '../pages/Dashboard';
import Alerts from '../pages/Alerts';
// import Traking from '../pages/Traking';
// import TrakingDetails from '../pages/Traking/Details';
import Invoices from '../pages/Invoices';
import AlertsDetails from '../pages/Alerts/Details';

// Exit Pages

// Routes
import RoutesConsume from './consumeRoutes';

const PrivateRoute = ({ component: Component, title, ...rest }) => {
  const location = useLocation();
  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: title,
      page_path: location.pathname + location.search,
      page_location: window.location.href,
    });
  }, [location, title]);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default function Routes() {
  // const { loggedIn } = useSelector((state) => state.auth);
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route title="Login" path="/login" exact component={Login} />
        <PrivateRoute title="Campanhas" path="/" exact component={Campaign} />

        <PrivateRoute
          title="Campanhas"
          path="/campanha"
          exact
          component={Campaign}
        />
        <PrivateRoute
          title="Nova campanha"
          path="/campanha/nova"
          exact
          component={NewCampaign}
        />
        <PrivateRoute
          title="Editar campanha"
          path="/campanha/edit/:id"
          component={EditCampaing}
        />
        <PrivateRoute
          title="Detalhes da campanha"
          path="/campanha/:id"
          component={DetailsCampaing}
        />

        <PrivateRoute
          title="Configurações"
          path="/configuracoes"
          exact
          component={Bom}
        />
        <PrivateRoute
          title="Configurações - BOM"
          path="/configuracoes/bom"
          exact
          component={Bom}
        />
        <PrivateRoute
          title="Configurações - Novo BOM"
          path="/configuracoes/bom/novo"
          exact
          component={BomNew}
        />
        <PrivateRoute
          title="Configurações - Editar BOM"
          path="/configuracoes/bom/:id"
          component={BomDetails}
        />

        <PrivateRoute
          title="Configurações - Produtos"
          path="/configuracoes/produtos"
          exact
          component={Products}
        />
        <PrivateRoute
          title="Configurações - Novo Produto"
          path="/configuracoes/produtos/novo"
          exact
          component={ProductsNew}
        />
        <PrivateRoute
          title="Configurações - Editar Produto"
          path="/configuracoes/produtos/:id"
          component={ProductsDetails}
        />

        <PrivateRoute
          title="Configurações - Referência de Produto"
          path="/configuracoes/referenciaprodutos"
          exact
          component={ProductReference}
        />
        <PrivateRoute
          title="Configurações - Nova Referência de Produto"
          path="/configuracoes/referenciaprodutos/novo"
          exact
          component={ProductReferenceNew}
        />
        <PrivateRoute
          title="Configurações - Editar Referência de Produto"
          path="/configuracoes/referenciaprodutos/:id"
          component={ProductReferenceDetails}
        />

        <PrivateRoute
          title="Configurações - Empresas"
          path="/configuracoes/empresa"
          exact
          component={Company}
        />
        <PrivateRoute
          title="Configurações - Nova Empresa"
          path="/configuracoes/empresa/novo"
          exact
          component={CompanyNew}
        />
        <PrivateRoute
          title="Configurações - Editar Empresa"
          path="/configuracoes/empresa/:id"
          component={CompanyDetails}
        />
        <PrivateRoute
          title="Configurações - Importar"
          path="/configuracoes/importacao/"
          component={Import}
        />
        <PrivateRoute
          title="Configurações - Importar SAP"
          path="/configuracoes/importacaoSap/"
          component={ImportSap}
        />

        <PrivateRoute
          title="Manual do sistema"
          path="/manual/"
          component={Manual}
        />
        <PrivateRoute
          title="Dashboard"
          path="/dashboard/"
          component={Dashboard}
        />
        <PrivateRoute
          title="Alertas"
          path="/alertas/"
          exact
          component={Alerts}
        />
        {/*<PrivateRoute path="/monitoramento/" exact component={Traking} />
        <PrivateRoute
          path="/monitoramento/:id/:itemCod"
          component={TrakingDetails}
        />*/}
        <PrivateRoute
          title="Notas Fiscais"
          path="/nf/:idnf"
          component={Invoices}
        />
        <PrivateRoute
          title="Detalhes do Alerta"
          path="/alertas/:id"
          component={AlertsDetails}
        />
        <PrivateRoute
          title="Configurações - Toller"
          path="/configuracoes/toller/"
          component={Toller}
        />
        <PrivateRoute
          title="Configurações - Justificativas"
          path="/configuracoes/justificativa/"
          exact
          component={Justify}
        />
        <PrivateRoute
          title="Configurações - Nova Justificativa"
          path="/configuracoes/justificativa/novo"
          component={JustifyNew}
          exact
        />
        <PrivateRoute
          title="Configurações - Editar Justificativa"
          path="/configuracoes/justificativa/:id"
          exact
          component={JustifyDetails}
        />

        <PrivateRoute
          title="Configurações - Grupos similares"
          path="/configuracoes/grupo-similar/"
          exact
          component={GroupSimilar}
        />
        <PrivateRoute
          title="Configurações - Novo Grupo similar"
          path="/configuracoes/grupo-similar/novo"
          component={GroupSimilarNew}
          exact
        />
        <PrivateRoute
          title="Configurações - Editar Grupo similar"
          path="/configuracoes/grupo-similar/:id"
          exact
          component={GroupSimilarDetails}
        />

        {/* <PrivateRoute path="*" exact component={Campaign} /> */}
      </Switch>
      <RoutesConsume loggedIn={isAuthenticated()} />
    </BrowserRouter>
  );
}
