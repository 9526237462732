import styled, { css } from "styled-components";
import add from "../../../assets/icons/icon-add.svg";
import calendar from "../../../assets/icons/icon-calendar.png";
// import tracking from "../../../assets/icons/icon-tracking.svg";
import eye from "../../../assets/icons/icon-eye.svg";
import iconDelete from "../../../assets/icons/icon-delete.svg";
import iconfechar from "../../../assets/icons/icon-x.svg";

const spanDataColor = (status) => {
  let background = "#2ea1e8";

  switch (status) {
    case "parAtendido":
      background = "#F39C12";
      break;

    case "atendida":
      background = "#1ABC9C";
      break;

    case "penEmissao":
      background = "#2ea1e8";
      break;

    default:
      background = "#2ea1e8";
      break;
  }

  return background;
};

export const Container = styled.div``;
export const IconSearch = styled.button`
  width: 18px;
  height: 18px;
  background: url(${add}) no-repeat center;
  background-size: cover;
`;
export const BtnClose = styled.div`
  width: 25px;
  height: 25px;
  background: url(${iconDelete}) no-repeat center;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;
export const IconCalendar = styled.button`
  width: 18px;
  height: 18px;
  background: url(${calendar}) no-repeat center;
  background-size: cover;
`;
export const IconTracking = styled.button`
  width: 25px;
  height: 25px;
  background: url(${eye}) no-repeat center;
  background-size: 80%;
`;
export const HeaderDetails = styled.div`
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
  margin-bottom: 60px;
  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
  span {
    display: flex;
    align-items: center;
    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }
  }
`;
export const HeaderItem = styled.div`
  margin: 0 10px;
  p {
    font-size: 14px;
    &:first-child {
      font-weight: 300;
      margin-bottom: 10px;
    }
    strong {
      font-size: 16px;
    }
    img {
      max-width: 100px;
      max-height: 40px;
      width: 100%;
    }
  }
  &.big {
    p strong {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 550px) {
    margin-bottom: 20px;
  }
`;
export const ProductList = styled.div`
  margin-bottom: 40px;
  header {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr 0.2fr;
    margin-bottom: 20px;
    padding: 0 20px;
    p {
      font-size: 14px;
      font-weight: 300;
      &.t-right {
        text-align: right;
      }
    }
  }
`;
export const ItemList = styled.div`
  width: 100%;
  padding: 20px;
  padding-right: 10px;
  border-radius: 6px;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 0.2fr;
  column-gap: 10px;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: all ease 0.1s;
  @media screen and (max-width: 700px) {
    grid-template-columns: 0.8fr 1.5fr 0.8fr;
  }
  p {
    font-size: 14px;
    color: #4c5062;
    font-weight: 300;
    &.t-right {
      text-align: right;
    }
    @media screen and (max-width: 700px) {
      font-size: 10px;
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        display: none;
      }
    }
    &:first-child {
      font-weight: 700;
    }
  }
  .status {
    display: flex;
    justify-content: flex-end;
  }
`;
export const Tit = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
`;
export const MaterialList = styled.div`
  margin-bottom: 40px;
`;
export const MaterialBox = styled.div`
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  header {
    display: grid;
    grid-template-columns: 1fr 2.6fr 0.4fr 0.4fr 3.4fr 1fr 0.8fr 0.8fr 2fr 0.4fr 0.4fr 0.4fr;
    margin-bottom: 20px;
    gap: 10px;
    p {
      font-size: 12px;
      font-weight: 300;
    }
  }
`;
export const ItemMaterial = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.6fr 0.4fr 0.4fr 3.4fr 1fr 0.8fr 0.8fr 2fr 0.4fr 0.4fr 0.4fr;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 5px;
  border-bottom: dotted 2px #cacaca;
  gap: 10px;
  p {
    color: #8b8d97;
    font-size: 12px;
    font-weight: 300;
    &.t-right {
      text-align: right;
    }
  }
  .ptotal {
    border-right: solid 1px #707070;
    padding: 5px 0;
    margin-right: 10px;
    font-weight: 600;
    padding-right: 10px;
    text-align: right;
  }
  .dataprevia {
    padding: 10px;
    border-radius: 10px;
    border: solid 1px #8b8d97;
  }
  input {
    width: 100%;
    padding: 10px;
    border: 0;
    border-radius: 6px;
    font-size: 12px;
    color: #8b8d97;
    border: solid 1px #c2c2c2;
    background: url(${calendar}) no-repeat 90%;
    transition: all ease 0.3s;
    &:hover,
    &:focus {
      background: #fff no-repeat 90%;
    }
  }
`;
export const BoxData = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  form {
    button {
      width: 100%;
    }
  }
`;
export const SpanData = styled.span`
  background: ${(props) =>
    props.status ? spanDataColor(props.status) : "#2ea1e8"};
  border-radius: 6px;
  padding: 10px;
  font-size: 11px;
  color: #fff;
  font-weight: 300;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 116px;
  cursor: pointer;
  strong {
    margin-left: 10px;
  }
`;
export const TotalMaterial = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0 0;
  font-weight: 300;
  color: #707385;
  big {
    font-size: 24px;
    color: #1abc9c;
    margin-left: 20px;
  }
`;
export const ContainerModal = styled.div`
  padding: 40px;
  background: #f2f2f2;
  min-width: 920px;
  border-radius: 20px;
  position: relative;
  form {
    button {
      margin: auto;
    }
  }
`;
export const ContainerModalSmall = styled(ContainerModal)`
  min-width: 360px;
  background: #fff;
  form {
    button {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    input {
      margin-bottom: 20px;
    }
  }
`;
export const TitleModal = styled.p`
  font-size: 20px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: solid 1px #fff;
`;
export const QuantityModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px #fff;
  h2 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  span {
    display: flex;
    p {
      font-size: 14px;
      margin-right: 20px;
    }
  }
`;
export const ContentListModal = styled(ProductList)`
  margin-bottom: 0;
  header {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 0.2fr;
  }
`;
export const ContentListInvoiceModal = styled(ProductList)`
  margin-bottom: 0;
  header {
    grid-template-columns: 1fr 2.5fr 2fr 2fr 2fr 1.5fr 0.2fr;
  }
`;
export const ContentListPredictionModal = styled(ProductList)`
  margin-bottom: 0;
  header {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;
export const ContentListComexModal = styled(ProductList)`
  margin-bottom: 0;
  header {
    grid-template-columns: 1.4fr 1fr 2fr 1fr 1.5fr;
  }
`;
export const ListModal = styled(ProductList)`
  max-height: 45vh;
  overflow-y: scroll;
`;
export const ItemListModal = styled(ItemList)`
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 0.2fr;
`;
export const ItemListInvoiceModal = styled(ItemList)`
  grid-template-columns: 1fr 2.5fr 2fr 2fr 2fr 1.5fr 0.2fr;
`;
export const ItemListPredictionModal = styled(ItemList)`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`;
export const ItemListComexModal = styled(ItemList)`
  grid-template-columns: 1.4fr 1fr 2fr 1fr 1.5fr;
`;
export const BoxaddData = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  .content {
    background: #fff;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 20px;
  }
  .info {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #ccc;
    p {
      font-size: 14px;
    }
  }
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 360px;
  }
  input {
    margin-bottom: 20px;
  }
  .sc-fzXfLX {
    width: 100%;
  }
`;
export const BtnFechar = styled.div`
  background: url(${iconfechar}) no-repeat center;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;
export const BoxStatus = styled.div`
  width: 100%;
  background: #ccc;
  font-size: 12px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  &.atendida {
    background: #1abc9c;
  }
  &.atrasada {
    background: #ff4e50;
  }
`;

export const FilterLine = styled.div`
  width: 100%;
  margin: 32px 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
export const FilterInput = styled.li`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  & + li {
    margin-left: 32px;
  }

  &.reset {
    width: 25px;

    div {
      height: 25px;
      width: 25px;
      padding: 0;
      margin: 0;
    }
  }

  img {
    width: 14px;
    height: 14px;
  }

  p {
    margin: 8px 0 0 0;
    padding: 0;
    font-size: 14px;
  }

  input {
    width: 100%;
    background: transparent;
    border: 0;
    border-bottom: solid 1px #292d41;
    font-size: 14px;
    font-weight: 300;
    padding: 0 0 0 20px;
    margin-left: 8px;
    &::placeholder {
      color: #8b8d97;
    }
  }

  select {
    width: 100%;
    background: transparent;
    border: 0;
    border-bottom: solid 1px #292d41;
    font-size: 14px;
    font-weight: 300;
    padding: 0 0 0 20px;
    margin-left: 8px;
    &::placeholder {
      color: #8b8d97;
    }
  }
`;
