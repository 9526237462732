import React from "react";

import imgExcel from "../../assets/excel.png";

import { Container } from "./styles";

export default function BtnExcel({ text, disabled, onClick }) {
  return (
    <Container type="button" disabled={disabled} onClick={onClick}>
      <span>
        <img src={imgExcel} alt="" />
      </span>
      <p>{text}</p>
    </Container>
  );
}
