import styled from "styled-components";

import { BarLoader } from "react-spinners";
export const Container = styled.div``;
export const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const HeaderList = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1.2fr 1fr 1fr 0.7fr 0.9fr 0.1fr;
  padding: 0 20px;
  margin-bottom: 20px;
  .center{
    justify-self:center;
  }
  p {
    font-size: 14px;
    @media screen and (max-width: 700px) {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        display: none;
      };
    };
  }
`;

export const List = styled.div``;
export const ItemList = styled.div`
  width: 100%;
  padding: 20px;
  padding-right: 10px;
  border-radius: 6px;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 2fr 1.2fr 1fr 1fr 0.7fr 0.9fr 0.1fr;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: all ease 0.1s;
  &:hover {
    transform: scale(1.01);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 0.8fr 1.5fr 0.8fr;
  }
  p {
    font-size: 12px;
    color: #8b8d97;
    @media screen and (max-width: 700px) {
      font-size: 10px;
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        display: none;
      }
    }
    &:first-child {
      font-weight: 700;
    }
  }
  span {
    justify-self:center;
    font-size: 12px;
    color:#1b1c1e;
    @media screen and (max-width: 700px) {
      font-size: 10px;
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        display: none;
      }
    }
    &:first-child {
      font-weight: 700;
    }
  }
  .status {
    display: flex;
    justify-content: flex-end;
  }
`;
export const Err = styled.div`
  font-size: 10px;
  color: red;
  margin: 5px 0;
`;
export const ErrFloat = styled(Err)`
  position: absolute;
`;
export const LoadingBtn = styled(BarLoader)``;

export const Pagination = styled.div`
  display: flex;
  margin: 20px 0;
  button {
    padding: 0 20px;
    height: 50px;
    background: #fff;
    border: solid 1px #dddddd;
    &:disabled {
      opacity: 0.5;
    }
    &.primary {
      background: #1abc9c;
      color: #fff;
      border: 0;
    }
  }
  @media screen and (max-width: 700px) {
    button {
      padding: 0 8px;
      height: 30px;
      font-size: 12px;
    }
  }
`;
