import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useValidation } from "../../../hooks";
import ActionsBranchInvoiceCreate from "../../../store/ducks/branch-invoices/create";
import NotaFullSearch from "../../../store/ducks/notafull/search";
import OrdersComboboxActions from "../../../store/ducks/orders/orders-combobox";
import { useDebounce } from "../../../hooks/"

import * as S from "./styles";
import { InputFile, Select } from "../../shared";
import { format } from "date-fns";

const UploadXML = ({ onUploaded }) => {
  const [open, setOpen] = useState(false);
  const [defaultChoice, setDefaulChoice] = useState(true);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { handleFormErrors } = useValidation();
  const { loading } = useSelector((state) => state.branchInvoiceCreate);
  const { loading: comboboxLoading, data: comboboxData } = useSelector(
    (state) => state.ordersCombobox
  );
  const { loading: notafullLoading, data: notaFullList } = useSelector(
    (state) => state.notaFullSearch
  );

  const notaFullOptions = useMemo(() => {

    if(!notaFullList) {
      return []
    }

    const options = notaFullList.map(nota => {
      const datePT = format(new Date(nota.notafull_dt_emiss), "dd/MM/yyyy")
      return {
        value: nota.notafull_id,
        label: `${nota.notafull_nf_num}-${nota.notafull_nf_ser} (${datePT})`
      }
    })

    return options;
    
  }, [notaFullList])

  const [searchNF, setSearchNF] = useState("");
  const debouncedSearchNF = useDebounce(searchNF, 500);

  const onSuccess = useCallback(({ reset }, refresh) => {
    reset();
    refresh();
    setOpen(false);
  }, []);

  const getComboboxData = useCallback(() => {
    dispatch(OrdersComboboxActions.request());
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      const defaultSchema = Yup.object().shape({
        xml: Yup.string().required("Selecione um arquivo XML"),
        alfa_id: Yup.string().required("Selecione uma campanha"),
      });

      const newSchema = Yup.object().shape({
        nf_number: Yup.string().required("Selecione uma NF"),
        alfa_id: Yup.string().required("Selecione uma campanha"),
      });

      try {
        formRef.current.setErrors({});
        
        if(defaultChoice) {
          await defaultSchema.validate(data, {
            abortEarly: false,
          });
        } else {
          await newSchema.validate(data, {
            abortEarly: false,
          });
        }

        const formData = new FormData();
        formData.append("alfa_id", data.alfa_id);
        
        if(defaultChoice) {
          formData.append("xml", data.xml);
        } else {
          formData.append("nf_number", data.nf_number);
        }

        dispatch(
          ActionsBranchInvoiceCreate.request(formData, () =>
            onSuccess({ reset }, onUploaded)
          )
        );
      } catch (error) {
        console.log(error)
        handleFormErrors(error, formRef);
      }
    },
    [defaultChoice, dispatch, handleFormErrors, onSuccess, onUploaded]
  );

  useEffect(() => {
    getComboboxData();
  }, [getComboboxData]);

  useEffect(
    () => {
      dispatch(NotaFullSearch.request({
        search: debouncedSearchNF
      }));
    },
    [debouncedSearchNF, dispatch]
  );

  const handleChoiceChange = () => {
    setDefaulChoice(prev => !prev)
  }

  return (
    <>
      <S.ButtonMini
        btStyle="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Importar NFe
      </S.ButtonMini>
      <S.Container open={open}>
        <S.Box>
          <S.Head>
            <S.Title>
              <S.IconFile />
              Importar NFe
            </S.Title>
          </S.Head>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.WrapInputs>
              <Select
                name="alfa_id"
                label="Campanha"
                options={comboboxData}
                isLoading={comboboxLoading}
                isDisabled={comboboxLoading}
                placeholder="Selecione..."
              />
            </S.WrapInputs>
            <S.ButtonGroup>
              <S.ButtonChoice
                type="button"
                active={defaultChoice ?? undefined}
                onClick={() => handleChoiceChange()}
              >
                Importar por XML
              </S.ButtonChoice>
              <S.ButtonChoice
                type="button"
                active={!defaultChoice ?? undefined}
                onClick={() => handleChoiceChange()}
              >
                Importar por NF
              </S.ButtonChoice>
            </S.ButtonGroup>
            <S.WrapInputs>
              {defaultChoice ? (
                <InputFile name="xml" label="XML Nota fiscal" />
              ): (
                <Select
                  name="nf_number"
                  label="Número da NF"
                  options={notaFullOptions}
                  isLoading={notafullLoading}
                  isDisabled={notafullLoading}
                  onInputChange={(value) => setSearchNF(value)}
                  placeholder="Procurar NF..."
                />
              )}
            </S.WrapInputs>
            <S.WrapButtons>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => setOpen(false)}
              >
                Voltar
              </S.Button>
              <S.Button btStyle="primary" type="submit">
                {loading ? <S.OnLoading /> : "Importar"}
              </S.Button>
            </S.WrapButtons>
          </Form>
        </S.Box>
      </S.Container>
    </>
  );
};

export default UploadXML;
