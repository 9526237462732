import { call, put, select } from "redux-saga/effects";
import { notify } from "../../../services/notification";
import api, { makeOptions, queryBuilder } from "../../../services/api";
import ActionsDeleteJustification from "../../ducks/compare/delete-justification";

const getAuthData = (state) => state.auth.data;

export function* destroy(action) {
  try {
    const { params, onSuccess } = action;
    const query = params ? queryBuilder(params) : "";
    const url = `branch-invoice-justification?${query}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.delete, url, makeOptions(authData));

    yield put(ActionsDeleteJustification.success(data.data));
    notify("success", "Justificativa deletada com sucesso!");
    onSuccess();
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ActionsDeleteJustification.failure(err.response.data.error.message)
    );
  }
}
