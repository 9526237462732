import styled from "styled-components";

export const Container = styled.div``;

export const ContentMenu = styled.div`
  width: 240px;
  min-height: 100%;
  position: relative;
  background: #292d41;
  padding: 30px;
  box-sizing: border-box;
  border-left: solid 1px #333;
  transition: all ease 0.3s;
  box-shadow: -8px 0px 20px rgba(41, 45, 65, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .icon-menu {
    float: right;
  }
  .user {
    text-align: center;
    margin-bottom: 40px;
    padding-top: 40px;
    img {
      margin-bottom: 20px;
    }
    p {
      color: #fff;
      font-size: 14px;

      small {
        font-size: 12px;
        color: #8b8d97;
      }
    }
  }
  nav {
    margin: 20px 0;
    min-width: 150px;
    a {
      display: block;
      font-size: 14px;
      font-weight: 300;
      color: #fff;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: solid 1px #4c5062;
      transition: all ease 0.3s;
      text-align: left;
      text-decoration: none;
      &:last-child {
        border-bottom: none;
      }
      &:hover,
      &.router-link-exact-active,
      &.router-link-active {
        color: #3498db;
      }
    }
  }
`;

export const Logout = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: all ease 0.3s;
  p {
    color: #fff;
    transition: all ease 0.3s;
    font-size: 14px;
    margin-right: 20px;
  }
  &:hover {
    p {
      color: #f39c12;
      margin-right: 40px;
    }
  }
`;

export const UserImage = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
`;

export const BoxLast = styled.div`
  /* position: absolute;
  bottom: 0;
  left: 0; */
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
`;

export const Gerasinergia = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
  img {
    max-width: 100px;
  }
  p {
    color: #7b819e;
    font-size: 10px;
    font-weight: 300;
    margin-bottom: 10px;
  }
`;
