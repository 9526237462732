import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
    justify-content: center;
	width: 40px;
    height: 40px;
    background: #1ABC9C;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: all ease 0.2s;
    cursor: pointer;
    &:hover{
        transform: scale(1.05);
    }
`