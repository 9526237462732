import moment from 'moment';

export function ValidateImportFields(data,accepted, rejected) {
    let bloco1 = [];
    let bloco2 = [];
    let bloco3 = [];
    
    data.forEach((item)=> {

        //MONTAGEM DO BLOCO 1
        const cod_materia_prima = item['COD. MAT. PRIMA'] ?  item['COD. MAT. PRIMA'] : null;
        const materia_prima =  item['MATERIA PRIMA'] ? item['MATERIA PRIMA'] : null;
        const cod_produto_final = item['COD. PRODUTO FINAL'] ? item['COD. PRODUTO FINAL']: null;
        const quantidade = item['QUANTIDADE'] ? item['QUANTIDADE']: null;
        const data_entrega = item['DATA DE ENTREGA'] ? item['DATA DE ENTREGA'] : null;
        
        //CONDICAO PARA PROSSEGUIR NA VALIDACAO BLOCO 1
        const allNullBlock1 = !cod_materia_prima && !materia_prima && !cod_produto_final && !quantidade && !data_entrega;

        //MONTAGEM DO BLOCO 2
        const cod_produto_acabado = item['COD. PROD. ACAB.'] ? item['COD. PROD. ACAB.'] : null;
        const produto_acabado = item['PRODUTO ACABADO'] ? item['PRODUTO ACABADO'] : null;
        const quantidace_final = item['QUANTIDADE FINAL'] ? item['QUANTIDADE FINAL'] : null;

        //CONDICAO PARA PROSSEGUIR NA VALIDACAO BLOCO 2
        const allNullBlock2 = !cod_produto_acabado && !produto_acabado && !quantidace_final;

        //MONTAGEM DO BLOCO 3
        const cnpj_toller = item['CNPJ TOLLER'] ? item['CNPJ TOLLER'] : null;
        const descricao = item['DESCRICAO'] ? item['DESCRICAO'] : null;
        const data_pedido =  item['DATA PEDIDO'] ? item['DATA PEDIDO'] : null;
        const data_vencimento = item['DATA VENCIMENTO'] ? item['DATA VENCIMENTO'] : null;
        
        //CONDICAO PARA PROSSEGUIR NA VALIDACAO BLOCO 3
        const allNullBlock3 = !cnpj_toller && !descricao && !data_pedido && !data_vencimento;

        if(!allNullBlock1) {
            bloco1.push({
                cod_materia_prima,
                materia_prima,
                cod_produto_final,
                quantidade,
                data_entrega,
            });
        }

        if(!allNullBlock2) {
            bloco2.push({
                cod_produto_acabado,
                produto_acabado,
                quantidace_final
            });
        }

        if(!allNullBlock3) {
            bloco3.push({
                cnpj_toller,    
                descricao,
                data_pedido,
                data_vencimento     
            });
        }

    });

    if(bloco1.length > 0){
        bloco1.forEach((item, index)=> {
            const b1_cod_mat_prima = item.cod_materia_prima ? true : false;
            const b1_cod_cod_prod_final = item.cod_produto_final ? true : false;
            const b1_data_entrega = moment(item.data_entrega, 'dd/mm/yyyy',true).isValid();
            const b1_materia_prima = item.materia_prima ? true : false;
            const b1_quantidade = item.quantidade ? true: false;
    
            if(b1_cod_mat_prima) {
                accepted.push(`Código da matéria prima cadastrado a partir da linha ${index} com o valor ${item.cod_materia_prima}`);  
            } else {
                rejected.push(`Código da matéria prima invalido no bloco 1 na linha ${index}`)
            }
    
            if(b1_cod_cod_prod_final) {
                accepted.push(`Código do produto final cadastrado a partir da linha ${index} com o valor ${item.cod_produto_final}`);  
            } else {
                rejected.push(`Código do produto final invalido no bloco 1 na linha ${index}`)
            }
    
            if(b1_data_entrega) {
                accepted.push(`Data de entrega cadastrada a partir da linha ${index} com o valor ${item.data_entrega}`);  
            } else {
                rejected.push(`Data de entrega invalida no bloco 1 na linha ${index}`)
            }
    
            if(b1_materia_prima) {
                accepted.push(`Matéria prima cadastrada a partir da linha ${index} com o valor ${item.materia_prima}`);  
            } else {
                rejected.push(`Matéria prima invalida no bloco 1 na linha ${index}`)
            }
    
            if(b1_quantidade) {
                accepted.push(`Quantidade cadastrada a partir da linha ${index} com o valor ${item.quantidade}`);  
            } else {
                rejected.push(`Quantidade invalida no bloco 1 na linha ${index}`)
            }
        });
    } else {
        rejected.push(`O BLOCO 1 ESTA VAZIO !`)
    }
    
    if(bloco2.length > 0) {
        bloco2.forEach((item, index)=> {
            const b2_cod_prod_acabado = item.cod_produto_acabado ? true : false;
            const b2_prod_acabado = item.produto_acabado ? true: false;
            const b2_qtd_final = item.quantidace_final ? true: false;
    
            if(b2_cod_prod_acabado) {
                accepted.push(`Código do produto acabado cadastrado a partir da linha ${index} com o valor ${item.cod_produto_acabado}`);  
            } else {
                rejected.push(`Código do produto acabado invalido no bloco 1 na linha ${index}`)
            }
    
            if(b2_prod_acabado) {
                accepted.push(`Produto acabado cadastrado a partir da linha ${index} com o valor ${item.produto_acabado}`);  
            } else {
                rejected.push(`Produto acabado invalido no bloco 1 na linha ${index}`)
            }
    
            if(b2_qtd_final) {
                accepted.push(`Quantidade final cadastrada a partir da linha ${index} com o valor ${item.quantidace_final}`);  
            } else {
                rejected.push(`Quantidade final invalida no bloco 1 na linha ${index}`)
            }
    
        });

    } else {
        rejected.push(`O BLOCO 2 ESTA VAZIO !`)
    }
    
    if(bloco3.length > 0){
        bloco3.forEach((item, index)=>{
            const b3_cnpj_toller = item.cnpj_toller ? true : false;
            const b3_descricao = item.descricao ? true: false;
            const b3_data_pedido = moment(item.data_pedido, 'dd/mm/yyyy',true).isValid();
            const b3_data_vencimento = moment(item.data_vencimento, 'dd/mm/yyyy',true).isValid();
    
            if(b3_cnpj_toller) {
                accepted.push(`CNPJ Toller cadastrado a partir da linha ${index} com o valor ${item.cnpj_toller}`);  
            } else {
                rejected.push(`CNPJ Toller invalido no bloco 1 na linha ${index}`)
            }
    
            if(b3_descricao) {
                accepted.push(`Descrição cadastrada a partir da linha ${index} com o valor ${item.descricao}`);  
            } else {
                rejected.push(`Descrição invalida no bloco 1 na linha ${index}`)
            }
    
            if(b3_data_pedido) {
                accepted.push(`Data do pedido cadastrada a partir da linha ${index} com o valor ${item.data_pedido}`);  
            } else {
                rejected.push(`Data do pedido invalido no bloco 1 na linha ${index}`)
            }
    
            if(b3_data_vencimento) {
                accepted.push(`Data de vencimento cadastrada a partir da linha ${index} com o valor ${item.data_vencimento}`);  
            } else {
                rejected.push(`Data de vencimento invalida no bloco 1 na linha ${index}`)
            }
        });

    } else {
        rejected.push(`O BLOCO 3 ESTA VAZIO !`)
    }
}
