import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { useDebounce, useValidation } from "../../../hooks";
import ActionsBranchRawInvoiceCreate from "../../../store/ducks/branch-raw-invoices/create";
import NotaFullSearch from "../../../store/ducks/notafull/search";

import * as S from "./styles";
import { InputFile, Select } from "../../shared";
import { format } from "date-fns";

const UploadRawMaterial = ({ onUploaded, branchInvoiceId, invoiceNumber }) => {
  const [open, setOpen] = useState(false);
  const [defaultChoice, setDefaulChoice] = useState(true);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { handleFormErrors } = useValidation();
  const { loading, progress } = useSelector(
    (state) => state.branchRawInvoiceCreate
  );

  const { loading: notafullLoading, data: notaFullList } = useSelector(
    (state) => state.notaFullSearch
  );

  const notaFullOptions = useMemo(() => {
    if (!notaFullList) {
      return [];
    }

    const options = notaFullList.map((nota) => {
      const datePT = format(new Date(nota.notafull_dt_emiss), "dd/MM/yyyy");
      return {
        value: nota.notafull_id,
        label: `${nota.notafull_nf_num}-${nota.notafull_nf_ser} - Emitido em ${datePT}`,
      };
    });

    return options;
  }, [notaFullList]);

  const [searchNF, setSearchNF] = useState("");
  const debouncedSearchNF = useDebounce(searchNF, 500);

  const onSuccess = useCallback(({ reset }, refresh) => {
    reset();
    refresh();
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      const defaultSchema = Yup.object().shape({
        xml: Yup.string().required("Selecione um arquivo XML"),
      });

      const newSchema = Yup.object().shape({
        nf_number: Yup.string().required("Selecione uma NF"),
      });

      try {
        formRef.current.setErrors({});

        if (defaultChoice) {
          await defaultSchema.validate(data, {
            abortEarly: false,
          });
        } else {
          await newSchema.validate(data, {
            abortEarly: false,
          });
        }

        const formData = new FormData();

        if (defaultChoice) {
          formData.append("xml", data.xml);
        } else {
          formData.append("nf_number", data.nf_number);
        }

        formData.append("branch_invoice_id", branchInvoiceId);

        dispatch(
          ActionsBranchRawInvoiceCreate.request(formData, () =>
            onSuccess({ reset }, onUploaded)
          )
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      branchInvoiceId,
      defaultChoice,
      dispatch,
      handleFormErrors,
      onSuccess,
      onUploaded,
    ]
  );

  useEffect(() => {
    dispatch(
      NotaFullSearch.request({
        search: debouncedSearchNF,
      })
    );
  }, [debouncedSearchNF, dispatch]);

  const handleChoiceChange = () => {
    setDefaulChoice((prev) => !prev);
  };

  return (
    <>
      <S.ButtonMini
        btStyle="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Importar NFe Matéria Prima
      </S.ButtonMini>

      <S.Container open={open}>
        <S.Box>
          <S.Head>
            <S.Title>
              <S.IconFile />
              Importar NFe Matéria Prima
            </S.Title>
            <S.Title>NF {invoiceNumber}</S.Title>
          </S.Head>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.ButtonGroup>
              <S.ButtonChoice
                type="button"
                active={defaultChoice ?? undefined}
                onClick={() => handleChoiceChange()}
              >
                Importar por XML
              </S.ButtonChoice>
              <S.ButtonChoice
                type="button"
                active={!defaultChoice ?? undefined}
                onClick={() => handleChoiceChange()}
              >
                Importar por NF
              </S.ButtonChoice>
            </S.ButtonGroup>
            <S.WrapInputs>
              {defaultChoice ? (
                <InputFile name="xml" label="XML Nota Fiscal" />
              ) : (
                <Select
                  name="nf_number"
                  label="Número NF"
                  options={notaFullOptions}
                  isLoading={notafullLoading}
                  isDisabled={notafullLoading}
                  onInputChange={(value) => setSearchNF(value)}
                  placeholder="Procurar NF..."
                />
              )}
            </S.WrapInputs>
            <S.WrapButtons>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => setOpen(false)}
              >
                Voltar
              </S.Button>
              <S.Button
                btStyle="primary"
                className={loading ? "run" : ""}
                type="submit"
                progress={progress}
              >
                {Boolean(progress) ? "" : !loading && "Importar"}
              </S.Button>
            </S.WrapButtons>
          </Form>
        </S.Box>
      </S.Container>
    </>
  );
};

export default UploadRawMaterial;
