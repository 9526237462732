import React from 'react';

import { Paginator } from '../../shared';
import Item from '../Item';
import * as S from './styles';

const List = ({ invoices, pagination, onPageChange, justification }) => {
  return (
    <S.Container>
      <S.Header>
        <S.Ghost/>
        <S.Head>
          <S.Label>Id</S.Label>
          <S.Label>Campanha</S.Label>
          <S.Label>NF</S.Label>
          <S.Label>Dt. Emiss</S.Label>
          <S.Label>Qtd. NF</S.Label>
          <S.Label>Produto</S.Label>
          <S.Label>Toller</S.Label>
          <S.Label className="center">Pendente de Consumo SAP</S.Label>
          <S.Label className='center'>Importação NF de Matéria Prima</S.Label>
        </S.Head>
      </S.Header>
      {invoices?.map((invoice, index) => (
        <Item key={invoice.id} invoice={invoice} selected={index} justification={justification}/>
      ))}
      <Paginator
        pagination={pagination}
        onPageChange={onPageChange}
        neighbors={4}
      />
    </S.Container>
  );
};

export default List;
