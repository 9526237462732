import React, { useState } from "react";
import XLSX from "xlsx";

import api from "../../../services/api";
// Components
import Header from "../../../components/Header";
import AsideMenu from "../../../components/AsideMenu";
import LoadPage from "../../../components/LoadPage";
import BtnBack from "../../../components/BtnBack";
import Text from "../../../components/Text";

//Images
import iconTit from "../../../assets/icons/icon-campaing.svg";
import iBom from "../../../assets/icons/icon-bom.svg";

//CSS
import { Center, TopHeader } from "../../../styles/style";

//VALIDATORS
import { ValidateImportFields } from "./validators";

import * as S from "./styles";

export default function Import({ history }) {
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rejected, setRejected] = useState([]);
  const [accepted, setAccepted] = useState([]);

  const getWorkbookFromFile = async (excelFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary", cellDates: true });
        resolve(workbook);
      };
      reader.readAsBinaryString(excelFile);
    });
  };

  const handleImport = async (file) => {
    const name = file[0].name.split(".");
    const index = name.length - 1;
    if (
      name[index] === "xlsx" ||
      name[index] === "xls" ||
      name[index] === "XLSX" ||
      name[index] === "XLS"
    ) {
      setLoading(true);
      setFileData([]);
      const workbook = await getWorkbookFromFile(file[0]);
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const firstColumn = first_worksheet.A1 ? first_worksheet.A1.v : null;
      const data = await XLSX.utils.sheet_to_json(first_worksheet, {
        header: 0,
      });

    ValidateImportFields(data,accepted,rejected)

     if(rejected.length === 0){
      await sendImport(data);
      setLoading(false);
      setRejected([]);
      setAccepted([]);

     } else {
      setFileData([{failures: rejected}]);
      setLoading(false);
      setRejected([]);
      setAccepted([]);

     }
      
    } else {
      setFileData([{ error: "NÃO É UM ARQUIVO VÁLIDO!" }]);
      setLoading(false);
    }
  };

  const sendImport = async (data) => {
    await api
      .post(`order/import/`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        // console.log("###", res.data);
        setFileData(res.data);
      })
      .catch((error) => {
        setFileData([
          { error: "Erro no envio da planilha. Favor tentar novamente!" },
        ]);
      });
  };

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="config.title" dm="Configurações" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>
        <S.Container>
          <AsideMenu />
          <S.Content>
            <header>
              <h2>
                <img src={iBom} alt="" />
                <Text id="imp.nova.campanha" dm="Importação de nova Campanha" />
              </h2>
            </header>

            {/* Editar aqui  */}
            <S.ImportContent>
              {!loading && (
                <input
                  type="file"
                  onChange={(event) =>
                    event.target.files[0] && handleImport(event.target.files)
                  }
                  name=""
                  placeholder=""
                />
              )}
              <S.ListAlerts>
                {fileData[0] && (
                  <>
                    {fileData[0].codigo || fileData[0].error ? (
                      <>
                        <ul>
                          {fileData.map((data) => (
                            <li key={data.codigo}>
                              {!data.error ? (
                                <>
                                  <S.ErroTxt>ERROR</S.ErroTxt>
                                  <p>
                                    <Text id="nome" dm="Nome" />:{" "}
                                    <strong>{data.nome}</strong>
                                  </p>
                                  <p>
                                    <Text id="codigo" dm="Código" />:{" "}
                                    <strong>{data.codigo}</strong>
                                  </p>
                                </>
                              ) : (
                                <>
                                  <S.ErroTxt>{data.error}</S.ErroTxt>
                                </>
                              )}
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : fileData[0].failures ? (
                      <>
                      <div className="failure-alert">
                       <h3 className="failure">Falha na importação</h3>
                       <p>Valores invalidos, favor tentar novamente aplicando as devidas correções</p>
                      </div>
                      
                       <ul>
                     {fileData[0].failures.map((item,index)=> {
                       return (
                         <li key={index}>
                           <strong>{item}</strong>
                         </li>
                         )
                      })}
                       </ul>
                      </>
                    ) : (
                      <>
                        <big>Campanha Cadastrada</big>
                        {fileData.map((data) => (
                          <div key={data.id}>
                            <S.TitList>Produto</S.TitList>
                            <S.BoxTitle>
                              <p>
                                Nome: <strong>{data.name}</strong>
                              </p>
                              <p>
                                Quantidade: <strong>{data.quantity}</strong>
                              </p>
                            </S.BoxTitle>

                            <S.TitList>Materiais</S.TitList>
                            <ul>
                              {data.materials &&
                                data.materials.map((material) => (
                                  <li key={material.id}>
                                    <p>
                                      Nome: <strong>{material.name}</strong>
                                    </p>
                                    <p>
                                      Quantidade:{" "}
                                      <strong>{material.quantity}</strong>
                                    </p>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}

                {!fileData[0] && loading && <LoadPage />}
              </S.ListAlerts>
            </S.ImportContent>
            {/* --------- */}
          </S.Content>
        </S.Container>
      </Center>
    </>
  );
}
