import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useValidation } from '../../../hooks';
import ActionsSapCreateMultiples from '../../../store/ducks/sap/create-multiples';

import * as S from './styles';
import { InputFile } from '../../shared';

const UploadMultiplesSAP = () => {
  const [open, setOpen] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { handleFormErrors } = useValidation();
  const { loading, data } = useSelector((state) => state.sapCreateMultiples);

  const onSuccess = useCallback(({ reset }) => {
    reset();
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    dispatch(ActionsSapCreateMultiples.reset());
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          xls: Yup.string().required('Selecione um arquivo XLS'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = new FormData();
        formData.append('xls', data.xls);

        dispatch(
          ActionsSapCreateMultiples.request(formData, () =>
            onSuccess({ reset })
          )
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );

  const renderResponde = useCallback(() => {
    if (data) {
      const { importSuccess, importError } = data;

      return (
        <S.ResponseWrapper>
          {importSuccess &&
            importSuccess.map((importSuccess) => (
              <S.ResponseSuccess key={importSuccess.invoiceNumber}>
                {importSuccess.invoiceNumber} - Arquivo cadastrado com sucesso
              </S.ResponseSuccess>
            ))}
          {importError.length > 0 &&
            importError.map((importError) => (
              <S.ResponseError key={importError.invoiceNumber}>
                {importError.invoiceNumber} - {importError.error?.message}
              </S.ResponseError>
            ))}
        </S.ResponseWrapper>
      );
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(ActionsSapCreateMultiples.reset());
    };
  }, [dispatch]);

  return (
    <>
      <S.ButtonMini btStyle="primary" onClick={() => setOpen(true)}>
        Importar planilha SAP (Múltiplas NFs)
      </S.ButtonMini>
      <S.Container open={open}>
        <S.Box>
          <S.Head>
            <S.Title>
              <S.IconFile />
              Importar planilha SAP (Múltiplas NFs)
            </S.Title>
          </S.Head>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.WrapInputs>
              <InputFile name="xls" label="XLS SAP com multiplas NFs" />
            </S.WrapInputs>
            {renderResponde()}
            <S.WrapButtons>
              <S.Button btStyle="cancel" type="button" onClick={onClose}>
                Voltar
              </S.Button>
              <S.Button btStyle="primary" type="submit">
                {loading ? <S.OnLoading /> : 'Upload'}
              </S.Button>
            </S.WrapButtons>
          </Form>
        </S.Box>
      </S.Container>
    </>
  );
};

export default UploadMultiplesSAP;
