import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    request: ['data', 'onSuccess'],
    success: ['data'],
    failure: ['error'],
    reset: [],
  },
  { prefix: 'SAP_CREATE_MULTIPLES_' }
);

export const SapCreateMultiplesTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

/* Reducers */

export const _request = (state) =>
  update(state, { loading: { $set: true }, error: { $set: null } });

export const _success = (state, action) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

export const _failure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

export const _reset = () => INITIAL_STATE;

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: _request,
  [Types.SUCCESS]: _success,
  [Types.FAILURE]: _failure,
  [Types.RESET]: _reset,
});
