import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import api from "../../../services/api";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";
import Select from "react-select";
import moment from "moment";

// Helpers
import replaceComma from "../../../helpers/replaceComma";

// Components
import Header from "../../../components/Header";
import LoadPage from "../../../components/LoadPage";
import AddProducts from "./addProducts";
import BtnBack from "../../../components/BtnBack";
import Text from "../../../components/Text";

//Images
import iconTit from "../../../assets/icons/icon-campaing.svg";
import iconeye from "../../../assets/icons/icon-eye.svg";

//CSS
import "react-datepicker/dist/react-datepicker.css";
import {
  Center,
  TopHeader,
  Form,
  DescriptionTitle,
  BoxForm,
  Row,
  InputBox,
  Button,
  Btns,
} from "../../../styles/style";

import { Err, LoadingBtn, ContainerFlex } from "../styles";

registerLocale("pt-BR", pt);

export default function Edit({ history }) {
  const { register, handleSubmit, errors, control } = useForm();

  const [companies, setCompanies] = useState([]);
  const [datasolicitacao, setDatasolicitacao] = useState(null);
  const [dataentrega, setDataentrega] = useState(new Date());
  const [order, setOrder] = useState();
  const [bom, setBom] = useState();
  const [loading, setLoading] = useState(true);
  const [txtBtn, setTxtBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [txtDelete, setTxtDelete] = useState(true);
  const [loadBtnDelete, setloadBtnDelete] = useState(false);
  const [justifications, setJustifications] = useState([]);

  let { id } = useParams();
  const msgsuccess = (text) => toast.success(text);
  const msgerror = (text) => toast.error(text);
  
  async function load(url, set) {
    await api
      .get(url)
      .then((result) => {
        set(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function loadData() {
    try {
      const response = await api.get(`/order/${id}`);

      setOrder(response.data.data);
      setDatasolicitacao(new Date(response.data.data.request_date));
      setDataentrega(new Date(response.data.data.deadline_date));

      await load(
        `/bom?filters[0][equal][branch_id]=${
          response.data.data.branch_id
        }&filters[1][equal][bom_type_id]=${2}&perPage=false`,
        setBom
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function loadJustifications() {
    try {
      const response = await api.get("justification-n?perPage=false");
      setJustifications(response.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    async function loadModules() {
      await loadData();
      await loadJustifications();
      await load(`/branch-n?perPage=false`, setCompanies);
      setLoading(false);
    }
    loadModules();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSubmit(data, e) {
    setLoadingBtn(true);
    setTxtBtn(false);
    await api
      .patch(`/order/${id}`, {
        branch_id: data.toller.id,
        description: data.descricao,
        request_date: datasolicitacao,
        deadline_date: dataentrega,
        justification_id: data.justification,
      })
      .then((result) => {
        setLoadingBtn(false);
        setTxtBtn(true);
        msgsuccess("Campanha Alterada!");
        e.target.reset();
        history.push("/campanha");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Delete bom
  async function deleteCampaing() {
    setloadBtnDelete(true);
    setTxtDelete(false);

    withSwalInstance(
      swal
        .fire({
          title: "Deletar a Campanha?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#FF4E50",
          cancelButtonColor: "#ccc",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Deletar",
        })
        .then(async (result) => {
          if (result.value) {
            await api
              .delete(`/order/${id}`)
              .then((result) => {
                setloadBtnDelete(false);
                setTxtDelete(true);
                msgsuccess("Campanha removida");
                history.push("/campanha");
                swal.fire("Excluído!", "Campanha foi excluída", "success");
              })
              .catch((err) => {
                console.log(err);
                msgerror(err.response.data.error.message);
                setloadBtnDelete(false);
                setTxtDelete(true);
              });
          } else {
            setloadBtnDelete(false);
            setTxtDelete(true);
          }
        })
    );
  }

  // Add Bom
  async function addBom(data) {
    const quantityFormatted = replaceComma(data.quantity);

    const dataFormatted = {
      ...data,
      quantity: quantityFormatted,
    };

    await api.post(`/order-bom/`, dataFormatted).then((result) => {
      msgsuccess("Produto adicionado");
      loadData();
    });
  }

  // Remover Bom
  async function deletBom(data) {
    await api
      .delete(`/order-bom/${data.id}`)
      .then((result) => {
        msgsuccess("Máteria prima removida");
        loadData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="lancamento.campanha" dm="Lançamento da Campanha" />
            </h1>
          </span>
          <BtnBack onClick={history.goBack} />
        </TopHeader>
        {loading ? (
          <LoadPage />
        ) : (
          <>
            <ContainerFlex>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <DescriptionTitle>
                  <Text id="informacoes" dm="Informações" />
                </DescriptionTitle>

                <BoxForm>
                  <Row>
                    <InputBox>
                      <label htmlFor="">
                        <Text id="descricao" dm="Descrição" />
                      </label>
                      <input
                        name="descricao"
                        defaultValue={order.description}
                        ref={register({ required: true })}
                        placeholder="Digite aqui a descrição da campanha"
                      />
                      {errors.descricao && <Err>Selecione o toller</Err>}
                    </InputBox>
                  </Row>

                  <Row className="r3">
                    <InputBox>
                      <label htmlFor="">Toller</label>
                      <Controller
                        as={
                          <Select
                            isDisabled
                            className="basic-single"
                            placeholder="Selecione o toller"
                            options={companies}
                            getOptionLabel={({ company_name }) => company_name}
                            getOptionValue={({ id }) => id}
                          />
                        }
                        control={control}
                        name="toller"
                        rules={{ required: true }}
                        defaultValue={order.branch}
                      />
                      {errors.toller && <Err>Selecione o toller</Err>}
                    </InputBox>

                    <InputBox>
                      <label htmlFor="">
                        <Text id="datasolicitacao" dm="Data Solicitação" />
                      </label>
                      <DatePicker
                        selected={datasolicitacao}
                        onChange={(date) => setDatasolicitacao(date)}
                        locale="pt-BR"
                        dateFormat="P"
                        name="solicitacao"
                        maxDate={new Date()}
                        autoComplete="off"
                      />
                      <input
                        autoComplete="off"
                        className="hidden"
                        name="solicitacao"
                        value={order.deadline_date}
                        ref={register({ required: true })}
                      />
                      {errors.solicitacao && <Err>Selecione a data</Err>}
                    </InputBox>

                    <InputBox>
                      <label htmlFor="">
                        <Text id="prazoentrega" dm="Prazo Entrega" />
                      </label>
                      <DatePicker
                        selected={dataentrega}
                        onChange={(date) => setDataentrega(date)}
                        locale="pt-BR"
                        dateFormat="P"
                        name="prazoent"
                        minDate={new Date()}
                        maxDate={
                          new Date(moment.utc(datasolicitacao).add(60, "days"))
                        }
                        ref={register({ required: true })}
                        autoComplete="off"
                        disabled={!datasolicitacao}
                      />
                      <input
                        autoComplete="off"
                        className="hidden"
                        name="prazoent"
                        value={dataentrega}
                        ref={register({ required: true })}
                      />
                      {errors.prazoent && <Err>Selecione a data</Err>}
                    </InputBox>
                  </Row>

                  <Row>
                    <InputBox>
                      <label htmlFor="">
                        <Text id="justificativa" dm="Justificativa" />
                      </label>
                      <select
                        name="justification"
                        ref={register({ required: true })}
                      >
                        <option value="" />
                        {justifications.map((justification) => (
                          <option
                            selected={
                              justification.id === order.justification_id
                            }
                            value={justification.id}
                          >
                            {justification.name}
                          </option>
                        ))}
                      </select>
                      {errors.justification && (
                        <Err>Escolha a justificativa</Err>
                      )}
                    </InputBox>
                  </Row>
                </BoxForm>

                <Btns className="crem">
                  <Button
                    type="reset"
                    className="delete"
                    onClick={deleteCampaing}
                  >
                    {txtDelete && <Text id="deletar" dm="Deletar" />}
                    <LoadingBtn
                      marginTop={"10px"}
                      sizeUnit={"px"}
                      size={10}
                      height={2}
                      color={"#fff"}
                      loading={loadBtnDelete}
                    />
                  </Button>
                  <span>
                    <Button
                      type="reset"
                      className="cancel"
                      onClick={history.goBack}
                    >
                      <Text id="cancelar" dm="Cancelar" />
                    </Button>
                    <Button>
                      {txtBtn && <Text id="salvar" dm="Salvar" />}
                      <LoadingBtn
                        marginTop={"10px"}
                        sizeUnit={"px"}
                        size={10}
                        height={2}
                        color={"#fff"}
                        loading={loadingBtn}
                      />
                    </Button>
                  </span>
                </Btns>
              </Form>
              {order.order_boms.length === 0 ? (
                ""
              ) : (
                <Button
                  className="sticky eye"
                  onClick={() => history.push(`/campanha/${id}`)}
                >
                  <img src={iconeye} alt="" />
                  <Text id="planejamento" dm="Planejamento" />
                </Button>
              )}
            </ContainerFlex>
            <AddProducts
              onSubmit={addBom}
              materialProduct={bom}
              id={id}
              list={order.order_boms}
              deleteProduct={deletBom}
            />
          </>
        )}
      </Center>
    </>
  );
}
