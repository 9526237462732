import styled from 'styled-components';

export const Container = styled.aside`
    width: 225px;
    border-right: solid 1px #8B8D97;
    padding: 20px 0 80px;
    margin-right: 80px;
    a{
        color: #000;
        &:hover{
            text-decoration: underline;
        }
    }
    span{
        display: block;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding-left: 40px;
        position: relative;
        font-weight: 300;
        img{
            position: absolute;
            left: 0;
        }
        font-size: 14px;
    }
`