import React from "react";

import * as S from "./styles";

const ItemBomPending = ({ data, setSelectedItem }) => {
  return (
    <S.Container>
      <S.Item>
        <S.Value>{data?.bom_name || data?.invoice_name}</S.Value>
        <S.Value>{data?.group && <S.IconGroup />}</S.Value>
        <S.Value>UN: {data?.unit}</S.Value>
        <S.Value>NF: {data?.invoice_quantity}</S.Value>
        <S.Value>BOM: {data?.bom_quantity || "---"}</S.Value>
        <S.Value className="diff">
          DIF: {data?.diff !== null ? data?.diff : "---"}
        </S.Value>
        <S.IconWarning />
        {data?.invoice_item_id && (
          <S.CommentButton
            onClick={() => setSelectedItem({ ...data, type: "BOM" })}
          >
            <S.IconComment />
          </S.CommentButton>
        )}
      </S.Item>
      <S.PercentBox>
        {data?.diff
          ? `${((data.diff * 100) / data.bom_quantity).toFixed(1)}%`
          : "---"}
      </S.PercentBox>
    </S.Container>
  );
};

export default ItemBomPending;
