import styled from "styled-components";

import ok from "../../../assets/icons/icon-ok.svg";

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  width: calc(100% - 305px);
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
  }
  h2 {
    display: flex;
    font-size: 16px;
    align-items: center;
    img {
      margin-right: 20px;
    }
  }
`;

export const ImportContent = styled.div`
  input {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-radius: 6px;
    background: #fff;
  }
`;

export const BoxTitle = styled.div`
  margin-bottom: 40px;
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ListAlerts = styled.div`
  margin: 40px 0;
  big {
    font-size: 20px;
    padding: 20px;
    display: block;
    background: url(${ok}) no-repeat 98% #3498db;
    border-radius: 6px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 20px;
  }

  ul li {
    list-style: none;
    padding: 16px;
    border: 0;
    border-radius: 4px;
    background: #f2f2f2;
    margin-bottom: 10px;
    border-radius: 6px;
    p {
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 300;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const TitList = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;
export const ErroTxt = styled.p`
  color: #d81c25;
  font-size: 14px;
`