import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import * as S from "./styles";

const Item = ({ invoice, selected, justification }) => {
  const history = useHistory();
  const storageSelected = useCallback((selected)=>{
    localStorage.setItem('@idxSelected',JSON.stringify(selected))
    
  },[selected]);

  const handleNavigate = useCallback(
    (finished) => {
      if (finished)
        return history.push(
          `/consumo-aprovado/${invoice?.id}/${invoice?.branch_id}/${invoice?.product_id}`
        );
      return history.push(
        `/consumo/${invoice?.id}/${invoice?.branch_id}/${invoice?.product_id}`
      );
    },
    [history, invoice.branch_id, invoice.id, invoice.product_id]
  );

  return (
    <S.Container>
      <S.IconComment color={invoice.justification ? '#c50b0b' : ''} onClick={()=>{justification(invoice)}}/>
      <S.Content onClick={() => {handleNavigate(invoice?.finished_at); storageSelected(selected)}}>
        <S.Value>{invoice?.id}</S.Value>
        <S.Value>{invoice?.order?.alfa_id}</S.Value>
        <S.Value>{invoice?.number}</S.Value>
        <S.Value>
          {format(new Date(invoice?.emission_date), "dd/MM/yyyy")}
        </S.Value>
        <S.Value>
          {invoice?.invoiceItems.reduce(
            (total, item) => item.quantity + total,
            0
          )}
        </S.Value>
        <S.Value>{invoice?.product?.name}</S.Value>
        <S.Value>{invoice?.branch?.company_name}</S.Value>
        {
          !invoice?.bomSap.length && !invoice?.finished_at 
            ? <S.IconAlert/>
            : <S.IconCheck/>
        }
        {
          !invoice?.branch_raw_invoice.length && !invoice?.finished_at
            ? <S.IconAlert/> 
            : <S.IconCheck />
        }
        {
          invoice?.finished_at 
            ? <S.IconCheck/>
            : <S.IconAlert />
        }
      </S.Content>
    </S.Container>
  );
};

export default Item;
