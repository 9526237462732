import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import api from '../../services/api';
import ReactTooltip from 'react-tooltip';

// Components
import Header from '../../components/Header';
import BtnFilter from '../../components/BtnFilter';
import BtnAdd from '../../components/BtnAdd';
import IconStatus from '../../components/IconStatus';
import LoadPage from '../../components/LoadPage';
import IconEdit from '../../components/IconEdit';
import Filter from '../../components/Filter';
import Text from '../../components/Text';
import DownloadXLS from './DownloadXLS';

//Images
import iconTit from '../../assets/icons/icon-campaing.svg';

//CSS
import { Center, TopHeader } from '../../styles/style';
import { HeaderList, List, ItemList, Pagination } from './styles';
import Paginator from '../../components/shared/Paginator';

export default function Campaigns() {
  let storedFilter = localStorage.getItem('statusFilter') || 'td';
  const [openFilter, setOpenFilter] = useState(storedFilter != 'td' ? true : false);
  const [campaings, setCampaings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const [statusFil, setStatusFil] = useState(storedFilter);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageFilter, setPageFilter] = useState(false);
  const [pagination, setPagination] = useState();
  const limit = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    if(pageFilter === true){
      setPageFilter(false)
    }
  };

  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: '50%' },
  };

  async function loadCampaings(filters = '', status) {
    setLoading(true);
    let fetchStatus = status != 'td' ? `&status=${status}` : ``;
    await api
      .get(`/order?page=${currentPage}&limit=${limit}&${filters}${fetchStatus}`)
      .then((result) => {
        let orders = result.data.data;
        let pagination = result.data;
        setPagination(pagination);
        setCampaings(orders);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function onSubmit(data, status) {
    setFilter(data)
    setStatusFil(status)
    setPageFilter(true)
    loadCampaings(data, status);
  }
  useEffect(() => {
    if(pageFilter === false){
      loadCampaings(filter,statusFil);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage,pageFilter]);

  useEffect(()=>{
    if(pageFilter === true){
      handlePageChange(1)
    }
  },[pageFilter])


  const handleOrderStatus = (status) => {
    switch (status) {
      case 'parAtendido':
        return 'Pendente';
      case 'atendida':
        return 'Finalizada';
      case 'atrasada':
        return 'Atrasada';
      default:
        return '';
    }
  };

  // Campanha
  const CampaignList = () => {
    return campaings.map((item) => (
      <ItemList key={item.id}>
        <p>{item.alfa_id}</p>
        <p>{item.description}</p>
        <p>{item.branch.company_name}</p>
        <p>{new Date(item.request_date).toLocaleDateString()}</p>
        <p>{new Date(item.deadline_date).toLocaleDateString()}</p>
        <span>
          <IconStatus
            datatip={handleOrderStatus(item.status)}
            theme={item.status}
          />
        </span>

        <span>{item.percentTotalFinished.toLocaleString()}%</span>
        
        <IconEdit
          edit={`/campanha/edit/${item.id}`}
          consumo={`/consumo-campanha/${item.id}`}
          view={`/campanha/${item.id}`}
        />
      </ItemList>
    ));
  };

  return (
    <>
      <Header />
      <Center>
        <TopHeader>
          <span>
            <img src={iconTit} className="icontit" alt="" />
            <h1>
              <Text id="campanhas" dm="Campanhas" />
            </h1>
          </span>
          <span>
            <DownloadXLS orders={campaings}/>
            <BtnFilter
              title={<Text id="filtrar.Campanha" dm="Filtrar.Campanha" />}
              onClick={() => setOpenFilter(!openFilter)}
              className={`${openFilter ? 'active mr' : 'mr'}`}
            />
            <Link to="/campanha/nova">
              <BtnAdd />
            </Link>
          </span>
        </TopHeader>

        <motion.div
          animate={openFilter ? 'open' : 'closed'}
          variants={variants}
        >
          {openFilter && <Filter onSubmit={onSubmit} />}
        </motion.div>

        <HeaderList>
          <p>
            <Text id="codigo" dm="Código" />
          </p>
          <p>
            <Text id="descricao" dm="Descrição" />
          </p>
          <p>
            <Text id="toller" dm="Toller" />
          </p>
          <p>
            <Text id="datasolicitacao" dm="Data Solicitação" />
          </p>
          <p>
            <Text id="prazoentrega" dm="Prazo Entrega" />
          </p>
          <p className='center'>
            <Text id="statusEnv" dm="Status de Envio" />
          </p>
          <p className='center'>
            <Text id='statusProd' dm="Status de Expedição"/>
          </p>
        </HeaderList>
        <List>
          {loading ? (
            <LoadPage />
          ) : (
            <>
              {campaings && campaings[0] ? (
                <>
                  <CampaignList />
                  <Paginator
                    pagination={pagination}
                    onPageChange={handlePageChange}
                    neighbors={4}
                  />
                </>
              ) : (
                <p>
                  <small>Nenhum registro encontrado...</small>
                </p>
              )}

              <ReactTooltip effect="solid" />
            </>
          )}
        </List>
      </Center>
    </>
  );
}
